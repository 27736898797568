import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';

import { GATSBY_STRIPE_API_KEY } from '../../Constants';
import CheckoutForm from '../forms/stripe/CheckoutForm';

export default React.forwardRef(({ user, handlePaymentStatusChange, paymentHandler, style }, ref) => (
    <StripeProvider apiKey={GATSBY_STRIPE_API_KEY} >
        <Elements>
            <CheckoutForm
                style={style}
                userData={user}
                handlePaymentStatusChange={handlePaymentStatusChange}
                paymentHandler={paymentHandler}
                innerRef={ref}
            />
        </Elements>
    </StripeProvider>
));
