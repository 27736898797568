import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GroceryGrid from './GroceryGrid';
import GroceryDetailView from './GroceryDetailView';
import GroceryModal from '../modals/GroceryDetail';
import { miniCabadi } from '../../assets';

import { getMiniCabadici } from '../../services';

class MiniCabadiciSelector extends Component {
    static propTypes = {
        isSelectable: PropTypes.bool,
    };

    static defaultProps = {
        isSelectable: false,
    };

    state = {
        assortimentsProduitsData: []
    };

    groceryModalRef = React.createRef();

    innerOpenModalWithData = (data) => {
        // TODO: bind good api fields
        this.groceryModalRef.current.setData({
            type: data.type,
            imgUrl: data.imgUrl,
            name: data.name,
            description1: data.description1,
            description2: data.description2,
            description3: data.description3,
            price: data.price
        });
    };

    componentDidMount = () => {
        getMiniCabadici().then(result => {
            this.setState({assortimentsProduitsData: result.sort((a,b)=>a.ordre-b.ordre)});
        });
    };

    render() {
        const { isSelectable } = this.props;
        const { assortimentsProduitsData } = this.state;
        return (
            <GroceryGrid>
                {
                    assortimentsProduitsData.map((item, id) => (
                        <GroceryDetailView
                            className="grocery"
                            key={id.toString()}
                            item={item}
                            innerOnClick={() => this.innerOpenModalWithData(item)}
                            isSelectable={isSelectable}
                        />
                    ))
                }
                <GroceryModal ref={this.groceryModalRef} type="minicabadici" />
            </GroceryGrid>
        );
    }
}

export default MiniCabadiciSelector;
