import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'styled-components';

import GreenCheckbox from '../../components1/GreenCheckbox';
import { Cell, BackgroundCell } from '../../components/Grid';
import { VegIcon, FishIcon, MeatIcon, HeartIcon, TimeIcon } from '../../components1/Icons';
import FlexDiv from '../../components1/FlexDiv';
import SelectRecipeButton from '../../components1/SelectRecipeButton';
import ParentheseBackgroundNoire from '../../components1/ParentheseBackgroundNoire';

const halo = css`0 0 5px 1px ${props => props.theme.color.vertFort}`

const mapStateToProps = ({ recipesSelection, }, { recipe }) => {
    return { recipesSelection, checked: recipesSelection.map(r => r.id).includes(recipe.id)};
};

const mapDispatchToProps = dispatch => {
    return {
        addRecipeToSelection: (value) => dispatch({ type: 'ADD_RECIPE_TO_SELECTION', value }),
        removeRecipeFromSelection: (value) => dispatch({ type: 'REMOVE_RECIPE_FROM_SELECTION', value }),
    };
};

class RecipeDetailView extends PureComponent {
    static propTypes = {
        checked: PropTypes.bool.isRequired,
        recipe: PropTypes.shape({
            id: PropTypes.string.isRequired,
            imgUrl: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
        }).isRequired,
        // col: PropTypes.number.isRequired,
        // add checkbox if selectable
        isSelectable: PropTypes.bool,
        // add function on click
        innerOnClick: PropTypes.func,
        recipesSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        addRecipeToSelection: PropTypes.func.isRequired,
        removeRecipeFromSelection: PropTypes.func.isRequired,
    };

    state= {
        // checked: false
    };

    innerHandleCheck = (e) => {
        const { recipe, removeRecipeFromSelection, addRecipeToSelection } = this.props;
        // this.setState({checked: e});
        if (e) {
            addRecipeToSelection(recipe);
        } else {
            removeRecipeFromSelection(recipe);
        }
    };

    renderIcon = (type) => {
        switch (type) {
        case 'VG':
            return (<VegIcon />);
        case 'P':
            return (<FishIcon />);
        case 'V':
            return (<MeatIcon />);
        case 'Mois':
            return (<HeartIcon />);
        case 'Recette Longue':
            return (<TimeIcon />);
        default:
            throw new Error('icone inconnue');
        }
    };

    render() {
        const { checked, innerOnClick, isSelectable, recipe: {
            imgUrl,
            name,
            type,
            time,
        }} = this.props;
        return (
            <FlexDiv className="col" style={{
                flexDirection: 'column'
            }}>
                <div
                    checked={checked}
                    isSelectable={isSelectable}
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flex: 1,
                        padding: 10,
                        cursor: 'pointer',
                    }}
                    css={`
                        :hover {
                            opacity: 0.7;
                        }
                        /* border: ${props => props.checked ? 'solid' : 'none'};*/
                        /* outline: ${props => props.checked ? '4px solid red': 'none'};*/
                        /* outline-offset: 5px; */
                        box-shadow: ${props => (props.isSelectable && props.checked) ? halo : 'none'};

                    `}
                    onClick={innerOnClick}
                    role="button"
                >
                    <img
                        src={imgUrl}
                        alt="identite"
                        width="100%"
                    />
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <div style={{
                            // fontWeight: 'bold',
                            fontSize: 14,
                            maxWidth: '70px',
                            // height: '100px',
                            padding: '1em',
                            display:'flex',
                            textAlign: 'center',
                            justifySelf: 'center',
                            textAlignLast: 'center',
                            alignItems: 'center'
                        }}>
                            {name}
                        </div>
                    </div>

                    <ParentheseBackgroundNoire>
                        <Cell
                            style={{
                                // gridColumn: {col},
                                // gridRow: 3,
                                alignItems: 'center',
                                padding: '20px 40px',
                                fontSize: 14,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding: 20px !important;
                                }
                            `}
                        >
                            {
                                this.renderIcon(type)
                                /*
                                type === 'chrono' ?
                                    <Icon type={type} style={{paddingLeft: '20px', paddingRight:'20px'}}/> :
                                    type === '❤' ?
                                        <Icon type={'coupDeCoeur'} style={{paddingLeft: '20px', paddingRight:'20px'}}/> :
                                        <Letter getColor={type}>{type}</Letter>
                                */
                            }
                            <div style={{paddingLeft:'15px', whiteSpace: 'nowrap'}}>{`${time}`}</div>
                        </Cell>
                    </ParentheseBackgroundNoire>
                </div>
                {
                    isSelectable &&
                        // <GreenCheckbox innerHandleCheck={this.innerHandleCheck}  checked={checked}/>
                        <SelectRecipeButton
                            css="flex: 0 0 70px;"
                            innerHandleCheck={this.innerHandleCheck}
                            checked={checked}
                        />
                }
            </FlexDiv>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDetailView);
