import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const ParentheseBackground = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            backgroundParenthesesNoiresRectangleFluid: file(relativePath: { eq: "parentheses/horizontal/noire.png" }) {
                childImageSharp {
                    fluid(maxWidth: 150, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                        presentationWidth
                    }
                }
            }
        }
    `);

    return (
        <BackgroundImage
            fluid={data.backgroundParenthesesNoiresRectangleFluid.childImageSharp.fluid}
            style={{
                backgroundSize: 'contain',
                maxWidth: '150px',

                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center'
            }}
            loading="eager"
        >
            {children}
        </BackgroundImage>
    );
};

ParentheseBackground.propTypes = {
    children: PropTypes.element.isRequired
};

export default ParentheseBackground;
