import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BackgroundCell } from '../../components/Grid';
import { Text } from '../../components1/Texts';
import { VegIcon, FishIcon, MeatIcon, HeartIcon, TimeIcon } from '../../components1/Icons';
import FlexDiv from '../../components1/FlexDiv';
import ParentheseBackgroundVerte from '../../components1/ParentheseBackgroundVerte';


class RecipeDetailViewBack extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        data: PropTypes.oneOfType([
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                imgUrl: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                type: PropTypes.string.isRequired,
                time: PropTypes.string.isRequired,
            }),
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                imgUrl: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                quantity: PropTypes.string.isRequired,
                origin: PropTypes.string.isRequired,
            })
        ]).isRequired,
    };

    renderIcon = (type) => {
        switch (type) {
        case 'VG':
            return (<VegIcon />);
        case 'P':
            return (<FishIcon />);
        case 'V':
            return (<MeatIcon />);
        case 'Mois':
            return (<HeartIcon />);
        case 'Recette Longue':
            return (<TimeIcon />);
        default:
            return (<HeartIcon />);
        }
    };

    render() {
        const { data, type } = this.props;

        return (
            <ParentheseBackgroundVerte>
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 20,
                    }}
                >
                    <Text style={{
                        fontWeight: 600,
                        fontSize: '1.25rem',
                        maxWidth: 400,
                        padding: '1em',
                        textAlign: 'center',
                        justifySelf: 'center',
                        textAlignLast: 'center',
                        alignItems: 'center'
                    }}>
                        {data.name}
                    </Text>
                    <Text
                        style={{
                            maxWidth: 400,
                            textAlign: 'center'

                        }}
                    >
                        {
                            type === 'grocery' ? data.origin : data.description1
                        }
                    </Text>
                    <FlexDiv
                        style={{
                            padding: 20,
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                flex-direction: column;
                            }
                        `}
                    >
                        <FlexDiv
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: 10,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding-left: 0px !important;
                                }
                            `}
                        >
                            <img
                                src={data.imgUrl}
                                style={{
                                    maxWidth: 200
                                }}
                                alt="identite"
                                width="100%"
                            />
                        </FlexDiv>
                        <FlexDiv
                            style={{
                                flexDirection: 'column',
                                maxWidth: 200,
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding-left: 0px !important;
                                }
                            `}
                        >
                            <FlexDiv
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flex:0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding: 20px !important;
                                    }
                                `}
                            />
                            {
                                type === 'grocery' && (
                                    <FlexDiv
                                        style={{
                                            flexDirection: 'column',
                                            flex: 0,
                                        }}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                padding-top: 20px !important;
                                                flex:1 !important;
                                            }
                                        `}
                                    >
                                        <Text style={{fontWeight: 600, textAlign: 'justify',}}>
                                            Description :
                                        </Text>
                                        <Text
                                            style={{
                                                paddingTop: 10,
                                                textAlign: 'justify',
                                            }}
                                        >
                                            {data.description}
                                        </Text>
                                    </FlexDiv>
                                )
                            }
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    flex: 0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-top: 20px !important;
                                        flex:1 !important;
                                    }
                                `}
                            >
                                <Text style={{fontWeight: 600, textAlign: 'justify',}}>
                                    {
                                        type === 'grocery' ? 'Quantité :' :
                                            'Ingrédients dans votre CABADICI :'
                                    }
                                </Text>
                                <Text
                                    style={{
                                        paddingTop: 10,
                                        textAlign: 'justify',
                                    }}
                                >
                                    {
                                        type === 'grocery' ? data.quantity  :
                                            data.description2
                                    }
                                </Text>
                            </FlexDiv>
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    flex:0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-top: 20px !important;
                                    }
                                `}
                            >
                                {
                                    type !== 'grocery' && (
                                        <>
                                            <Text style={{fontWeight: 600, textAlign: 'justify',}}>
                                                {
                                                    'Ingrédients dans vos placards :'
                                                }
                                            </Text>
                                            <Text
                                                style={{
                                                    paddingTop: 10,
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                {
                                                    data.description3
                                                }
                                            </Text>
                                        </>
                                    )
                                }
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
            </ParentheseBackgroundVerte>
        );
    }
}

export default RecipeDetailViewBack;
