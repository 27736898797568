import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import Modalize from '../../components/Modalizer_';
import FlexDiv from '../../components1/FlexDiv';
import { Bold, Text, Title } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';
import { getOrder } from '../../services'

const OrderSuccess = ({ closeModal }) => {
    /*
    const [data, setData] = useState({});

    useEffect(async () => {
        const [{userRef, recipes: {recipesSelection, recipesNumber}, ...data}, ...rest] = await getOrder().then(qs => qs.docs.map(doc => doc.data()))
        console.log(recipesSelection);
        const dataCopy = {...data, recipesNumber}
        console.log(data);

        setData(data);
    }, []);
    */
    return (
        <FlexDiv style={{flexDirection: 'column'}}>
            <Title style={{textAlign: 'center', padding: '10px 20px'}}>
                Félicitations !
            </Title>
            <Text style={{textAlign: 'center', padding: '10px 0'}}>
                Votre commande a bien été prise en compte
            </Text>
            <Text style={{textAlign: 'center', paddingBottom: 30}}>
                <Bold>{'N\'oubliez pas : '}</Bold>{'vous avez jusqu\'à dimanche minuit pour la modifier,'}
                <br />
                {'changer vos modalités de livraison;'}
                <br />
                {'ou même l’annuler si vous le souhaitez. (à partir de votre deuxième commande)'}
                <br />
                {'Pour ce faire, rdv dans votre espace personnel, rubrique (MON CABADICI).'}
                <br />
                <br />
                Passé ce délai, nous mettrons en marche les commandes auprès de nos fournisseurs,
                <br />
                votre CABADICI sera donc presque déjà en route pour vous rejoindre !
            </Text>
            <FlexDiv style={{ justifyContent: 'center' }}>
                <GatsbyLink style={{ textDecoration: 'none' }} to="/user/orders">
                    <CabadiciButton
                        color="rougeFort"
                    >
                        terminer
                    </CabadiciButton>
                </GatsbyLink>
            </FlexDiv>
        </FlexDiv>
    );
}

OrderSuccess.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export default Modalize(OrderSuccess, {showCloseIcon: false});
