import React from 'react';
import styled from 'styled-components';
import Media from 'react-media';

import Separator from './Separator';
import { Title } from './Texts';

const RelativeText = styled.div`
    position: relative;
    top: 36px;
    color: #fff;
    @media only screen and (max-width: 599px) {
        top: 17px !important;
    }
`;

const InlineDiv = styled.div`
    display:inline-block;
`;

const StrokedTextSmall = ({children, strokeColor}) => (
    <InlineDiv
        style={{
            position: 'relative',
            top: 5,
        }}
    >
        <RelativeText>
            <Title
                style={{
                    backgroundColor: 'transparent',
                    whiteSpace: 'nowrap',
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 0.75rem;
                    }
                `}
            >
                {children}
            </Title>
        </RelativeText>
        <Separator
            strokeColor={strokeColor}
            strokeWidth="1.1rem"
            style={{margin: '0 -10px'}}
            css={`
                @media only screen and (max-width: 599px) {
                    margin: 0 -2px !important;
                }
            `}
        />
    </InlineDiv>
);

const StrokedTextDesktop = ({children, strokeColor}) => (
    <InlineDiv
        style={{
            margin: '0 20px',
            position: 'relative',
            top: 4,
        }}
    >
        <RelativeText>
            <Title
                style={{
                    backgroundColor: 'transparent',
                    // whiteSpace: 'nowrap',
                }}
            >
                {children}
            </Title>
        </RelativeText>
        <Separator
            strokeColor={strokeColor}
            strokeWidth="2.2rem"
            style={{margin: '0 -10px'}}
        />
    </InlineDiv>
);

const StrokedText = (props) => (
    <Media query="(max-width: 599px)">
        {matches =>
            matches ? (
                <StrokedTextSmall {...props} />
            ) : (
                <StrokedTextDesktop {...props} />
            )
        }
    </Media>
);

export default StrokedText;
