import React, { Component, useRef, useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import styled, { withThemeOld } from 'styled-components';
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import withTheme from '../theme';
import { BackIcon } from './Icons';
import FlexDiv from './FlexDiv';
import {Text} from './Texts';

/* could write
${props => !props.isSubHeaderTabs && 'min-width: 130px;'}
${props => props.subHeaderTabs && ({minWidth: '130px'})};
${props => props.subHeaderTabs && css`min-width: 130px`};
*/

export const CabadiciButton = styled.button`
    background-color: ${props => props.theme.color[props.color]};
    font-family: ${props => props.theme.font.special};
    font-size: 30px;
    color: #fff;
    border:none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    /*outline: none !important;*/
    cursor: pointer;

    ${props => !props.isSubHeaderTabs && 'min-width: 130px;'}

    :hover {
        opacity: 0.7;
    }
    :active {
        outline: none;
        border: none;
    }
`;

const CabadiciButtonSpecial = styled(CabadiciButton).attrs(({isSubHeaderTabs}) => ({isSubHeaderTabs}))`
    background-color: transparent;
    position: absolute;
    opacity: 1;
    /* TODO: reactivate this and check modifs*/
    right: 15px;
    ${props => props.isSubHeaderTabs && 'right: auto;padding-left: 5px !important;'}

    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    white-space: nowrap;
    padding-top: 10px;

    @media only screen and (max-width: 599px) {
        right: auto;
        padding-left: 5px !important;
    }
`;

const Background = withTheme(styled.div`
    background-color: ${props => props.theme.color[props.color]};
    height: 2.1em;
    width: 7.5em;
    margin-right: 15px;
    opacity: ${props => props.hover ? 0.8 : 1};
`);

export const Button = styled.button`
    background-color: ${props => props.theme.color[props.color]};
    ${props => props.size && `font-size: ${props.size}`};
    border: none;
    ${props => props.transparent && `background: transparent;`}
    :hover {
        opacity: 0.7;
    }
    :active {
        outline: none;
        border: none;
    }
    cursor: pointer;
    /* ripple */
    background-position: center;
    transition: background 0.8s;
    :hover {
        background: ${props => props.theme.color[props.color]} radial-gradient(circle, transparent 1%, ${props => props.theme.color[props.color]} 1%) center/15000%;
    }
    :active {
        background-color: ${props => props.theme.color[props.color]};
        background-size: 100%;
        transition: background 0s;
    }
`;

export class CabadiciConnectionButton extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.shape(),
            PropTypes.string,
        ]).isRequired,
        color: PropTypes.string.isRequired,
    };

    state = {
        isHovered: false
    };

    toggleHovering = () => {
        // this introduce buggy hovering ?
        // this.setState(({isHovered}) => ({isHovered: !isHovered}));
    }

    render() {
        const { children, color, onClick, isSubHeaderTabs, className } = this.props;
        const { isHovered } = this.state;
        return (
            <div
                style={{
                    // display: 'inline-block'
                }}
                className={className}
            >
                <div
                    style={{
                        // display: 'flex',
                        // justifyContent: 'center',
                        position: 'relative',
                    }}
                    css={`
                        :hover{
                            cursor: pointer;
                        }
                    `}
                >
                    <Background color={color} hover={isHovered}/>
                    <CabadiciButtonSpecial
                        as="a"
                        onFocus={()=>{}}
                        onMouseOver={this.toggleHovering}
                        onMouseLeave={this.toggleHovering}
                        onClick={onClick}
                        isSubHeaderTabs={isSubHeaderTabs}
                    >
                        { children }
                    </CabadiciButtonSpecial>
                </div>
            </div>
        );
    }
};

export const CabadiciConnectionButton2 = withTheme(({ theme, children, color }) => {
    return (
        <div style={{display: 'inline-block'}}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div
                    style={{
                        backgroundColor: theme.color[color],
                        height: '2.1em',
                        width: '7.5em',
                        marginRight: 15
                    }}
                />
                <button
                    type='button'
                    style={{
                        backgroundColor: 'transparent',
                        position: 'absolute',
                        opacity: 1,
                        fontFamily: theme.font.special,
                        fontSize: 30,
                        color: '#fff',
                        border:'none',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 5,
                        // top: 50,
                        right: 15,
                        // outline: 'none !important'
                    }}
                >
                    { children }
                </button>
            </div>
        </div>
    );
});

export const BackButton = props => (
    <Button {...props}>
        <BackIcon />
    </Button>
);

export const SuspendSubscriptionButton = ({ className, children, onClick }) => {
    const dataImage = useStaticQuery(graphql`
        query {
            backgroundParenthesesNoires: file(relativePath: { eq: "backgrounds/parentheses-noires-rectangle.png" }) {
                childImageSharp {
                    fluid(maxWidth: 200, quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);
    return (
        <FlexDiv
            className={className}
            onClick={onClick}
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                padding: 10,
                // flex: 0,
                alignSelf: 'center',
                justifySelf: 'center'
            }}
            css={`
                &:hover{
                    cursor: pointer;
                    opacity: 0.7;
                }
            `}
        >
            <BackgroundImage
                fluid={dataImage.backgroundParenthesesNoires.childImageSharp.fluid}
                style={{
                    // default values:
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'cover',
                    // backgroundPosition: 'center',

                    backgroundSize: '100% 100%',
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 10,
                }}
                fadeIn={false}
                css={`
                    @media only screen and (max-width: 599px) {
                        /*min-height: 135px !important;
                        margin-top: 5px !important;
                        background-size: contain !important;*/
                    }
                `}
            >
                {
                    children ||
                        (
                            <Text
                                style={{
                                    textDecoration: 'underline',
                                    alignSelf: 'center',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 0.875rem !important;
                                    }
                                `}
                            >
                                ne rien recevoir cette semaine
                            </Text>
                        )
                }
            </BackgroundImage>
        </FlexDiv>
    )
};

const FontAwesomeIconButton = styled(FontAwesomeIcon)`
    cursor: 'pointer';
    :hover {
        opacity: 0.7;
    }
`;

export const AddToCartButton = ({ itemId, updateCart, itemPreviousValue }) => {

    const inputRef = useRef(null);
    const [isFirstMount, setFirstMount] = useState(true);

    const initialState = {itemNumber: itemPreviousValue};

    const reducer = (state, action) => {
        switch (action.type) {
        case 'increment':
            return {itemNumber: state.itemNumber + 1};
        case 'decrement':
            const newValue = state.itemNumber - 1;
            if (newValue <= 0) {
                return {itemNumber: 0};
            }
            return {itemNumber: newValue};
        case 'set':
            return {itemNumber: action.value};
        default:
            throw new Error('Action not allowed');
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    // trigger updateCart when itemNumber change
    // avoid to be trigged onMount
    useEffect(() => {
        if (!isFirstMount) {
            updateCart({
                id: itemId,
                itemNumber: state.itemNumber
            });
        } else {
            setFirstMount(false);
        }
    }, [state.itemNumber]);

    return (
        <FlexDiv style={{flex: 0, padding: '0 10px', alignItems: 'center'}}>
            <FontAwesomeIconButton icon="minus" onClick={() => dispatch({type: 'decrement'})}/>
            <input
                ref={inputRef}
                value={state.itemNumber}
                onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    dispatch({type: 'set', value})
                }}
                style={{
                    width: '1rem',
                    textAlign: 'center',
                    margin: '0 5px',
                    fontSize: '0.875rem'
                }}
                css={`
                    font-family: ${props => props.theme.font.main}
                `}
            />
            <FontAwesomeIconButton icon="plus" onClick={() => dispatch({type: 'increment'})}/>
        </FlexDiv>
    );
};
