import React, { Component } from 'react';
import Modal from 'react-responsive-modal';

const higherOrderComponentClass = (WrappedComponent, { onOpenModal, onCloseModal, overlayId, showCloseIcon = true } = {overlayId: false}) => {
    class HOC extends Component {
        static getDerivedStateFromProps = (props, state) => {
            if (typeof props.isOpen !== 'undefined' && props.isOpen !== state.open) {
                return {
                    open: props.isOpen
                };
            }
            return null;
        };

        state = {
            open: false,
        };

        openModal = () => {
            this.setState({ open: true });
            if (onOpenModal) {
                onOpenModal();
            }
        };

        closeModal = () => {
            const { onCloseModalHook } = this.props;
            this.setState({ open: false });
            if (onCloseModal) {
                onCloseModal();
            }
            if (onCloseModalHook) {
                onCloseModalHook();
            }
        };

        closeModal2 = () => {
            this.setState({ open: false });
            if (onCloseModal) {
                onCloseModal();
            }
        };

        setData = data => {
            this.setState({ open: true, data });
        };

        /*
        componentDidMount = () => {
            const { doOpenModal } = this.props;
            if (typeof doOpenModal !== 'undefined' && doOpenModal) {
                console.log(doOpenModal);
                this.openModal();
            }
        }
        */

        render() {
            const { open, data } = this.state;
            return (
                <Modal
                    open={open}
                    onClose={this.closeModal}
                    closeOnOverlayClick={false}
                    center
                    overlayId={overlayId || undefined}
                    focusTrapped={false}
                    blockScroll
                    showCloseIcon={showCloseIcon}
                    styles={{
                        modal: {
                            // padding: '0rem',
                            // maxWidth: '200px'
                        }

                    }}>
                    <WrappedComponent {...this.props} closeModal={this.closeModal} data={data} openModal={this.openModal} />
                </Modal>
            );
        }
    }
    return HOC;
};

export default higherOrderComponentClass;
