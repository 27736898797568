import React, { PureComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { connect } from 'react-redux';


import { Cell, Row } from '../components/Grid';
import FlexDiv from '../components1/FlexDiv';
import { Text } from '../components1/Texts';
import Modalize from '../components/Modalizer_';
import { SuspendSubscriptionButton } from '../components1/buttons';
import RecipeDetailView from './recipe/RecipeDetailView';
import RecipeDetailViewBack from './recipe/RecipeDetailViewBack';
import RecipesGrid from './recipe/RecipesGrid';
import { getWeekRecipes } from '../services';
import { getDate, getPreviousWeek, getNextWeek, getNextOrderableWeekToken, getNextFridayNumber, getNextFriday } from '../services/dates';
import { subscriptionDeactivateCurrentWeekOrder, subscriptionReactivateCurrentWeekOrder, updateUSerData, deleteOrderById } from '../actions';

import { ActivateCurrentWeekOrderButton, SuspendCurrentWeekOrderButton } from './user/components/buttons';

const MAX_WEEK_DISPLAYED = 2;

const FaChevronCircleLeftStyled = styled(FaChevronCircleLeft)`
    :hover {
        opacity: 0.7;
    }
`;

const FaChevronCircleRightStyled = styled(FaChevronCircleRight)`
    :hover {
        opacity: 0.7;
    }
`;

const RecipeDetailViewBackModal = Modalize(RecipeDetailViewBack);

const RecipeSelector = withTheme(({ children, theme, increase, decrease }) => {
    return (
        <>
            <FaChevronCircleLeftStyled
                color={theme.color.rougeFort}
                style={{
                    cursor: 'pointer',
                }}
                size="2em"
                onClick={decrease}
            />
            {children}
            <FaChevronCircleRightStyled
                color={theme.color.rougeFort}
                style={{
                    cursor: 'pointer',
                }}
                size="2em"
                onClick={increase}
            />
        </>
    );
});

const mapStateToProps = ({ user }) => {

    const isLastSubscriptionsDeactivate = (typeof user.subscription !== 'undefined') ?
        user.subscription.suspendedWeekId.includes(getNextOrderableWeekToken()) :
        false;

    return { user, isLastSubscriptionsDeactivate, };
};

const mapDispatchToProps = dispatch => ({
    subscriptionDeactivateCurrentWeekOrder: (value) => dispatch(subscriptionDeactivateCurrentWeekOrder(value)),
    subscriptionReactivateCurrentWeekOrder: (value) => dispatch(subscriptionReactivateCurrentWeekOrder(value)),
    deleteOrderById: (value) => dispatch(deleteOrderById(value)),
})

class RecipeDateSelector extends PureComponent {
    static defaultProps = {
        isSelectable: false,
    } ;

    state = {
        date: getDate(),
        weekIndex: 1,
        isStateVacancy: false,
    };

    modal = React.createRef();

    increase = () => {
        const { weekIndex } = this.state;
        if (weekIndex >= MAX_WEEK_DISPLAYED) {
            return;
        }
        this.setState(({date, weekIndex}) => ({
            date: getNextWeek(date),
            weekIndex: weekIndex + 1
        }));
    };

    decrease = () => {
        const { weekIndex } = this.state;
        if (weekIndex === 1) {
            return;
        }
        this.setState(({date, weekIndex}) => ({
            date: getPreviousWeek(date),
            weekIndex: weekIndex - 1
        }));
    };

    innerOnClick = (recipe) => {
        this.modal.current.setData(recipe);
    };

    deactivateCurrentWeekOrder = () => {
        const { subscriptionDeactivateCurrentWeekOrder, innerModalRef } = this.props;
        const weekId = getNextOrderableWeekToken();
        subscriptionDeactivateCurrentWeekOrder(weekId)
            .then(() => {
                innerModalRef.current.setData({
                    title: 'Bien recu !',
                    message: 'Vous ne serez pas livré cette semaine !'
                });
            })
            .catch((e)=>alert(e))
    };

    reactivateCurrentWeekOrder = () => {
        const { subscriptionReactivateCurrentWeekOrder, innerModalRef } = this.props;
        const weekId = getNextOrderableWeekToken();
        subscriptionReactivateCurrentWeekOrder(weekId)
            .then(() => {
                innerModalRef.current.setData({
                    title: 'Félicitation !',
                    message: 'Votre livraison de la semaine est de nouveau active !'
                });
            })
            .catch((e)=>alert(e))
    };

    componentDidMount = () => {
        const nextOrderableWeekToken = getNextOrderableWeekToken();
        if (['2019-31', '2019-32', '2019-33', '2019-51'].includes(nextOrderableWeekToken)) {
            return this.setState(({ date }) => ({
                date: getNextWeek(date),
                isStateVacancy: true,
            }));
        }

        getWeekRecipes(nextOrderableWeekToken)
            .then(data => {
                this.setState({
                    data
                });
            });
    };

    componentDidUpdate = (prevProps, {date: prevDate}) => {
        const { date } = this.state;

        if (date.format() !== prevDate.format()) {
            getWeekRecipes(getNextOrderableWeekToken(date))
                .then(data => data.sort((recipeA, recipeB) => parseInt(recipeA.id, 10) - parseInt(recipeB.id, 10)))
                .then(data => {
                    this.setState({
                        data
                    });
                    console.log(data);
                });
        };
    };

    render() {
        const {
            theme,
            isSelectable,
            isLogged,
            isSuscriber,
            showSubscriptionSuspendButton,
            showReactiveSubscriptionButton,
            innerModalRef,
            isLastSubscriptionsDeactivate,
            deleteOrderById,
            subscriptionReactivateCurrentWeekOrder,
            subscriptionDeactivateCurrentWeekOrder,
        } = this.props;
        const { date, data, isStateVacancy, } = this.state;

        // show Active / suspend buttons only for next orderable week
        const nextWeekDeliveryDate = date ? getNextOrderableWeekToken(date) : getNextOrderableWeekToken();
        const isNextWeekActuallyDisplayed = (getNextOrderableWeekToken() === nextWeekDeliveryDate) && !isStateVacancy;
        const isSubscriptionToggleDisplay = isNextWeekActuallyDisplayed && isSuscriber;

        const isVacancy = ['2019-31', '2019-32', '2019-33', '2019-51', '2020-20'].includes(nextWeekDeliveryDate) || isStateVacancy;

        return (
            <Row
                style={{
                    flex: 1,
                    alignItems: 'stretch',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {
                    isVacancy && (
                        <div
                            style={{
                                display: 'flex',
                                flex: 2,
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: 20,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    flex: 4 !important;
                                }
                            `}
                        >
                            <Text
                                style={{
                                    color: theme.color.rougeFort,
                                    textAlign: 'center',
                                    fontSize: 24,
                                    padding: '0 15px',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 16px !important;
                                    }
                                `}
                            >
                                    ⛱ c’est les vacances ! 🌞
                            </Text>
                            <Text
                                style={{
                                    color: theme.color.rougeFort,
                                    textAlign: 'center',
                                    fontSize: 16,
                                    padding: '0 15px',
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 16px !important;
                                    }
                                `}
                            >
                            RDV fin août pour retrouver vos CABADICI !
                            </Text>
                        </div>
                    )
                }
                {
                    !isVacancy && (
                        <>
                            <Row
                                style={{
                                    justifyContent: 'center',
                                    // marginRight: '100px',
                                    // marginLeft: '100px',
                                    marginBottom: isSubscriptionToggleDisplay ? 0 : 30,
                                }}>
                                <RecipeSelector
                                    increase={this.increase}
                                    decrease={this.decrease}
                                    style={{}}>
                                    <Cell
                                        style={{
                                            fontSize: '1rem',
                                            // fontWeight: 900,
                                            color: theme.color.rougeFaible,
                                            marginRight: '5em',
                                            marginLeft: '5em',
                                            // alignSelf: 'start'
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            display: 'flex',
                                        }}
                                        css={`
                                            @media only screen and (max-width: 700px) {
                                                margin-right: 1em !important;
                                                margin-left: 1em !important;
                                            }
                                            @media only screen and (max-width: 599px) {
                                                font-size: 0.875rem !important;
                                            }
                                        `}
                                    >
                                        {
                                            isLogged ?
                                                (<Text style={{fontWeight: 600}}>{`livrées le ${getNextFriday(date)}`}</Text>) :
                                                `livrées le ${getNextFridayNumber(date)}`
                                        }
                                        {
                                            (isLogged && !isNextWeekActuallyDisplayed) && <Text>(pour informations)</Text>
                                        }
                                    </Cell>
                                </RecipeSelector>
                            </Row>
                            {
                                isSuscriber && (
                                    isLastSubscriptionsDeactivate ?
                                        <ActivateCurrentWeekOrderButton
                                            subscriptionReactivateCurrentWeekOrder={subscriptionReactivateCurrentWeekOrder}
                                        /> :
                                        <SuspendCurrentWeekOrderButton
                                            deleteOrderById={deleteOrderById}
                                            subscriptionDeactivateCurrentWeekOrder={subscriptionDeactivateCurrentWeekOrder}
                                        />
                                )
                            }
                            <RecipesGrid>
                                {
                                    data && data.map((recipe, id) => (
                                        <RecipeDetailView
                                            key={id.toString()}
                                            recipe={recipe}
                                            innerOnClick={() => this.innerOnClick(recipe)}
                                            isSelectable={isNextWeekActuallyDisplayed}
                                        />
                                    ))
                                }
                            </RecipesGrid>
                            <RecipeDetailViewBackModal
                                ref={this.modal}
                            />
                        </>
                    )
                }
            </Row>
        );
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(RecipeDateSelector));
