import React from 'react';
import LazyHero from 'react-lazy-hero';
import Image from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

import { heroImage } from '../assets';

export default () => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    logo: file(relativePath: { eq: "logos/logo-rouge.png" }) {
                        childImageSharp {
                            fixed(width: 300) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            `}
            render={data => (
                <LazyHero
                    opacity={0.5}
                    css={`
                        @media only screen and (max-width: 599px) {
                            display: none;
                        }
                    `}
                    isCentered
                    minHeight="75vh"
                    parallaxOffset={400}
                    imageSrc={heroImage}
                    style={{
                        objectFit: 'contain',
                    }}
                >
                    <div style={{
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Image
                            fixed={data.logo.childImageSharp.fixed}
                            alt="logo"
                            imgStyle={{
                                // maxWidth: '33%',
                                // minWidth: '300px',
                                width: 300,
                                height: 'auto',
                                // maxHeight: '80%'
                            }}
                        />
                    </div>
                </LazyHero>
            )}
        />
    );
};
