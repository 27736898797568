import styled from 'styled-components';

const Test = styled.div`
    @media (height > 600px) {
    body { line-height: 1.4; }
    }

    @media (400px <= width <= 700px) {
    body { line-height: 1.4; }
    }

    @media query {

    }
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
    /* For everything bigger than 768px */
    .col-1{
    width: 8.33%;
    }
    .col-2 {...}
    .col-3{
    width: 25%;
    }
    }
`;

const Section = styled.div`
    margin: 0 auto;
    padding-top: 0;
    padding-right: 5vw;
    padding-left: 5vw;

    @media only screen and (max-width: 599px) {
        padding-right: 10px;
        padding-left: 10px;
    }

    @media only screen and (min-width: 599px) and (max-width: 799px) {
        padding-right: 10px;
        padding-left: 10px;
    }
`;

export default Section;
