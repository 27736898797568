import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import GroceryGrid from './GroceryGrid';
import GroceryDetailView from './GroceryDetailView';
import GroceryModal from '../modals/GroceryDetail';
import { getGroceries } from '../../services';

const assortimentsProduitsData = [
    {
        name: 'Assortiment de fruits frais – 3 variétés',
        id: 'aer',
        img: 'recette1',
        type: 'VG',
        price: 10,
    },
    {
        name: 'Assortiment de fromages affinés – 3 variétés',
        id: 'gzg',
        img: 'recette1',
        type: 'VG',
        price: 14,
    },
    {
        name: 'Assortiment de charcuteries – 3 variétés',
        id: 'zhs',
        img: 'recette1',
        type: 'V',
        price: 18,
    },
    {
        name: 'Assortiment apéritif complet',
        id: 'zghzd',
        img: 'recette1',
        type: 'V',
        price: 20,
    },
    {
        name: 'L’huile d’olives Bio d’ici',
        id: 'sfhswf',
        img: 'recette1',
        type: 'VG',
        price: 15,
    },
    {
        name: 'Tapenade d’olives vertes de tata Annie',
        id: 'zhsfsfhy',
        img: 'recette1',
        type: 'VG',
        price: 5,
    },
    {
        name: 'Tapenade d’olives noires de tata Colette',
        id: 'hejdtju',
        img: 'recette1',
        type: 'VG',
        price: 5,
    },
    {
        name: 'Tapenade d’olives vertes à la truffe du Perigord',
        id: 'hdfwjg',
        img: 'recette1',
        type: 'VG',
        price: 7,
    },
    {
        name: 'Confit de tomates séchées',
        id: 'uedigfaer',
        img: 'recette1',
        type: 'VG',
        price: 5,
    },
    {
        name: 'Confit de betteraves au cumin',
        id: 'ghzqrsfhuwdf',
        img: 'recette1',
        type: 'VG',
        price: 5,
    },
];

class GrocerySelector extends PureComponent {
    static propTypes = {
        isSelectable: PropTypes.bool,
    };

    static defaultProps = {
        isSelectable: false,
    };

    state = {
        assortimentsProduitsData: []
    };

    groceryModalRef = React.createRef();

    innerOpenModalWithData = (data) => {
        console.log(data);
        // TODO: bind good api fields
        this.groceryModalRef.current.setData({
            imgUrl: data.imgUrl,
            name: data.name,
            description: data.description,
            quantity: data.quantity,
            origin: data.origin,
        });
    };

    componentDidMount = () => {
        getGroceries().then(result => {
            this.setState({assortimentsProduitsData: result.sort((a,b)=>a.ordre-b.ordre)});
        });
    };

    render() {
        const { isSelectable } = this.props;
        const { assortimentsProduitsData } = this.state;
        return (
            <GroceryGrid>
                {
                    assortimentsProduitsData.map((item, id) => (
                        <GroceryDetailView
                            key={id.toString()}
                            className="grocery"
                            item={item}
                            innerOnClick={() => this.innerOpenModalWithData(item)}
                            isSelectable={isSelectable}
                        />
                    ))
                }
                <GroceryModal ref={this.groceryModalRef} type="grocery" />
            </GroceryGrid>
        );
    }
}

export default GrocerySelector;
