import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import { withTheme } from 'styled-components';
import BackgroundImage from 'gatsby-background-image'

import FlexDiv from './FlexDiv';

const LogoHeader =  withTheme(({ theme, isUserPage }) => {
    return (
        <StaticQuery
            query={graphql`
                query {
                    header: file(relativePath: { eq: "index/header.jpg" }) {
                        ...fullWidthImage_noBase64
                    }
                    headerBackground: file(relativePath: { eq: "index/header.jpg" }) {
                        ...fullWidthImage_noBase64
                    }
                    logoPrincipal: file(relativePath: { eq: "logos/logo-rouge.png" }) {
                        childImageSharp {
                            fixed(height: 100) {
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                    }
                    logoPrincipalPetit: file(relativePath: { eq: "logos/logo-rouge.png" }) {
                        childImageSharp {
                            fixed(height: 60) {
                                ...GatsbyImageSharpFixed_noBase64
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                    <BackgroundImage
                        id="header"
                        fluid={data.headerBackground.childImageSharp.fluid}
                        style={{
                            // default values:
                            // backgroundRepeat: 'no-repeat',
                            // backgroundSize: 'cover',
                            // backgroundPosition: 'center',

                            // backgroundSize: '100% 100%',
                        }}
                        fadeIn={false}
                        css={`
                            @media only screen and (max-width: 599px) {
                                /*min-height: 135px !important;*/
                            }
                        `}
                    >
                        <FlexDiv
                            style={{
                                padding: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            css={`
                                @media only screen and (min-width: 599px) {
                                    display: none;
                                }
                            `}
                        >
                            <Image
                                fixed={
                                    isUserPage ?
                                        data.logoPrincipalPetit.childImageSharp.fixed :
                                        data.logoPrincipal.childImageSharp.fixed
                                }
                                alt="logo"
                            />
                        </FlexDiv>
                    </BackgroundImage>
                    {
                        !isUserPage && false && (
                            <Image
                                fluid={data.header.childImageSharp.fluid}
                                alt="logo"
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none;
                                    }
                                `}
                            />
                        )
                    }
                </>
            )}
        />
    );
});

export default LogoHeader;
