import heroImage from '../images/heroImage/cabadici.jpg';
import parenthesesRoses from '../images/parentheses/horizontal/rose.png';
import parenthesesVerticalJaunes from '../images/parentheses/vertical/jaune.png';
import parenthesesVerticalNoires from '../images/parentheses/vertical/noir.png';
import parenthesesVerticalRoses from '../images/parentheses/vertical/rougeFaible.png';
import parenthesesVerticalVertes from '../images/parentheses/vertical/vertFaible.png';
import parenthesesNoiresCarrees from '../images/backgrounds/parentheses-noires-carre.png';
import miniCabadi from '../images/grocery/mini-cabadici.jpg';

export {
    heroImage,
    parenthesesRoses,
    parenthesesVerticalJaunes,
    parenthesesVerticalNoires,
    parenthesesVerticalRoses,
    parenthesesVerticalVertes,
    parenthesesNoiresCarrees,
    miniCabadi,
};
/*
import logoParenthesesNoires from '../../static/images/PNG EXTRAITS PSD 261218/BARRE MENU/Logo texte parenthse NOIR.png';
import parenthesesRoses from '../../static/images/parenthese-rose.png';
import logoPrincipal from '../../static/images/PNG EXTRAITS PSD 261218/HEADER/Logo BORDEAUX.png';
import parenthesesNoiresCarrees from '../../static/images/parentheses-noires-carre.png';
import parenthesesNoires from '../../static/images/PNG EXTRAITS PSD 261218/LES RECETTES/Parenthese noire.png';
import logoParenthesesNoires2 from '../images/logos/logo-noir.png';
import logoVert from '../../static/images/PNG EXTRAITS PSD 261218/CONCEPT/Logo ST VERT.png';

// sections
import idee1 from '../images/index/concept1.png';
import idee2 from '../images/index/concept2.png';
import idee3 from '../images/index/concept3.png';
import contenu from '../../static/images/PNG EXTRAITS PSD 261218/CONTENU/Contenu insert test.png';
import robot from '../../static/images/PNG EXTRAITS PSD 261218/LES RECETTES/thermomix.png';
import principe1 from '../images/principe1.png';
import principe2 from '../images/principe2.png';
import principe3 from '../images/principe3.png';
import principe4 from '../images/principe4.png';
import principe5 from '../images/principe5.png';
import logoText from '../../static/images/PNG EXTRAITS PSD 261218/COMMANDER/Logo texte parenthse NOIR copie copie.png';

import recette1 from '../../static/images/PNG EXTRAITS PSD 261218/LES RECETTES/recette1.png';

export {
    logoParenthesesNoires,
    parenthesesRoses,
    logoPrincipal,
    parenthesesNoiresCarrees,
    recette1,
    parenthesesNoires,
    idee1,
    idee2,
    idee3,
    logoParenthesesNoires2,
    contenu,
    robot,
    principe1,
    principe2,
    principe3,
    principe4,
    principe5,
    logoText,
    logoVert,
    aliments,
    champs,
    livre,
    chariot,
    drive,
    local,
    panier,
};
*/
