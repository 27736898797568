import React, { Component } from 'react';
import { css, withTheme } from 'styled-components';
import { connect } from 'react-redux';
import Media from 'react-media';
import Ink from 'react-ink';

import FlexDiv from '../../components1/FlexDiv';
import { Title, Text } from '../../components1/Texts';
import { deliveryHoursData } from '../../Constants';


const halo = css`4px solid ${props => props.theme.color.beigeFort}`;

const DeliveryHoursChoice = ({ data, checked, innerOnClick, value }) => (
    <FlexDiv
        checked={checked}
        style={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 10,
            padding: 10,
            cursor: 'pointer',
            position: 'relative',
        }}
        css={`
            background-color: ${props => props.theme.color.beigeFaible};
            /* border: ${props => props.checked ? 'solid' : 'none'};*/
            outline: ${props => props.checked ? halo : 'none'};
            outline-offset: 2px;
            &:hover {
                opacity: 0.7;
            }
            @media only screen and (max-width: 599px){
                margin: 5px !important;
                padding: 5px !important;
            }
        `}
        onClick={() => innerOnClick(value)}
    >
        <Ink background={false} opacity={0.1}/>
        <Text
            css={`
                @media only screen and (max-width: 599px){
                    font-size: 0.875rem;
                }
            `}>{data.text}</Text>
    </FlexDiv>
);

const mapStateToProps = ({ deliveryHoursChoice }) => ({
    deliveryHoursChoice
});

const mapDispatchToProps = dispatch => {
    return {
        updateDeliveryHoursChoice: (value) => dispatch({ type: 'UPDATE_DELIVERY_HOURS_CHOICE', value }),
    };
};

class DeliverySelector extends Component {

    innerOnClick = (value) => {
        const { updateDeliveryHoursChoice } = this.props;
        updateDeliveryHoursChoice(value);
    }

    render() {
        const { theme, deliveryHoursChoice } = this.props;

        return (
            <>
                <Text style={{textAlign: 'center', padding: '20px 0'}}>
                    Choisissez votre horaire de livraison :
                </Text>
                <FlexDiv
                    style={{
                        justifyContent: 'space-between',
                        alignItems: 'stretch',
                        paddingTop: 10,
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            padding: 10px 10px 0 10px !important;
                        }
                    `}
                >
                    {
                        deliveryHoursData.map((hours, index) => (
                            <React.Fragment key={index.toString()}>
                                <DeliveryHoursChoice
                                    data={hours}
                                    checked={(deliveryHoursChoice === index)}
                                    innerOnClick={this.innerOnClick}
                                    value={index}
                                />
                                {
                                    index < 2 && (
                                        <Media query="(min-width: 450px)">
                                            <Text
                                                style={{alignSelf: 'center'}}
                                                css={`
                                                    @media only screen and (max-width: 599px) {
                                                        font-size: 0.850rem;
                                                    }
                                                `}
                                            >
                                                ou
                                            </Text>
                                        </Media>
                                    )
                                }
                            </React.Fragment>
                        ))
                    }
                </FlexDiv>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(DeliverySelector));
