import React from 'react';
import styled from 'styled-components';
import withTheme from '../../theme';

const Button = styled.button`
    color: palevioletred;
    font-size: 1em;
    margin: 0.5em;
    padding: 0.25em 1em;
    border: 2px solid palevioletred;
    border-radius: 3px;
`;

const ButtonDetail = styled.button`
    color: paleblue;
    font-size: 0.5em;
    margin: 0.5em;
    padding: 0.25em 0.5em;
    border: 2px solid paleblue;
    border-radius: 3px;
`;

const CabadiciButton = withTheme(styled.button`
    background-color: ${props => props.theme.color[props.color]};
    font-family: ${props => props.theme.font.special};
    font-size: 30px;
    color: #fff;
    border:none;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
`);

export { Button, ButtonDetail, CabadiciButton };
