import React from 'react';
import PropTypes from 'prop-types';

const CarouselSlideWrapper = ({ index, activeIndex, children }) => {
    return (
        <li
            className={
                index === activeIndex
                    ? 'carousel__slide carousel__slide--active'
                    : 'carousel__slide'
            }>
            <div className="carousel-slide__content">
                {children}
            </div>
        </li>
    );
};

CarouselSlideWrapper.propTypes = {
    index: PropTypes.number.isRequired,
    activeIndex: PropTypes.number.isRequired,
    children: PropTypes.element.isRequired,
};

export {
    CarouselSlideWrapper
};
