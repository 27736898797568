import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import FlexDiv from '../../components1/FlexDiv';
import { Text } from '../../components1/Texts';
import { checkCoupon } from '../../services';

const PaymentForm = ({ handleCoupon, handleCouponError }) => {
    const inputRef = useRef(null);
    return (
        <FlexDiv
            style={{justifyContent: 'space-between'}}
            css={`
                @media only screen and (max-width: 599px) {
                    flex-direction: column;
                }
            `}
        >
            <Text>
                Un code de réduction ?
            </Text>
            <form onSubmit={(e) => {
                e.preventDefault();
                checkCoupon({
                    name: inputRef.current.value,
                    date: new Date().toISOString()
                })
                    .then(resp => {
                        // console.log(resp);
                        if (resp.data.status === 'error') {
                            return handleCouponError(resp.data.message);
                        }
                        return handleCoupon(resp.data.coupon);
                    })
            }}>
                <input type='text' placeholder="..." ref={inputRef} />
                <input type="submit" value="OK" />
            </form>
        </FlexDiv>
    );
};

PaymentForm.propTypes = {
    handleCoupon: PropTypes.func.isRequired,
    handleCouponError: PropTypes.func.isRequired,
}

export default PaymentForm;
