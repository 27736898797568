import React, { PureComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

import Modalize from '../../components/Modalizer_';
import { BackgroundCell } from '../../components/Grid';
import { updatePartnerDeliveryAddress } from '../../actions';
import { Text as TextWithoutStyle, Title } from '../../components1/Texts';
import ParentheseBackgroundVerte from '../../components1/ParentheseBackgroundVerte';
import PartnerAddressModal from './PartnerAddress';
import { getPartners } from '../../services';

const Text = styled(TextWithoutStyle)`
    @media only screen and (max-width: 599px) {
        font-size: 0.625rem;
    }
`;

const PartnerGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    @media only screen and (max-width: 599px) {
        grid-column-gap: 5px;
    }
`;

class PartnerDeliveryInformations extends PureComponent {
    static propTypes = {
        data: PropTypes.shape().isRequired,
    };



    render() {
        const { data: { location, name, morningHours, afternoonHours, partnerId, partnerAddress,}, onClick } = this.props;
        return (
            [
                <Text
                    key="location"
                    onClick={()=>onClick()}
                    css={`
                        cursor: pointer;
                        :hover {
                            opacity: 0.6;
                        }
                    `}
                >{location}</Text>,
                <Text
                    key="name"
                    onClick={()=>onClick()}
                    css={`
                        cursor: pointer;
                        :hover {
                            opacity: 0.6;
                        }
                    `}
                >{name}</Text>,
                <Text
                    key="hours"
                    style={{whiteSpace: 'nowrap', justifySelf: 'center'}}
                    onClick={()=>onClick()}
                    css={`
                        cursor: pointer;
                        :hover {
                            opacity: 0.6;
                        }
                        @media only screen and (max-width: 599px) {
                            font-size: 0.5rem !important;
                        }
                    `}
                >
                    {`${morningHours} / ${afternoonHours}`}
                </Text>,
            ]
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updatePartnerDeliveryAddress:  value => dispatch(updatePartnerDeliveryAddress(value)),
    };
};

const partnerDeliveryAdressRef = React.createRef();

const PartnerDeliveryAddress = ({ closeModal, isSelectable, updatePartnerDeliveryAddress, updateDeliveryChoice }) => {
    const [partnerData, setData] = useState();

    useEffect(() => {
        getPartners().then(result => {
            setData(result)
        });
    }, []);

    return (
        <ParentheseBackgroundVerte
            css="flex-direction: column;"
        >
            <Text
                style={{textAlign: 'center',}}
                css={`
                    font-family: ${props => props.theme.font.special};
                    font-size: 40px;
                    @media only screen and (max-width: 599px) {
                        font-size: 1.125rem;
                    }
                `}
            >
                Liste de nos partenaires
            </Text>
            <Text style={{textAlign: 'center',}}>
                Sélectionnez le lieu où vous attendra votre CABADICI.
            </Text>
            <PartnerGrid
                style={{
                    padding: 20,
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        padding: 5px !important;
                    }
                `}

            >
                <Text style={{fontWeight: 600, justifySelf: 'center', paddingBottom: 10}}>Ville / quartier</Text>
                <Text style={{fontWeight: 600, justifySelf: 'center', paddingBottom: 10}}>Partenaire</Text>
                <Text style={{fontWeight: 600, justifySelf: 'center', paddingBottom: 10}}>{'Horaires d\'ouverture'}</Text>
                {
                    partnerData && partnerData.map((partner, id) => (
                        <PartnerDeliveryInformations
                            key={id.toString()}
                            data={partner}
                            onClick={() => {
                                if (isSelectable) {
                                    partnerDeliveryAdressRef.current.setData(partner);
                                }
                            }}
                        />
                    ))
                }
            </PartnerGrid>
            {
                !isSelectable && (
                    <Text style={{padding: '20px 0', textAlign: 'center'}}>
                        Vous souhaitez devenir partenaire ?
                        <br />
                        Nous recommander un commerçant près de chez vous ?
                        <br />
                        {'N\'hésitez pas à nous écrire à '}<a href="mailto:contact@cabadici.fr" style={{color: '#000'}}>contact@cabadici.fr</a>
                    </Text>
                )
            }
            <PartnerAddressModal ref={partnerDeliveryAdressRef} onSubmit={(data) => {
                updatePartnerDeliveryAddress(data);
                // set delivery choice as partner only after submitting choice
                updateDeliveryChoice(0);
                closeModal();
            }}/>
        </ParentheseBackgroundVerte>
    );
};

PartnerDeliveryAddress.propTypes = {
    closeModal: PropTypes.func.isRequired,
    isSelectable: PropTypes.bool,
};

PartnerDeliveryAddress.defaultProps = {
    isSelectable: true,
};

export default Modalize(connect(null, mapDispatchToProps)(PartnerDeliveryAddress));
