import React from 'react';
import styled from 'styled-components';

import FlexDiv from '../FlexDiv';

export const Check = styled.input.attrs({ type: 'checkbox' })`
    &:focus {
        box-shadow: 0 0 2px 3px ${props => props.theme.color.rougeFaible};
    }
`;

export const CustomRadio = ({ className, handleChange, value }) => {
    return <div className={className} onClick={() => handleChange(value)} />;
};

export const StyledCheckbox = styled(CustomRadio)`
    display: inline-block;
    width: 16px;
    height: 16px;
    background: ${props =>
        props.checked ? props.theme.color.vertFort : 'none'};
    border: solid 2px;
    border-color: ${props => props.theme.color.vertFort};
    border-radius: 50%;
    transition: all 150ms;
`;

export class MySelect extends React.Component {
    handleChange = value => {
        const { name, onChange } = this.props;
        // this is going to call setFieldValue and manually update values.topcis
        onChange(name, value);
    };

    handleBlur = () => {
        const { name, onBlur } = this.props;
        // this is going to call setFieldTouched and manually update touched.topcis
        onBlur(name, true);
    };

    render() {
        const { value } = this.props;
        return (
            <FlexDiv style={{ justifyContent: 'space-around' }}>
                {[
                    { text: 'Mme', value: 'mme' },
                    { text: 'Mr', value: 'mr' },
                ].map((gender, id) => (
                    <FlexDiv style={{ flex: 0 }} key={id.toString()}>
                        <StyledCheckbox
                            checked={gender.value === value}
                            value={gender.value}
                            handleChange={this.handleChange}
                        />
                        <label style={{ marginLeft: 10 }}>{gender.text}</label>
                    </FlexDiv>
                ))}

                {/*
                    <div>
                        {this.props.value}
                    </div>
                    <div
                        onChange={this.handleChange}
                        onClick={() => this.handleChange('tutu')}
                        onBlur={this.handleBlur}
                        value={this.props.value}
                    >
                        {'tutu'}
                    </div>
                    <div
                        onChange={this.handleChange}
                        onClick={() => this.handleChange('boby')}
                        onBlur={this.handleBlur}
                        value={this.props.value}
                    >
                        {'boby'}
                    </div>
                    */}
            </FlexDiv>
        );
    }
}

export const CustomInputComponent2 = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors, values, setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    return (
        <>
            <MySelect
                name={field.name}
                value={values[field.name]}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors[field.name]}
                touched={touched[field.name]}
            />
            {/* touched[field.name] && errors[field.name] && <div className="error">{errors[field.name]}</div> */}
        </>
    );
};

export const SelectStyled = styled.select`
    width: 100%;
    height: 35px;
    background: white;
    color: gray;
    padding-left: 5px;
    font-size: 14px;
    border: none;
    margin-left: 10px;

    option {
        color: black;
        background: white;
        display: flex;
        white-space: pre;
        min-height: 20px;
        padding: 0px 2px 1px;
    }
`;

export const NewTodo2 = styled.input`
  font-family: ${props => props.theme.font.main};
  padding: 16px 16px 16px 60px;
  border: none;
  background: rgba(0, 0, 0, 0.003);
  box-shadow: inset 0 -2px 1px rgba(0, 0, 0, 0.03);
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: red;
  }
  :-ms-input-placeholder {
     color: red;
  }
`;

// https://codemyui.com/hand-drawn-border-buttons-css/
export const DrawHandStyleInput = styled.input.attrs(({ field }) => ({
    // we can define static props
    type: "text",
    ...field
}))`
    box-sizing:border-box;
    padding:0 ${props => props.noPadding ? '0' : '0.5rem'};
    /*margin:0 1rem;*/
    transition:all .5s ease;
    color:#41403E;
    font-size:0.75rem;
    letter-spacing:1px;
    outline:none;
    box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
    border-radius: 155px 15px 100px 15px/15px 100px 15px 100px;
    border:solid 1px #41403E;
    width: 100%;
    @media only screen and (max-width: 599px) {
        /*max-width: 9rem;*/
    }
    ::placeholder {
      color: lightgrey;
    }
`;

// https://anybodesign.com/des-select-personnalises-en-css/
export const DrawHandStyleSelect = styled.select.attrs(({ field }) => ({
    // we can define static props
    // type: "text",
    ...field,
    required: true,
}))`
    -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

	width: 110%;
	/*
		On est sûr de ne plus voir
		la flèche native
	*/

	height: auto;
    border: 0;
	margin: 0;
	/*padding: .75em;*/
    padding:0 ${props => props.noPadding ? '0' : '0.5rem'};
	border-radius: 0;
    background-color: #fff;

	overflow: hidden;
	text-overflow: ellipsis;
	/*
		On empêche le texte d'aller
		jusqu'au bout s'il est trop long
	*/
    font-size: 1rem;
    font-family: ${props => props.theme.font.main};
`;

export const WrappingSelect = styled.div`
    position: relative;
    max-height: 1.250rem;
    /* background-color: #fff; */
    /* border: #777 1px solid; */
    margin: 0 0 0 0;
    overflow: hidden;

    /* hand draw effect */
    outline:none;
    border-radius: 155px 15px 100px 15px/15px 100px 15px 100px;
    border:solid 1px #41403E;

    &::after {
        /* Le pointeur du select */
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -3px;
        right: .75em;
        display: block;
        width: 0; height: 0;
        border-color: transparent;
        border-top-color: #444;
        border-width: 6px;
        border-style: solid;
        pointer-events: none;
    }

    // The select element is set to required
    // as long as the selected options value
    // is empty the element is not valid.
    &:invalid {
        color: #d9d9d9;
    }
`;

export const DrawHandStyleTexArea = styled.textarea.attrs(({ field }) => ({
    // we can define static props
    ...field
}))`
    box-sizing:border-box;
    padding:0 ${props => props.noPadding ? '0' : '0.5rem'};
    /*margin:0 1rem;*/
    transition:all .5s ease;
    color:#41403E;
    font-size:1rem;
    letter-spacing:1px;
    outline:none;
    box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);
    border-radius: 155px 15px 100px 15px/15px 100px 15px 100px;
    border:solid 1px #41403E;
    @media only screen and (max-width: 599px) {
        max-width: 9rem;
    }
    ::placeholder {
      color: lightgrey;
    }
`;
