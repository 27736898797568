import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import withTheme from '../../theme';

import Section from '../Section';
import FlexDiv from '../FlexDiv';
import { Text } from '../Texts';
import { InstagramIcon } from '../Icons';

const footerData = [];

const FooterLink = styled(Link)`
    text-decoration: none;
    color: #000;
    :hover {
        opacity: 0.7;
    }
    :active {
        outline: none;
        border: none;
    }
`;

const FooterDesktop = withTheme(({ theme }) => {
    return (
        <Section
            style={{
                backgroundColor: theme.color.beigeFort,
            }}
            css={`
                @media only screen and (max-width: 1px) {
                    display: none !important;
                }
            `}
        >
            <FlexDiv
                style={{
                    justifyContent: 'space-between',
                    alignSelf: 'stretch',
                    paddingTop: 50,
                    paddingBottom: 50,
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        flex-direction: column;
                    }
                `}
            >
                {
                    footerData.map((data, index) => (
                        <FlexDiv
                            key={index.toString()}
                            style={{
                                flexDirection: 'column',
                                padding: 10,
                                alignItems: index === 0 ?
                                    'flex-start' :
                                    index === 1 ?
                                        'center' :
                                        'flex-end'
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    align-items: flex-start !important;
                                }
                            `}
                        >
                            <Text
                                style={{
                                    textTransform: 'uppercase',
                                    fontWeight: 600,
                                    padding: 0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-bottom: 10px !important;
                                    }
                                `}
                            >
                                { index !== 2 ? data.title : (
                                    <FooterLink
                                        to="/faq"
                                    >
                                        <Text
                                            style={{
                                                textTransform: 'uppercase',
                                                fontWeight: 600,
                                                padding: 0,
                                            }}
                                        >
                                            {data.title}
                                        </Text>
                                    </FooterLink>
                                )}
                            </Text>
                            {
                                data.links.map((data, index) => (
                                    <FooterLink
                                        to={data.link}
                                        key={index.toString()}
                                    >
                                        <Text
                                            style={{
                                                padding: 0,
                                            }}
                                        >
                                            {data.text}
                                        </Text>
                                    </FooterLink>
                                ))
                            }
                        </FlexDiv>
                    ))
                }
            </FlexDiv>
        </Section>
    )
});

const FooterText = styled(Text)`
    font-family: ${props => props.theme.font.title};
    font-size: 1.125rem;
    @media only screen and (max-width: 699px) {
        font-size: 0.875rem;
    }
`;

const BoldFooterText = styled(FooterText)`
    font-weight: 600;
    @media only screen and (max-width: 699px) {
        font-size: 0.750rem !important;
    }
`;

const Footer = ({ theme }) => {
    return (
        <>
            <Section>
                <FlexDiv
                    style={{
                        flexDirection: 'row',
                        // justifyContent: 'center',
                        // alignItems: 'center',
                        padding: '30px 0 20px 0',
                    }}
                    css={`
                        @media only screen and (min-width: 599px) {
                            flex-direction: column;
                        }
                    `}
                >
                    <FlexDiv
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <FooterText
                            style={{
                                textTransform: 'uppercase',
                                fontWeight: 600,
                            }}
                        >
                            Nous contacter
                        </FooterText>
                        <FooterText
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.750rem !important;
                                }
                            `}
                        >
                            {'Par mail : '}
                            <a href="mailto:contact@cabadici.fr">
                                 contact@cabadici.fr
                            </a>
                        </FooterText>
                        <FooterText
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.750rem !important;
                                }
                            `}
                        >
                            Par téléphone ou par SMS :
                            <br />
                            <BoldFooterText>07 56 87 34 12</BoldFooterText>
                        </FooterText>
                        <FooterText
                            style={{
                                fontSize: '0.875rem',
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.625rem !important;
                                }
                            `}
                        >
                            (aucune commande ne pourra être prise par mail ou par téléphone)
                        </FooterText>
                    </FlexDiv>
                    <FlexDiv
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <FooterText
                            style={{
                                textAlign: 'center',
                            }}
                        >
                            Retrouvez-nous sur Instagram
                        </FooterText>
                        <a href="https://www.instagram.com/cabadici/" >
                            <InstagramIcon
                                css={`
                                    padding: 5px;
                                    cursor: pointer;
                                    color: ${props => props.theme.color.beigeFaible};
                                    :hover {
                                        color: ${props => props.theme.color.beigeFort};
                                    }
                                `}
                            />
                        </a>
                    </FlexDiv>
                    <FlexDiv
                        style={{
                            flexDirection: 'column',
                        }}
                    >
                        <FooterLink
                            to="/faq"
                        >
                            <FooterText
                                style={{
                                    textAlign: 'right',
                                    fontWeight: 600,
                                }}
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >
                                FAQ
                            </FooterText>
                            <FooterText
                                style={{
                                    textAlign: 'right',
                                    fontWeight: 600,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >
                                Foire aux Questions
                            </FooterText>
                        </FooterLink>
                        <FooterLink
                            to="/cgv"
                        >
                            <FooterText
                                style={{
                                    textAlign: 'right',
                                    fontWeight: 600,
                                }}
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >
                                CGV
                            </FooterText>
                            <FooterText
                                style={{
                                    textAlign: 'right',
                                    fontWeight: 600,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        display: none !important;
                                    }
                                `}
                            >
                                Conditions Générales de Vente
                            </FooterText>
                        </FooterLink>
                        <FooterLink
                            to="/mentions-legales"
                        >
                            <FooterText
                                style={{
                                    textAlign: 'right',
                                    fontWeight: 600,
                                }}
                            >
                                Mentions Légales
                            </FooterText>
                        </FooterLink>
                    </FlexDiv>
                </FlexDiv>
            </Section>
        </>
    );
};

Footer.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default withTheme(Footer);
