import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage as ErrorMessageWithoutStyle } from 'formik';

const ErrorMessage = styled(ErrorMessageWithoutStyle)`
    color: ${props => props.theme.color.rougeFaible};
`;

export {
    Formik,
    Form,
    Field,
    ErrorMessage
};
