import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tab, TabList } from 'react-tabs';

import { Title } from './Texts';
import StrokedTitle from './StrokedTitle';

export const CustomTabList = styled(TabList)`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    list-style-type: none;
    padding-inline-start: 0 !important;

`;

const CustomTab = ({ disabled, selected, className, tabData }) => {
    const { color, text } = tabData;
    if (selected) {
        return (
            <Tab
                className={className}
                disabled={disabled}
                selected={selected}
                disabledClassName="inactive"
                selectedClassName="active"
                style={{padding: '0px 12px !important'}}
                css={`
                    @media only screen and (max-width: 599px) {
                        padding: 0 2px !important;
                    }
                `}
            >
                <StrokedTitle strokeColor={color}>{text}</StrokedTitle>
            </Tab>
        )
    }
    return (
        <Tab
            className={className}
            disabled={disabled}
            selected={selected}
            disabledClassName="inactive"
            selectedClassName="active"
            css={`
                @media only screen and (max-width: 599px) {
                    padding: 0 2px !important;
                }
            `}
        >
            <Title
                style={{
                    whiteSpace: 'nowrap',
                    padding: '0 10px',
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 0.75rem;
                        padding: 0 2px !important;
                    }
                `}
            >{text}</Title>
        </Tab>
    );
};

CustomTab.tabsRole = 'Tab';

CustomTab.propTypes = {
    // disabled: PropTypes,
    // selected: PropTypes,
    // className: PropTypes,
    // tabData: PropTypes
};

export { CustomTab };
