import React, { useState } from 'react';
import styled, { withTheme, keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faClock, faHeartCircle } from '@fortawesome/free-solid-svg-icons';
// import withTheme from '../theme';

export const VegIcon = withTheme(({ theme }) => (
    <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.vertFort} size='2x'/>
        <span className="fa-layers-text fa-inverse" style={{textAlign: 'center', left: '80%'}} size='2x'>VG</span>
    </span>
));

export const FishIcon = withTheme(({ theme }) => (
    <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.jauneFort} size='2x'/>
        <span className="fa-layers-text fa-inverse" style={{textAlign: 'center', left: '80%'}} size='2x'>P</span>
    </span>
));

export const MeatIcon = withTheme(({ theme }) => (
    <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.rougeFort} size='2x'/>
        <span className="fa-layers-text fa-inverse" style={{textAlign: 'center', left: '80%'}} size='2x'>V</span>
    </span>
));

export const HeartIcon = withTheme(({ theme }) => (
    <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.beigeFort} size='2x'/>
        <FontAwesomeIcon icon="heart" inverse transform="shrink-8" size='2x'/>
    </span>
));

export const TimeIcon = withTheme(({ theme }) => (
    <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.rougeFaible} size='2x'/>
        <FontAwesomeIcon icon={['far', 'clock']} inverse transform="shrink-6" flip="horizontal" size='2x'/>
    </span>
));

export const ColorIcon = withTheme(({ theme, color, children }) => (
    <span className="fa-layers fa-fw" style={{
        height: 40,
        width: 40,
        // borderRadius: 90,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <FontAwesomeIcon icon="circle" color={theme.color[color]} size='2x' css="font-size: 2.5rem;"/>
        <span className="fa-layers-text fa-inverse" style={{textAlign: 'center'}} size='2x'>{children}</span>
    </span>
));

export const ColorIconOld = withTheme(({ theme, color, children }) => (
    <div className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color[color]} size='2x'/>
        <span className="fa-layers-text fa-inverse" style={{textAlign: 'center', left: '80%'}} size='2x'>{children}</span>
    </div>
));

export const BackIcon = withTheme(({ theme, color }) => (
    <FontAwesomeIcon icon="reply" inverse transform="shrink-8" size='2x' />
));

export const TimeIcon2 = withTheme(({ theme, color }) => (
    <div className="fa-layers fa-fw">
        <FontAwesomeIcon icon="circle" color={theme.color.rougeFaible} fixedWidth />
        <FontAwesomeIcon icon="clock" inverse fixedWidth />
    </div>
));

export const MapMarkerIcon = withTheme(({ theme, color }) => (
    <FontAwesomeIcon icon="map-marker-alt" color={theme.color.rougeFaible} fixedWidth />
));

export const InstagramIcon = withTheme(({ theme, color, className }) => (
    <FontAwesomeIcon icon={['fab', 'instagram']} size='2x' className={className} />
));

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(90deg);
  }
`;

const RotateIcon2 = ({ isClose }) => {
    return (
        <>
            {
                isClose ? (
                    <FontAwesomeIcon
                        icon={['far', 'hand-point-right']}
                        size='1x'
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={['far', 'hand-point-right']}
                        size='1x'
                        css={`
                            animation-name: ${rotate};
                            animation-duration: 1s;
                            animation-timing-function: ease-in;
                            animation-delay: 0s;
                            animation-iteration-count: 1;
                            animation-direction: normal;
                            animation-fill-mode: forwards;
                            animation-play-state: running;
                        `}
                    />
                )
            }
        </>
    );
};

const RotateIcon = ({ isClose }) => {
    return (
        <FontAwesomeIcon
            icon={['far', 'hand-point-right']}
            size='1x'
            css={`
                transform: rotate(0deg);
                overflow: hidden;
                transition: all 0.3s ease-out;
                transform: ${props => (props.rotate ? `rotate(180deg)` : "")};

            `}
        />
    )
}

export const RightHandIcon = withTheme(({ theme, color, className, rotate }) => {
    return (
        <RotateIcon rotate={rotate}/>
    )
});

export const DownHandIcon = withTheme(({ theme, color, className, rotate }) => {
    return (
        <FontAwesomeIcon
            icon={['far', 'hand-point-down']}
            size='1x'
        />
    )
});


const getColor = (type) => {
    switch (type) {
        case 'VG':
            return 'vertFort';
        case 'P':
            return 'jauneFort';
        case 'V':
            return 'rougeFort';
        case 'chrono':
            return 'rougeFaible';
        case '❤':
            return 'beigeFort';
        default:
            return 'beigeFaible';
    }
};

export const Letter = withTheme(styled.div`
    background: ${props => props.theme.color[getColor(props.getColor)]};
    border-radius: 1em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 2em;
    margin-right: 10px;
    text-align: center;
    width: 2em;
    min-width: 2em;
`);

export const Icon = withTheme(({type, style, theme}) => {
    if (type === 'coupDeCoeur') {
        return (
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="circle" color={theme.color.beigeFort} size='2x'/>
                <FontAwesomeIcon icon="heart" inverse transform="shrink-8" size='2x'/>
            </span>
        )
    }
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="circle" color={theme.color.rougeFaible} size='2x'/>
            <FontAwesomeIcon icon={['far', 'clock']} inverse transform="shrink-6" flip="horizontal" size='2x'/>
        </span>
    )
    return (
        <FontAwesomeIcon icon="clock" color={theme.color.rougeFort} size='2x'/>
    )
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="circle" color={theme.color.rougeFort} size='2x'/>
            <FontAwesomeIcon icon="hourglass-half" inverse transform="shrink-8" size='2x'/>
        </span>
    )
    return (
        <span className="fa-layers fa-fw">
            <FontAwesomeIcon icon="circle" color={theme.color.beigeFort} size='2x'/>
            <span className="fa-layers-text fa-inverse" style={{textAlign: 'center'}}>VG</span>
         </span>
    )

    const icon = (() => {
        switch (type) {
        case 'chrono':
            return faClock;
        case 'coupDeCoeur':
            return 'heart'// faHeartCircle;
        default:

        }
    })();

    return (
        <FontAwesomeIcon
            icon={icon}
            color={theme.color.rougeFort}
            size="1em"
            // style={{style}}
            mask={['circle']}
            size='2x'
        />
    );
});
