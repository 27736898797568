import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    password: Yup.string().required('Le mot de passe est requis'),
});

export const DeliveryInformationsSchema = Yup.object().shape({
    deliveryAddress: Yup.string()
        .required('Requis'),
    deliveryPostalCode: Yup.string()
        .required('Requis'),
    deliveryCity: Yup.string()
        .required('Requis'),
    deliveryComments: Yup.string(),
});

export const UserInformationsSchema = Yup.object().shape({
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    password: Yup.string().required('Le mot de passe est requis'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'mots de passes différents')
        .required('Password confirm is required'),
    gender: Yup.string().required('Requis'),
    userInformations: Yup.object().shape({
        birthDate: Yup.object().shape({
            day: Yup.string().max(2).test(
                'is-day',
                'Jour non valide',
                value => parseInt(value, 10) <= 31,
            ).required('Format JJ'),
            month: Yup.string().oneOf([
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
            ], 'Mois non reconnu').required('Choisissez un mois'),
            year: Yup.string()
                .min(4, 'format AAAA')
                .max(4, 'format AAAA')
                .test(
                    'is-year',
                    'Année non valide',
                    value => {
                        const year = parseInt(value, 10);
                        return (year >= 1900 && year <= 2020);
                    },
                ).required('format AAAA'),
        }).required('Indiquez votre date de naissance'),
        firstName: Yup.string()
            .max(50, 'Trop long')
            .required('Requis'),
        lastName: Yup.string()
            .max(50, 'Trop long')
            .required('Requis'),
        phone: Yup.string().required('Requis'),
    }),
    billingAddress: Yup.object().shape({
        address: Yup.string().required('Requis'),
        postalCode: Yup.string().required('Requis'),
        city: Yup.string().required('Requis'),
    }),
    informations: Yup.object().shape({
        familyNumber: Yup.string().required('Requis'),
        foodPreference: Yup.string().required('Requis'),
    }),
});

export const UserInformationsNoEmailSchema = Yup.object().shape({
    /*
    email: Yup.string()
        .email('Email invalide')
        .required('Requis'),
    password: Yup.string().required('Le mot de passe est requis'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'mots de passes différents')
        .required('Password confirm is required'),
    */
    gender: Yup.string().required('Requis'),
    userInformations: Yup.object().shape({
        birthDate: Yup.object().shape({
            day: Yup.string().max(2).test(
                'is-day',
                'Jour non valide',
                value => parseInt(value, 10) <= 31,
            ).required('Format JJ'),
            month: Yup.string().oneOf([
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'
            ], 'Mois non reconnu').required('Choisissez un mois'),
            year: Yup.string()
                .min(4, 'format AAAA')
                .max(4, 'format AAAA')
                .test(
                    'is-year',
                    'Année non valide',
                    value => {
                        const year = parseInt(value, 10);
                        return (year >= 1900 && year <= 2020);
                    },
                ).required('format AAAA'),
        }).required('Indiquez votre date de naissance'),
        firstName: Yup.string()
            .max(50, 'Trop long')
            .required('Requis'),
        lastName: Yup.string()
            .max(50, 'Trop long')
            .required('Requis'),
        phone: Yup.string().required('Requis'),
    }),
    billingAddress: Yup.object().shape({
        address: Yup.string().required('Requis'),
        postalCode: Yup.string().required('Requis'),
        city: Yup.string().required('Requis'),
    }),
    informations: Yup.object().shape({
        familyNumber: Yup.string().required('Requis'),
        foodPreference: Yup.string().required('Requis'),
    }),
});
