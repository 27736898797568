import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';

import FlexDiv from '../../components1/FlexDiv';
import { Text, Title, Bold } from '../../components1/Texts';
import { MapMarkerIcon, TimeIcon2 } from '../../components1/Icons';
import { Check } from '../../components1/forms';
import { recipesPrices, deliveryData, deliveryHoursData } from '../../Constants';
import ReductionCodeForm from '../forms/ReductionCode';

const WrappingDiv = styled.div`
    padding: 10px 0px;
`;

const SansAbonnementInfo = ({ recipesNumber, recipesSelection }) => (
    <WrappingDiv>
        <Title style={{fontSize: '1.5rem', fontWeight: 600}}>
            Vos recettes
        </Title>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <Text>
                {`${recipesNumber} recettes`}
            </Text>
            <NumberFormat
                value={recipesPrices[recipesNumber]}
                displayType="text"
                renderText={value => <Text>{value}</Text>}
                thousandSeparator
                suffix="€"
                decimalScale={2}
                fixedDecimalScale
            />
        </FlexDiv>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <Text>
                Hors abonnement
            </Text>
            <NumberFormat
                value={5}
                displayType="text"
                renderText={value => <Text>+{value}</Text>}
                thousandSeparator
                suffix="€"
                decimalScale={2}
                fixedDecimalScale
            />
        </FlexDiv>
        <Text style={{fontSize: '0.75rem'}}>Vous avez choisi la formule sans abonnement, un supplément vous est facturé.</Text>
        {
            recipesSelection.map(recipe => (
                <Text>
                    {`- ${recipe.name}`}
                </Text>
            ))
        }
    </WrappingDiv>
);

SansAbonnementInfo.propTypes = {
    recipesNumber: PropTypes.number.isRequired,
};

const AbonnementInfo = ({ recipesNumber, recipesSelection }) => (
    <WrappingDiv>
        <Title style={{fontSize: '1.5rem', fontWeight: 600}}>
            Votre abonnement
        </Title>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <Text>
                {`${recipesNumber} recettes par semaine`}
            </Text>
            <NumberFormat
                value={recipesPrices[recipesNumber]}
                displayType="text"
                renderText={value => <Text>{value}</Text>}
                thousandSeparator
                suffix="€"
                decimalScale={2}
                fixedDecimalScale
            />
        </FlexDiv>
        <Text style={{fontSize: '0.75rem'}}>Le contenu de votre CABADICI suivant (nombre de recettes, mode de livraison, compléments...) sera bien-sûr totalement modifiable dans votre espace utilisateur.</Text>
        {
            recipesSelection.map((recipe, id) => (
                <Text key={id.toString()}>
                    {`- ${recipe.name}`}
                </Text>
            ))
        }
    </WrappingDiv>
);

AbonnementInfo.propTypes = {
    recipesNumber: PropTypes.number.isRequired,
};

const ComplementsInfo = ({ itemsSelection }) => (
    <WrappingDiv>
        <Title style={{fontSize: '1.5rem', fontWeight: 600}}>
            Vos compléments
        </Title>
        {
            itemsSelection.map(({name, unitPrice, itemNumber}) => (
                <FlexDiv style={{justifyContent: 'space-between'}}>
                    <Text>
                        {name}
                        {
                            itemNumber > 1 && <Bold>{` x${itemNumber}`}</Bold>
                        }
                    </Text>
                    <NumberFormat
                        value={unitPrice*itemNumber}
                        displayType="text"
                        renderText={value => <Text>{value}</Text>}
                        thousandSeparator
                        suffix="€"
                        decimalScale={2}
                        fixedDecimalScale
                    />
                </FlexDiv>
            ))
        }
    </WrappingDiv>
);

ComplementsInfo.propTypes = {
    itemsSelection: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
    })).isRequired,
};

const LivraisonInfo = ({ deliveryChoice, partnerDeliveryAddress, privateDeliveryAddress, deliveryHoursChoice }) => (
    <WrappingDiv>
        <Title style={{fontSize: '1.5rem', fontWeight: 600}}>
            Votre livraison
        </Title>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <Text>
                {deliveryData[deliveryChoice].text}
            </Text>
            {
                // eslint-disable-next-line no-restricted-globals
                isNaN(deliveryData[deliveryChoice].price) ?
                    (
                        <Text>
                            {deliveryData[deliveryChoice].price}
                        </Text>
                    ) : (
                        <NumberFormat
                            value={deliveryData[deliveryChoice].price}
                            displayType="text"
                            renderText={value => <Text>{value}</Text>}
                            thousandSeparator
                            suffix="€"
                            decimalScale={2}
                            fixedDecimalScale
                        />
                    )
            }
        </FlexDiv>
        {
            deliveryChoice === 0 && (
                <Text><MapMarkerIcon /><span style={{paddingLeft: 5}} >{`${partnerDeliveryAddress.name} (${partnerDeliveryAddress.location})`}</span></Text>
            )
        }
        {
            deliveryChoice === 2 && (
                <Text><MapMarkerIcon /><span style={{paddingLeft: 5}} >{`${privateDeliveryAddress.deliveryAddress}, ${privateDeliveryAddress.deliveryPostalCode} ${privateDeliveryAddress.deliveryCity}`}</span></Text>
            )
        }
        <Text><TimeIcon2 /><span style={{paddingLeft: 5}} >{deliveryHoursData[deliveryHoursChoice].text}</span></Text>
        <FlexDiv style={{justifyContent: 'space-between'}}>
            <Text>
                Sac CABADICI,
                <br />
                pochette iso et plaquette de froid
            </Text>
            <Text>
                offerts
            </Text>
        </FlexDiv>
    </WrappingDiv>
);

LivraisonInfo.propTypes = {
    deliveryChoice: PropTypes.number.isRequired,
};

const ReductionCode = ({ handleCoupon, handleCouponError }) => (
    <WrappingDiv>
        <ReductionCodeForm handleCoupon={handleCoupon} handleCouponError={handleCouponError} />
    </WrappingDiv>
);

const Sum = ({ orderTotalSum }) => (
    <WrappingDiv>
        <FlexDiv style={{justifyContent: 'flex-end'}}>
            <NumberFormat
                value={orderTotalSum}
                displayType="text"
                renderText={value => (
                    <Text
                        style={{fontWeight: 600, paddingTop: 10}}
                        css={`border-top: dashed 2px ${props => props.theme.color.beigeFort};`}
                    >{value}</Text>
                )}
                thousandSeparator
                suffix="€"
                decimalScale={2}
                fixedDecimalScale
            />
        </FlexDiv>
    </WrappingDiv>
);

Sum.propTypes = {
    orderTotalSum: PropTypes.number.isRequired,
};

const Total = ({ orderTotalSum }) => (
    <WrappingDiv>
        <FlexDiv style={{justifyContent: 'flex-end'}}>
            <Text style={{fontSize: '1.25rem', fontWeight: 600, paddingRight: 40}}>
                TOTAL
            </Text>
            <NumberFormat
                value={orderTotalSum}
                displayType="text"
                renderText={value => <Text style={{fontSize: '1.25rem', fontWeight: 600}}>{value}</Text>}
                thousandSeparator
                suffix="€"
                decimalScale={2}
                fixedDecimalScale
            />
        </FlexDiv>
    </WrappingDiv>
);

Total.propTypes = {
    orderTotalSum: PropTypes.number.isRequired,
};

const CgvValidator = ({innerRef, innerChecked, innerOnChange}) => (
    <Text style={{fontSize: '0.875rem', padding: '5px 0px'}}>
        <Check
            ref={innerRef}
            checked={innerChecked}
            onChange={innerOnChange}
            onClick={() => innerRef.current.blur()}
        />
        {'J\'ai lu et j\'accepte les conditions générales de vente (un email vous sera envoyé en cas de modification)'}
    </Text>
);

CgvValidator.propTypes = {
    innerRef: PropTypes.shape().isRequired,
    innerChecked: PropTypes.bool.isRequired,
    innerOnChange: PropTypes.func.isRequired,
};

export {
    SansAbonnementInfo,
    AbonnementInfo,
    ComplementsInfo,
    LivraisonInfo,
    ReductionCode,
    Sum,
    Total,
    CgvValidator,
};
