export const recipesPrices = {
    2: 70,
    3: 90,
    4: 110,
    5: 120,
};

export const deliveryData = {
    0: {
        text: 'Retrait chez un partenaire',
        price: 'offert',
    },
    1: {
        text: 'Livraison groupée',
        price: 0,
    },
    2: {
        text: 'Livraison privée',
        price: 10,
    },
};

export const subscriptionPrice = {
    0: 0,
    1: 5,
};

export const deliveryHoursData = [
    {
        text: 'Le matin',
    },
    {
        text: 'L\'après-midi',
    },
    {
        text: 'Peu importe dans la journée',
    },
];

export const { GATSBY_STRIPE_API_KEY } = process.env;
