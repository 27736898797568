import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { BackgroundCell } from '../../components/Grid';
import { Text } from '../../components1/Texts';
import { VegIcon, FishIcon, MeatIcon, HeartIcon, TimeIcon } from '../../components1/Icons';
import FlexDiv from '../../components1/FlexDiv';
import ParentheseBackgroundVerte from '../../components1/ParentheseBackgroundVerte';

const mapDispatchToProps = dispatch => {
    return {
        addRecipeToSelection: (value) => dispatch({ type: 'ADD_RECIPE_TO_SELECTION', value }),
        removeRecipeFromSelection: (value) => dispatch({ type: 'REMOVE_RECIPE_FROM_SELECTION', value }),
    };
};

class RecipeDetailViewBack extends Component {
    static propTypes = {
        data: PropTypes.shape({
            id: PropTypes.number.isRequired,
            imgUrl: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            time: PropTypes.string.isRequired,
        }).isRequired,
    };

    renderIcon = (type) => {
        switch (type) {
        case 'VG':
            return (<VegIcon />);
        case 'P':
            return (<FishIcon />);
        case 'V':
            return (<MeatIcon />);
        case 'Mois':
            return (<HeartIcon />);
        case 'Recette Longue':
            return (<TimeIcon />);
        default:
            throw new Error('icone inconnue');
        }
    };

    render() {
        const { data } = this.props;
        return (
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 20,
                    }}
                >
                    <Text style={{
                        fontWeight: 600,
                        fontSize: '1.25rem',
                        maxWidth: 400,
                        padding: '1em',
                        textAlign: 'center',
                        justifySelf: 'center',
                        textAlignLast: 'center',
                        alignItems: 'center'
                    }}>
                        {data.name}
                    </Text>
                    <Text
                        style={{
                            maxWidth: 400,
                            textAlign: 'center'

                        }}
                    >
                        {
                            data.shortDescription
                        }
                    </Text>
                    <FlexDiv
                        style={{
                            padding: 20,
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                flex-direction: column;
                            }
                        `}
                    >
                        <FlexDiv
                            style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingRight: 10,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding-left: 0px !important;
                                }
                            `}
                        >
                            <img
                                src={data.imgUrl}
                                style={{
                                    maxWidth: 200
                                }}
                                alt="identite"
                                width="100%"
                            />
                        </FlexDiv>
                        <FlexDiv
                            style={{
                                flexDirection: 'column',
                                maxWidth: 200,
                                justifyContent: 'space-between',
                                paddingLeft: 10,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    padding-left: 0px !important;
                                    justify-content: flex-start !important;
                                }
                            `}
                        >
                            <FlexDiv
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flex:0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding: 20px !important;
                                    }
                                `}
                            >
                                {
                                    this.renderIcon(data.type)
                                }
                                <Text
                                    style={{
                                        paddingLeft: 15,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {`${data.time}`}
                                </Text>
                            </FlexDiv>
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    flex: 0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-top: 20px !important;
                                        flex:1 !important;
                                    }
                                `}
                            >
                                <Text style={{fontWeight: 600, textAlign: 'justify',}}>
                                    {
                                        'Ingrédients dans votre CABADICI :'
                                    }
                                </Text>
                                <Text
                                    style={{
                                        paddingTop: 10,
                                        textAlign: 'justify',
                                    }}
                                >
                                    {
                                        data.ingredients.map(i => i.name).join(', ')
                                    }
                                </Text>
                            </FlexDiv>
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    flex:0,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        padding-top: 20px !important;
                                    }
                                `}
                            >
                                <Text style={{fontWeight: 600, textAlign: 'justify',}}>
                                    {
                                        'Ingrédients dans vos placards :'
                                    }
                                </Text>
                                <Text
                                    style={{
                                        paddingTop: 10,
                                        textAlign: 'justify',
                                    }}
                                >
                                    {
                                        data.home.join(', ')
                                    }
                                </Text>
                            </FlexDiv>
                        </FlexDiv>
                    </FlexDiv>
                </FlexDiv>
        );
    }
}

export default connect(null, mapDispatchToProps)(RecipeDetailViewBack);
