import styled from 'styled-components';
import withTheme from '../theme';

export const Bold = withTheme(styled.span`
    font-family: ${props => props.theme.font.main};
    font-weight: 600;
    font-size: ${props => props.size ? `${props.size}px` : '16px'}
`);

export const Text = withTheme(styled.div`
    /*padding: 10px;*/
    font-family: ${props => props.special ? props.theme.font.special : props.theme.font.main};
`);

export const Title = withTheme(styled.div`
    text-transform: uppercase;
    font-size: 1.875rem;
    /*padding: 10px;*/
    font-weight: 600;
    font-family: ${props => props.theme.font.title};
`);
