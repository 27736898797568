import React, { Component } from 'react';
import Modal2 from 'react-responsive-modal';
import Popup from 'reactjs-popup';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 400,

  }
};

const higherOrderComponentClass = WrappedComponent => {
    class HOC extends Component {
        state = {
            modalIsOpen: false,
        };

        openModal = () => {
            this.setState({ modalIsOpen: true });
        };

        closeModal = () => {
            this.setState({ modalIsOpen: false });
        };

        render() {
            return (
                <div>
                    <Modal
                        shouldFocusAfterRender={false}
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal">
                        <button onClick={this.closeModal}>close</button>
                        <WrappedComponent closeModal={this.closeModal}/>
                    </Modal>
                </div>
            );
        }
    }
    return HOC;
};

export default higherOrderComponentClass;
