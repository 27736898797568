import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Checkbox from './forms1/Checkbox';

class GreenCheckbox extends Component {
    static propTypes = {
        innerHandleCheck: PropTypes.func.isRequired,
        className: PropTypes.string,
        checked: PropTypes.bool,
    };

    // https://alligator.io/react/get-derived-state/
    static getDerivedStateFromProps(props, state) {
        if (props.checked !== state.checked) {
            return {
                checked: props.checked,
            };
        }

        // Return null if the state hasn't changed
        return null;
    };

    state = {
        checked: false,
    };

    handleCheckboxChange = event => {
        const { innerHandleCheck } = this.props;
        this.setState({ checked: event.target.checked });
        innerHandleCheck(event.target.checked);
    };

    render() {
        // add className to handle styled properties
        const { className } = this.props;
        const { checked } = this.state;
        return (
            <div
                className={className}
            >
                <label>
                    <Checkbox
                        checked={checked}
                        onChange={this.handleCheckboxChange}
                        colors={['noirFort', 'noirFaible']}
                    />
                </label>
            </div>
        );
    }
}

export default styled(GreenCheckbox)`
    margin: 20px 0;
    &:hover {
        opacity: 0.7;
    }
`;
