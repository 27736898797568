import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabPanel } from 'react-tabs';

import Modalize from '../../components/Modalizer_';
import { CustomTabList, CustomTab } from '../../components1/Tabs';
import { Text } from '../../components1/Texts';
import OrderPageTab from '../firstOrder/OrderPage';
import CoordinatesPage from '../CoordinatesPage';
import PaymentPage from '../payment/PaymentPage';
import BasketContent from '../BasketContent';

class FirstOrder extends Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        orderSuccessModalRef: PropTypes.shape().isRequired,
        connectionModalRef: PropTypes.shape().isRequired,
    };

    state = {
        tabIndex: 0
    };

    modalRef = React.createRef();

    scrollTo = () => {
        const scrollElement = document.getElementById('FirstOrderScroller');
        scrollElement.scrollTo(0, 0);
        scrollElement.dispatchEvent(new Event('scroll'));
    };

    innerNextTab = () => {
        this.setState(({ tabIndex }) => ({ tabIndex: tabIndex + 1 }));
        this.scrollTo();
    };

    innerPrevTab = () => {
        this.setState(({ tabIndex }) => ({ tabIndex: tabIndex - 1 }));
        this.scrollTo();
    };

    render() {
        const { tabIndex } = this.state;
        const { closeModal, orderSuccessModalRef, connectionModalRef } = this.props;

        return (
            <>
                <BasketContent closeModal={closeModal} />
                <Tabs selectedIndex={tabIndex}>
                    <CustomTabList css="margin: 0 0 20px 0;">
                        <CustomTab tabData={{color: 'vertFort', text: 'Vos choix'}} />
                        <CustomTab tabData={{color: 'rougeFort', text: 'Vos Coordonnées'}} />
                        <CustomTab tabData={{color: 'jauneFort', text: 'Paiement'}} />
                    </CustomTabList>

                    <TabPanel>
                        {
                            /*
                                <OrderPage innerNextTab={this.innerNextTab} />
                            */
                        }
                        <OrderPageTab innerNextTab={this.innerNextTab} scrollTo={this.scrollTo}/>
                    </TabPanel>

                    <TabPanel>
                        <CoordinatesPage
                            innerNextTab={this.innerNextTab}
                            innerPrevTab={this.innerPrevTab}
                            connectionModalRef={connectionModalRef}
                        />
                    </TabPanel>

                    <TabPanel>
                        <PaymentPage
                            innerPrevTab={this.innerPrevTab}
                            closeModal={closeModal}
                            orderSuccessModalRef={orderSuccessModalRef}
                        />
                    </TabPanel>
                </Tabs>
            </>
        );
    }
}

export default Modalize(FirstOrder, {overlayId: 'FirstOrderScroller', showCloseIcon: false});
