import styled from 'styled-components';

const RecipesGrid = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 0 5px;
    padding-bottom: 20px;
    // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-columns: repeat(5, minmax(135px, 1fr));
    /*grid-auto-rows: 150px;*/
    /*grid-auto-flow: row dense;*/
    justify-content: center;

    /* tablet */
    @media only screen and (min-width : 700px) and (max-width : 1024px) {
        /*grid-template-columns: repeat(3, minmax(135px, 1fr));*/
        grid-template-columns: repeat(12, minmax(11px, 1fr));

        .col:nth-child(-1n + 3) {
            grid-column: span 4;
        }
        .col:nth-last-child(2) {
            grid-row-start: 2;
            grid-column: 3 / span 4;
        }
        .col:nth-last-child(1) {
            grid-row-start: 2;
            grid-column: 7 / span 4;
        }
    }

    @media only screen and (max-width: 700px) {
        /* grid-template-columns: 0.5fr; */
        /* grid-template-columns: repeat(2, minmax(135px, 1fr));*/
        grid-template-columns: repeat(8, minmax(17px, 1fr)) !important;
        grid-row-gap: 20px !important;
        grid-column-gap: 10px !important;
        .col:nth-child(-1n + 4) {
            grid-column: span 4 !important;
        }
        .col:nth-last-child(1) {
            grid-row-start: 3 !important;
            grid-column: 3 / span 4 !important;
        }
    }
`;

export default RecipesGrid;
