import React, { Component } from 'react';
import { css, withTheme } from 'styled-components';
import { connect } from 'react-redux';
import Ink from 'react-ink';

import FlexDiv from '../components1/FlexDiv';
import { Title, Text } from '../components1/Texts';

const subscriptionData = [
    {
        title: 'Oui',
        text: 'S\'abonner et recevoir chaque semaine un CABADICI',
        textLight: 'engagement de 2 commandes minimum'
    },
    {
        title: 'Non',
        text: 'Essayer CABADICI sans abonnement',
        textBold: '+ 5.00€'
    },
];

const halo = css`4px solid ${props => props.theme.color.beigeFort}`;

const SubscriptionChoice = ({ data, checked, innerOnClick, value }) => (
    <FlexDiv
        checked={checked}
        style={{
            flexDirection: 'column',
            justifyContent: 'space-between',
            margin: 10,
            padding: 10,
            position: 'relative',
            cursor: 'pointer',
        }}
        css={`
            background-color: ${props => props.theme.color.beigeFaible};
            /* border: ${props => props.checked ? 'solid' : 'none'};*/
            outline: ${props => props.checked ? halo : 'none'};
            outline-offset: 2px;
            &:hover {
                opacity: 0.7;
            }
        `}
        onClick={() => innerOnClick(value)}
    >
        <Ink background={false} opacity={0.1}/>
        <Title>{data.title}</Title>
        <Text
            css={`
                @media only screen and (max-width: 599px) {
                    font-size: 0.75rem;
                }
            `}
        >{data.text}</Text>
        {
            data.textLight && (
                <Text
                    style={{fontSize: '0.7rem'}}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.625rem !important;
                        }
                    `}
                >{data.textLight}</Text>)
        }
        {
            data.textBold && (<Text style={{fontWeight: 600}}>{data.textBold}</Text>)
        }
    </FlexDiv>
);

const mapStateToProps = ({subscriptionChoice}) => ({
    subscriptionChoice
});

const mapDispatchToProps = dispatch => {
    return {
        updateSubscriptionChoice: (value) => dispatch({ type: 'UPDATE_SUBSCRIPTION_CHOICE', value }),
    };
};

class SubscriptionSelector extends Component {

    innerOnClick = (value) => {
        const { updateSubscriptionChoice } = this.props;
        updateSubscriptionChoice(value);
    }

    render() {
        const { theme, subscriptionChoice } = this.props;

        return (
            <FlexDiv
                style={{
                }}
            >
                {
                    subscriptionData.map((sub, index) => (
                        <SubscriptionChoice
                            key={index.toString()}
                            data={sub}
                            checked={(subscriptionChoice === index)}
                            innerOnClick={this.innerOnClick}
                            value={index}
                        />
                    ))
                }
            </FlexDiv>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(SubscriptionSelector));
