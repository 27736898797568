import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import { getNextFriday } from '../../services/dates';
import Separator from '../../components1/Separator';

import DeliveryHoursSelector from '../delivery/DeliveryHoursSelector';
import DeliverySelector from '../delivery/DeliverySelector';

const mapStateToProps = ({
    deliveryChoice,
    isLogged,
    partnerDeliveryAddress,
    privateDeliveryAddress,
}) => ({
    deliveryChoice,
    isLogged,
    partnerDeliveryAddress,
    privateDeliveryAddress,
});

class FirstOrderRecipesChoice3 extends Component {

    static propTypes = {
        innerNextTab: PropTypes.func.isRequired,
        goToPrevSlide: PropTypes.func.isRequired,
        deliveryChoice: PropTypes.number,
    };

    state = {
        error: null,
    };

    onClick = () => {
        const { innerNextTab, deliveryChoice, partnerDeliveryAddress, privateDeliveryAddress } = this.props;
        if ((deliveryChoice === 2 && !!privateDeliveryAddress) || (deliveryChoice === 0 && !!partnerDeliveryAddress)) {
            return innerNextTab();
        }
        this.setState({
            error: 'Vous n\'avez pas choisi de mode de livraison',
        });
    }

    componentDidUpdate = () => {
        const { deliveryChoice, partnerDeliveryAddress, privateDeliveryAddress } = this.props;
        const { error } = this.state;
        if (((deliveryChoice === 2 && !!privateDeliveryAddress) || (deliveryChoice === 0 && !!partnerDeliveryAddress)) && error) {
            this.setState({error : null});
        }
    };

    render() {
        const { goToPrevSlide, scrollTo, isLogged, noButtons, innerModalRef } = this.props;
        const { error } = this.state;
        return (
            <>
                {
                    !isLogged ? (
                        <Text
                            style={{
                                paddingBottom: 10,
                                fontSize: '2.5rem',
                                fontWeight: 600,
                            }}
                            css={`
                                font-family: ${props => props.theme.font.title};
                                @media only screen and (max-width: 599px) {
                                    font-size: 1.5rem !important;
                                }
                            `}
                        >
                            {
                                !isLogged ? 'Votre' : 'Ma prochaine'
                            } livraison
                        </Text>
                    ) : (
                        <Text
                            style={{
                                textAlign: 'center',
                                fontSize: '1.5rem',
                                fontWeight: 600,
                                paddingTop: 20,
                                paddingBottom: 10,
                                margin: '0 auto',
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 1rem !important;
                                    padding-bottom: 0 !important;
                                }
                                font-family: ${props => props.theme.font.title};
                            `}
                        >4 – Je choisis mes modalités de livraison :</Text>
                    )
                }
                <Text
                    style={{
                        textAlign: 'center',
                        fontSize: '1.5rem',
                        fontWeight:600,
                        textTransform: 'capitalize',
                        padding: '10px 0',
                    }}
                    css={`
                        text-decoration: underline ${props => props.theme.color.rougeFort};
                        @media only screen and (max-width: 599px) {
                            font-size: 1.125rem !important;
                        }
                        font-family: ${props => props.theme.font.title};
                    `}
                >
                    {
                        getNextFriday()
                    }
                </Text>
                <DeliverySelector isLogged={isLogged} />
                <DeliveryHoursSelector />
                {
                    !noButtons ? (
                        <>
                            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
                            <FlexDiv style={{
                                justifyContent: 'space-evenly',
                                alignItems: 'flex-start'
                            }}>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 1}}
                                >
                                    <BackButton
                                        size="1.115rem"
                                        color="jauneFort"
                                        onClick={goToPrevSlide}

                                    />
                                </FlexDiv>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 3}}
                                >
                                    <CabadiciButton
                                        color="rougeFort"
                                        onClick={this.onClick}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                font-size: 1.625rem !important;
                                            }
                                        `}
                                    >
                                        suivant
                                    </CabadiciButton>
                                </FlexDiv>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 1}}
                                />
                            </FlexDiv>
                        </>
                    ) : (
                        <FlexDiv style={{
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-end'
                        }}>
                            <FlexDiv
                                style={{justifyContent: 'center', flex: 1}}
                            >
                                <BackButton
                                    size="1.115rem"
                                    color="rougeFort"
                                    onClick={() => {
                                        goToPrevSlide();
                                        scrollTo();
                                    }}

                                />
                            </FlexDiv>
                            <FlexDiv
                                style={{justifyContent: 'center', flex: 3}}
                            >
                                <CabadiciButton
                                    color="rougeFaible"
                                    onClick={this.onClick}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 1.625rem !important;
                                        }
                                    `}
                                >
                                    finaliser ma commande
                                </CabadiciButton>
                            </FlexDiv>
                            <FlexDiv
                                style={{justifyContent: 'center', flex: 1}}
                            />
                        </FlexDiv>
                    )
                }
                {
                    error && (
                        <Text
                            style={{
                                color: 'red',
                                fontSize: '0.875rem',
                                paddingTop: 10,
                            }}
                        >
                            {error}
                        </Text>
                    )
                }
            </>
        );
    }
}

export default connect(mapStateToProps)(FirstOrderRecipesChoice3);
