import React from 'react';
import PropTypes from 'prop-types';

import Modalize from '../../components/Modalizer_';
import { Text, Title } from '../../components1/Texts';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';

const PartnerAddress = ({ data, onSubmit, closeModal}) => {
    const {name, partnerAddress: {street, postalCode, city}} = data;
    return (
        <>
            <FlexDiv
                style={{
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
                css={`
                    @media only screen and (min-width: 599px) {
                        min-width: 300px;
                    }
                `}
            >
                <Title>{name}</Title>
                <Text>{street}</Text>
                <Text>{`${postalCode} ${city}`}</Text>
            </FlexDiv>
            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="jauneFort"
                        onClick={closeModal}

                    />
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 3}}
                >
                    <CabadiciButton
                        color="rougeFort"
                        onClick={() => {
                            onSubmit(data);
                            closeModal()
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.625rem !important;
                            }
                        `}
                    >
                        sélectionner
                    </CabadiciButton>
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>
        </>
    );
};

export default Modalize(PartnerAddress, {showCloseIcon: false});
