import React from 'react';
import PropTypes from 'prop-types';

import Modalize from '../../components/Modalizer_';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';

const DeactivateSubscription = ({ closeModal, data }) => {
    return (
        <FlexDiv style={{flexDirection: 'column', paddingTop: 20}}>
            {
                data.title && (
                    <Title style={{textAlign: 'center'}}>
                        {data.title}
                    </Title>
                )
            }
            <Text style={{textAlign: 'center'}}>
                Nous avons bien pris en compte
                <br />
                votre demande.
            </Text>
            <Text style={{textAlign: 'center'}}>
                {
                    data.message
                }
            </Text>
            <FlexDiv style={{ justifyContent: 'center', paddingTop: 10 }}>
                <CabadiciButton
                    color="jauneFort"
                    onClick={closeModal}
                >
                    fermer
                </CabadiciButton>
            </FlexDiv>
        </FlexDiv>
    );
}

DeactivateSubscription.propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.shape({message: PropTypes.string}),
};

export default Modalize(DeactivateSubscription);
