import React from 'react';
import PropTypes from 'prop-types';

import Carousel from '../../components1/carousel';
import FirstOrderPage1 from './FirstOrderPage1';
import FirstOrderPage2 from './FirstOrderPage2';
import FirstOrderPage3 from './FirstOrderPage3';

const FirstOrderTab = ({ innerNextTab, scrollTo }) => (
    <Carousel>
        {
            [
                <FirstOrderPage1 key="FirstOrderPage1" scrollTo={scrollTo} showSubscriptionOptions />,
                <FirstOrderPage2 key="FirstOrderPage2" scrollTo={scrollTo} />,
                <FirstOrderPage3 key="FirstOrderPage3" innerNextTab={innerNextTab} />,
            ]
        }
    </Carousel>
);

FirstOrderTab.propTypes = {
    innerNextTab: PropTypes.func.isRequired,
};

export default FirstOrderTab;
