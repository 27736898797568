import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modalize from '../../components/Modalizer_';
import SignUpForm from '../forms/SignUpForm';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import VerticalSeparator from '../../components1/VerticalSeparator';
import { CabadiciButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';
import { signInUser } from '../../actions';
import PasswordForgetModal from './PasswordForget';
import SignInSignUpErrorModal from './SignInSignUpError';

const mapStateToProps = ({ error }) => ({
    error,
});

const mapDispatchToProps = dispatch => {
    return {
        signInUserNOO: values => dispatch(signInUser(values)),
    };
};

const SignInSignUp = ({error, signInUser, firstOrderRef, closeModal}) => {
    const passwordForgetModalRef = React.createRef();

    return (
        <>
            <FlexDiv
                css={`
                    @media only screen and (max-width: 599px) {
                        flex-direction: column;
                    }
                `}
            >
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        padding: '20px 20px 0 20px',
                    }}
                >
                    <Title
                        style={{
                            textTransform: 'none',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            paddingBottom: 20,
                        }}
                    >
                        Me connecter
                    </Title>
                    <SignUpForm onSubmit={signInUser} />
                    {
                        // error && (<Text>{error.message}</Text>)
                    }
                    <Text
                        style={{paddingTop: 40, fontSize: '0.875rem', alignSelf: 'center'}}
                        css={`
                            :hover {
                                opacity: 0.8;
                                cursor: pointer;
                            }
                        `}
                        onClick={() => {
                            passwordForgetModalRef.current.openModal();
                        }}
                    >
                        Mot de passe oublié ?
                    </Text>
                </FlexDiv>
                <VerticalSeparator
                    strokeColor="vertFaible"
                    strokeWeight="5px"
                    css={`
                        @media only screen and (max-width: 599px) {
                            display: none;
                        }
                    `}
                />
                <Separator
                    strokeColor="vertFaible"
                    strokeWeight="5px"
                    css={`
                        @media only screen and (min-width: 599px) {
                            display: none;
                        }
                    `}
                />
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 20,
                    }}
                >
                    <Title
                        style={{
                            textTransform: 'none',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            paddingBottom: 20,
                        }}
                    >
                        {'M\'inscrire &'}
                        <br />
                        valider
                        <br />
                        ma première commande
                    </Title>
                    <CabadiciButton
                        color="rougeFort"
                        onClick={() => {
                            firstOrderRef.current.openModal();
                            closeModal();
                        }}
                    >
                        Inscription
                    </CabadiciButton>
                </FlexDiv>
            </FlexDiv>
            <PasswordForgetModal ref={passwordForgetModalRef} />
            <SignInSignUpErrorModal isOpen={!!error} passwordForgetModalRef={passwordForgetModalRef} />
        </>
    );
};


SignInSignUp.propTypes = {
    error: PropTypes.shape(),
    signInUser: PropTypes.func.isRequired,
    firstOrderRef: PropTypes.shape().isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default Modalize(connect(mapStateToProps, mapDispatchToProps)(SignInSignUp), {
    onClose: () => {
        /**
        Clean error from redux
        */
    }
});
