import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Formik, Form, Field, ErrorMessage as ErrorMessageWithoutStyle } from '../../components1/forms/formik';
import { Text as TextWithoutStyle} from '../../components1/Texts';
import { DrawHandStyleInput, DrawHandStyleTexArea } from '../../components1/forms';
import { DeliveryInformationsSchema } from './schemas';

const Text = styled(TextWithoutStyle)`
    @media only screen and (max-width: 599px) {
        font-size: 0.75rem;
    }
`;

const ErrorMessage = styled(ErrorMessageWithoutStyle)`
    @media only screen and (max-width: 599px) {
        font-size: 0.75rem;
    }
`;

const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    grid-template-areas:
        'a a b b'
        '. . bb bb'
        '. . c d'
        '. . cc dd'
        'e e f f'
        'e e f f'
        'g g g g';

    @media only screen and (max-width: 599px){
        grid-template-columns: 1fr 1fr !important;
        grid-column-gap: 5px;
        grid-template-rows: repeat(6, auto);
        grid-template-areas:
            'a b'
            'c d'
            'e e'
            'f f'
            'f f'
            'g .' !important;
    }

    @media only screen and (max-width: 420px){
        grid-template-columns: 1fr  0.5fr !important;
        grid-template-rows: repeat(9, auto);
        grid-column-gap: 5px;
        grid-template-areas:
            'a .'
            'b bb'
            'c cc'
            'd dd'
            'e .'
            'e .'
            'f f'
            'f f'
            'g g' !important;
    }
`;

const DeliveryInformations = React.forwardRef(({onSubmit, ...props}, ref) => (
    <Formik
        ref={ref}
        initialValues={{
            deliveryAddress: '',
            deliveryPostalCode: '',
            deliveryCity: '',
            deliveryComments: '',
        }}
        validationSchema={DeliveryInformationsSchema}
        onSubmit={onSubmit}
        {...props}
    >
        <Form>
            <FormGrid>
                <Text style={{gridArea: 'a', fontWeight: 600}}>
                    Adresse de livraison
                </Text>

                <Field name="deliveryAddress" style={{gridArea: 'b'}} component={DrawHandStyleInput} placeholder="rue, avenue, impasse, ..."/>
                <ErrorMessage name="deliveryAddress" component="div" style={{gridArea: 'bb'}}/>

                <Field name="deliveryPostalCode" style={{gridArea: 'c'}} component={DrawHandStyleInput}  placeholder="code postal"/>
                <ErrorMessage name="deliveryPostalCode" component="div" style={{gridArea: 'cc'}}/>

                <Field name="deliveryCity" style={{gridArea: 'd'}} component={DrawHandStyleInput}  placeholder="ville"/>
                <ErrorMessage name="deliveryCity" component="div" style={{gridArea: 'dd'}}/>

                <Text style={{gridArea: 'e', fontWeight: 600}}>
                    Commentaires
                </Text>

                <Field name="deliveryComments" style={{gridArea: 'f'}} component={DrawHandStyleTexArea} placeholder="Etage, interphone, ..."/>
                <ErrorMessage name="deliveryComments" component="div" style={{gridArea: 'f'}}/>

                <Text
                    style={{
                        gridArea: 'g',
                        fontSize: '0.85rem',
                        textAlign: 'center',
                        paddingTop: 20,
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.75rem;
                        }
                    `}
                >
                    Vous serez informé la veille de votre livraison du moment de livraison.
                </Text>

            </FormGrid>
        </Form>
    </Formik>
));

DeliveryInformations.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default DeliveryInformations;
