/* eslint import/extensions: 'off' */

// needed by gatsby
// export { default as Layout } from './layout/index.js';

/** Previous version break production build */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import HeroImage from './HeroImage';
import Logo from './Logo';
import SubHeaderTabs from './layoutFolder/SubHeaderTabs';
import MenuBurger from './layoutFolder/MenuBurger';
import Footer from './layoutFolder/Footer';
import UserDataWrapper from '../containers/user/UserDataWrapper';
import InfoMessage from './InfoMessage';

// import FirstOrderPage, {SignUpPage as Tutu} from './forms/FirstOrder';
// import DemoForm from './forms/UserInformations';

// import Modalize from '../components/Modalizer_';
// import SignUp from './forms/SignUp';

import SignInFormModal from '../containers/modals/SignInSignUp';
import FirstOrderModal from '../containers/modals/FirstOrder';
import OrderSuccessModal from '../containers/modals/OrderSuccess';
import { signInUser, deconnexion, signOut } from '../actions';

import { getMessage } from '../services';
/*
const SignInFormOld = Modalize(SignUp, {
    onClose: () => {},
    onExited: () => {},
});
*/
const mapStateToProps = ({isLogged, authUserData}) => ({isLogged, authUserData});

const mapDispatchToProps = dispatch => {
    return {
        signInUser: values => dispatch(signInUser(values)),
        deconnexion: () => dispatch(deconnexion()),
        signOut: () => dispatch(signOut()),
    }
}

class Layout extends Component {
    state = {
        message: false,
    };

    connectFormRef = React.createRef();

    firstOrderRef = React.createRef();

    orderSuccessModalRef = React.createRef();

    openfirstOrderForm = () => {
        const { signOut } = this.props;
        signOut()
        this.firstOrderRef.current.openModal();
    }

    openConnectForm = () => {
        this.connectFormRef.current.openModal();
    }

    signIn = (values) => {
        const {signInUser} = this.props;
        signInUser(values)
            .then(() => {
                navigate('/user');
            });
    };

    componentDidMount = async () => {
        const { isUserPage, pageName } = this.props;
        const message = await getMessage();

        if (message) {
            this.setState({
                message: message[0],
            })
        }

        if (isUserPage) {
            return;
        }
        const { doOpenConnectForm, isLogged, authUserData } = this.props;
        // redirect customers & user to /user section
        // if (isLogged && authUserData && authUserData.isCustomer) {
        if (isLogged && !['cgv', 'faq', 'mentions-legales'].includes(pageName)) {
            navigate('/user');
            return;
        }
        if (isLogged && authUserData && !authUserData.isCustomer) {
            setTimeout(() => {
                this.openfirstOrderForm();
            }, 1000);
            return;
        }
        if (typeof doOpenConnectForm !== 'undefined' && doOpenConnectForm) {
            setTimeout(() => {
                this.openConnectForm();
            }, 1000);
        }
    }

    _closeMessage = () => {
        this.setState({message: false});
    }

    render() {
        const { children, isIndex, noFooter, doOpenConnectForm, signInUser, isUserPage, isLogged, signOut, pageName } = this.props;
        const isFromLoggedContent = isUserPage || (isLogged && ['cgv', 'faq', 'mentions-legales'].includes(pageName))
        const { message } = this.state;

        return (
            <>
                {
                    message && (
                        <InfoMessage message={message} onClick={this._closeMessage}/>
                    )
                }
                <MenuBurger modalRef={this.connectFormRef} isUserPage={isFromLoggedContent} isLogged={isLogged} signOut={signOut} />
                {
                    isIndex && (
                        <HeroImage />
                    )
                }
                <Logo isUserPage={isUserPage} />

                <SubHeaderTabs modalRef={this.connectFormRef} isUserPage={isFromLoggedContent} isLogged={isLogged} signOut={signOut}/>

                {
                    /*
                    isUserPage ?
                        UserDataWrapper(children) :
                        children({
                            openConnectForm: this.openConnectForm
                        })
                        */

                        children({
                            openConnectForm: this.openConnectForm
                        })
                }
                {
                    !noFooter && <Footer />
                }
                {
                    !isUserPage && (
                        <>
                            <OrderSuccessModal ref={this.orderSuccessModalRef} />
                            <FirstOrderModal
                                ref={this.firstOrderRef}
                                orderSuccessModalRef={this.orderSuccessModalRef}
                                connectionModalRef={this.connectFormRef}
                            />
                            <SignInFormModal
                                ref={this.connectFormRef}
                                firstOrderRef={this.firstOrderRef}
                                signInUser={this.signIn}
                            />
                        </>
                    )
                }
            </>
        );
    }
}

Layout.defaultProps = {
    isIndex: false,
    noFooter: false,
};

Layout.propTypes = {
    children: PropTypes.func.isRequired,
    isIndex: PropTypes.bool,
    noFooter: PropTypes.bool,
};

const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(Layout);

export { ConnectedLayout as Layout };
