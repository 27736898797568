import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FlexDiv from '../../components1/FlexDiv';
import Separator from '../../components1/Separator';
import { Bold, Text, Title } from '../../components1/Texts';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import Subtitle from '../../components1/Subtitle2';

import MiniCabadiciSelector from '../grocery/MiniCabadiciSelector';
import GrocerySelector from '../grocery/GrocerySelector';

const mapStateToProps = ({isLogged}) => ({isLogged});

const FirstOrderRecipesChoice2 = ({ goToNextSlide, goToPrevSlide, scrollTo, isLogged, noButtons }) => (
    <>
        {
            noButtons ? (
                <FlexDiv style={{paddingTop: 0, paddingBottom: 0}}>
                    <Separator
                        noMargin
                        style={{flex: 1, alignItems: 'center',}}
                        strokeColor="jauneFort"
                        strokeWidth="1.5em"
                    />
                    <Title
                        style={{flex: 1, textAlign: 'center',}}
                        css={`
                            @media only screen and (max-width:599px) {
                                padding: 2px 30px !important;
                            }
                        `}
                    >
                        {'L\'épicerie'}
                    </Title>
                    <Separator
                        noMargin
                        style={{flex: 1, alignItems: 'center', zIndex: -1}}
                        strokeColor="jauneFort"
                        strokeWidth="1.5em"
                    />
                </FlexDiv>
            ) : (
                <Text
                    style={{
                        paddingBottom: 10,
                        fontSize: '2.5rem',
                        fontWeight: 600,
                    }}
                    css={`
                        font-family: ${props => props.theme.font.title};
                        @media only screen and (max-width: 599px) {
                            font-size: 1.5rem !important;
                        }
                    `}
                >Notre petite épicerie</Text>
            )
        }
        <FlexDiv style={{paddingTop: 5, paddingBottom: 30}}>
            {
                !isLogged ? (
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            padding: 0,
                            margin: '0 auto',
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.125rem !important;
                            }
                            font-family: ${props => props.theme.font.title};
                        `}
                    >
                        pour compléter votre repas
                    </Text>
                ) : (
                    <Text
                        style={{
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: 600,
                            paddingTop: 10,
                            paddingBottom: 10,
                            margin: '0 auto',
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1rem !important;
                                padding-bottom: 0 !important;
                            }
                            font-family: ${props => props.theme.font.title};
                        `}
                    >3 – Je choisis mes compléments éventuels :</Text>
                )
            }
        </FlexDiv>
        <Subtitle
            alignement="center"
            position="center"
            color="jauneFaible"
            width="50%"
            top={-20}
        >
            <Text
                special
                style={{
                    fontSize: 50,
                    fontWeight: 300,
                }}
            >Mini Recettes</Text>
        </Subtitle>
        <FlexDiv style={{paddingTop: 0, paddingBottom: 20}}>
            <Text
                style={{
                    flex: 1,
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    padding: 0
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1rem !important;
                    }
                `}
            >
                ingrédients et recettes
            </Text>
        </FlexDiv>

        <MiniCabadiciSelector isSelectable />

        <Subtitle
            alignement="center"
            position="center"
            color="jauneFaible"
            width="50%"
            top={-20}
        >
            <Text
                special
                style={{
                    fontSize: 50,
                    fontWeight: 300,
                }}
            >Prêt à manger</Text>
        </Subtitle>
        <FlexDiv style={{paddingTop: 0, paddingBottom: 20}}>
            <Text
                style={{
                    flex: 1,
                    fontSize: '1.125rem',
                    fontWeight: 600,
                    textAlign: 'center',
                    padding: 0
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1rem !important;
                    }
                `}
            >
                produits et assortiments &nbsp;&nbsp;&nbsp;&nbsp;de produits&nbsp;&nbsp;
            </Text>
        </FlexDiv>

        <GrocerySelector isSelectable/>

        {
            /*
            <Separator strokeColor="rougeFaible"/>
            <Text>LES CABADICI PERSONNALISES – ingrédients et recettes juste pour vous</Text>
            <Text>
                {'Demandez nous ce qui vous ferait plaisir, on s\'occupe des courses et des recettes !'}
                <br/>
                Quelques exemples de CABADICI sur devis :
            </Text>
            <Text>
                <Bold>{'Un goûter d\'anniversaire pour 10 enfants ?'}</Bold>
                <br/>
                Tout pour préparer les gourmandises et les boissons.
            </Text>
            <Text>
                <Bold>Un repas en famille pour 8 personnes, un jour de fête ?</Bold>
                <br/>
                Entrée, plat et dessert, vous aurez tout pour cuisiner sans y penser, selon vos goûts et votre budget.
            </Text>
            <Text>
                <Bold>Un apéritif dînatoire entre amis ?</Bold>
                <br/>
                De quoi préparer des bouchées salées et sucrées, selon votre temps et votre budget.
            </Text>
            <Text>
                <Bold>{'Un plat fétiche dont vous connaissez déjà la recette mais pour laquelle vous n\'avez pas le temps de chercher les bons produits ?'}</Bold>
                <br/>
                Nous nous occupons de tout !
            </Text>
            <FlexDiv style={{ justifyContent: 'center' }}>
                <CabadiciButton
                    color="vertFort"
                    onClick={()=>{alert('demander un devis')}}
                >
                    demander un devis
                </CabadiciButton>
            </FlexDiv>
            */
        }
        {
            !noButtons && (
                <>
                    <Separator strokeColor="vertFaible"/>
                    <FlexDiv style={{
                        justifyContent: 'space-evenly',
                        alignItems: 'flex-start'
                    }}>
                        <FlexDiv
                            style={{justifyContent: 'center', flex: 1}}
                        >
                            <BackButton
                                size="1.115rem"
                                color="jauneFort"
                                onClick={() => {
                                    goToPrevSlide();
                                    scrollTo();
                                }}

                            />
                        </FlexDiv>
                        <FlexDiv
                            style={{justifyContent: 'center', flex: 3}}
                        >
                            <CabadiciButton
                                color="rougeFort"
                                onClick={() => {
                                    goToNextSlide();
                                    scrollTo();
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.625rem !important;
                                    }
                                `}
                            >
                                suivant
                            </CabadiciButton>
                        </FlexDiv>
                        <FlexDiv
                            style={{justifyContent: 'center', flex: 1}}
                        />
                    </FlexDiv>
                </>
            )
        }
    </>
);

FirstOrderRecipesChoice2.propTypes = {
    goToNextSlide: PropTypes.func.isRequired,
    goToPrevSlide: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(FirstOrderRecipesChoice2);
