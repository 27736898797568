import React, { useState, useEffect, useCallback } from 'react';
import { withTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FlexDiv from './FlexDiv';

const SelectRecipeButton = withTheme(React.memo(({ checked, className, innerHandleCheck, theme }) => {
    // const iconDisplay = 'plus' //checked ? 'check' : 'plus';
    const [iconDisplay, setIcon] = useState('plus');
    const onRest = useCallback(() => {
        console.log('Checked:', checked);
        if (checked) {
            setIcon('check')
            return alert(`Fini, je fais passer l'icone à : 'check'`)
        }
        alert(`Fini, l'icone est bonne`)
    }, [checked])

    const isOpenRef = React.useRef(false);



    const [props, set, stop] = useSpring(() => ({
        from: { x: 0 },
        x: checked ? 1 : 0,
        config: { duration: 500 },
        onStart: () => {
            // alert(`Start, je fais passer l'icone à : 'plus'`)
            setIcon('plus')
        },
        onRest: () => {
            // console.log('Checked:', isOpenRef.current);
            if (isOpenRef.current) {
                setIcon('check')
                // return alert(`Fini, je fais passer l'icone à : 'check'`)
            }
            // alert(`Fini, l'icone est bonne`)
        }
    }));

    // Update spring with new props
    // set({opacity: toggle ? 1 : 0})

    // a => b => a
    /*
    const props = useSpring({
        from: { x: 0 },
        x: checked ? 1 : 0,
        config: { duration: 500 },
        onStart: () => {
            alert(`Start, je fais passer l'icone à : 'plus'`)
        },
        onRest: () => {
            if (checked) {
                return alert(`Fini, je fais passer l'icone à : 'check'`)
            }
            alert(`Fini, l'icone est bonne`)

        }
    });
    */

    useEffect(() => {
        isOpenRef.current = checked;
        // console.log('Checked value from useEffect:', checked);
        set({x: checked ? 1 : 0})
    }, [checked]);
    // console.log(iconDisplay);

    return (
        <FlexDiv
            className={className}
            role="button"
            onClick={() => {
                innerHandleCheck(!checked);
            }}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
        >
            <animated.div
                style={{
                    opacity: props.x.interpolate({
                        range: [0, 0.5, 1],
                        output: [1, 0.3, 1]
                    }),
                    transform: props.x
                        .interpolate({
                            range: [0, 1],
                            output: [0, 360]
                        })
                        .interpolate(x => `rotate(${x}deg)`),
                }}
            >
                <span className="fa-layers fa-fw ripple" style={{
                    height: 60,
                    width: 60,
                    borderRadius: 90,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <FontAwesomeIcon icon="circle" color="#fff" size='3x'/>
                    <FontAwesomeIcon icon={['far', 'circle']} color={theme.color.vertFort} size='3x'/>
                    {
                        iconDisplay === 'plus' ?
                            <FontAwesomeIcon icon="plus" size='3x' transform="shrink-8" color={theme.color.vertFort} /> :
                            <span className="fa-layers fa-fw ripple" style={{
                                height: 50,
                                width: 50,
                                borderRadius: 90,
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                                <FontAwesomeIcon icon="circle" color={theme.color.vertFort} size='3x'/>
                                <FontAwesomeIcon icon="check" size='2x' transform="shrink-4" color="#fff" />
                            </span>
                    }
                </span>
            </animated.div>
        </FlexDiv>
    );
}));


const SelectRecipeButton2 = withTheme(React.memo(({className, theme, checked, innerHandleCheck}) => {
    const [state, toggle] = useState(checked);
    const [iconDisplay, setIcon] = useState(checked ? 'check' : 'plus');
    const [oneshot, trigger] = useState(false);
    console.log(checked);
    console.log(iconDisplay);

    // derivedStateFromProps
    useEffect(() => {
        alert(oneshot)
        if (!oneshot) {
            setIcon(checked ? 'check' : 'plus');
            trigger(true)
        }
   }, [checked])

    /* one way : a => b // a => b
    const [props, set] = useSpring(() => ({
        from: { x: 0 },
        x: 1, // state ? 0 : 1,
        config: { duration: 500 },
        reset: state,
        onRest: () => {
            if (props.x.value === 1) {
                alert(iconDisplay)
                setIcon(iconDisplay => iconDisplay === 'plus' ? 'check' : 'plus')
            }
        }
    }))
    */

    // a => b => a
    const props = useSpring({
        from: { x: 0 },
        x: checked ? 1 : 0,
        config: { duration: 500 },
        onRest: () => {
            if (props.x.value === 1) {
                setIcon('check');
            }
        }
    });

    return (
        <FlexDiv
            className={className}
            role="button"
            onClick={() => {
                if (iconDisplay === 'check') {
                    setIcon('plus');
                }
                innerHandleCheck(!checked);
                start(!checked);
                // toggle(!state);
            }}
            style={{
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            }}
        >
            <animated.div
                style={{
                    opacity: props.x.interpolate({
                        range: [0, 0.5, 1],
                        output: [1, 0.3, 1]
                    }),
                    transform: props.x
                        .interpolate({
                            range: [0, 1],
                            output: [0, 360]
                        })
                        .interpolate(x => `rotate(${x}deg)`),
                }}>
                <span className="fa-layers fa-fw ripple" style={{
                    height: 60,
                    width: 60,
                    borderRadius: 90,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <FontAwesomeIcon icon="circle" color="#fff" size='3x'/>
                    <FontAwesomeIcon icon={['far', 'circle']} color={theme.color.vertFort} size='3x'/>
                    {
                        iconDisplay === 'plus' ?
                            <FontAwesomeIcon icon="plus" size='3x' transform="shrink-8" color={theme.color.vertFort} /> :
                            <span className="fa-layers fa-fw ripple" style={{
                                height: 50,
                                width: 50,
                                borderRadius: 90,
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}>
                                <FontAwesomeIcon icon="circle" color={theme.color.vertFort} size='3x'/>
                                <FontAwesomeIcon icon="check" size='2x' transform="shrink-4" color="#fff" />
                            </span>
                    }
                </span>
            </animated.div>
        </FlexDiv>
    );
}));

export default SelectRecipeButton;
