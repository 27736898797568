import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import StripePaymentForm from './StripePayment';
import {
    SansAbonnementInfo,
    AbonnementInfo,
    ComplementsInfo,
    LivraisonInfo,
    ReductionCode,
    Sum,
    Total,
    CgvValidator,
} from './infos';
import { deliveryData, recipesPrices, subscriptionPrice, deliveryHoursData } from '../../Constants';
import { createOrder, updateOrder, createSubscription, updateUserProfil, forceConnexion, updateAuthUserData } from '../../actions';
import { getNextOrderableWeekToken } from '../../services/dates';
import Separator from '../../components1/Separator';
import { addCustomerToAuthClaimsFromApi, addChargeToCustomerFromApi, getUser } from '../../services';

export const PaymentButton = ({status, innerRef, onClick}) => {
    return (
        <CabadiciButton
            ref={innerRef}
            onClick={onClick}
            color="vertFort"
            style={{
                alignSelf: 'center'
            }}
            type="submit"
            disabled={status === 'submitting'}>
            {status === 'submitting' ? 'En cours' : 'Payer'}
        </CabadiciButton>
    );
};

const mapStateToProps = ({
    recipesNumber,
    recipesSelection,
    subscriptionChoice,
    deliveryChoice,
    itemsSelection,
    partnerDeliveryAddress,
    privateDeliveryAddress,
    deliveryHoursChoice,
    isLogged,
    user,
    authUserData,
}) => {
    return { user, recipesNumber, recipesSelection, subscriptionChoice, deliveryChoice, itemsSelection, partnerDeliveryAddress, privateDeliveryAddress, deliveryHoursChoice, isLogged, authUserData};
};

const mapDispatchToProps = dispatch => {
    return {
        createOrder: values => dispatch(createOrder(values)),
        updateOrder: values => dispatch(updateOrder(values)),
        createSubscription: values => dispatch(createSubscription(values)),
        updateUserProfil: values => dispatch(updateUserProfil(values)),
        forceConnexion: () => dispatch(forceConnexion()),
        updateAuthUserData: (user) => dispatch(updateAuthUserData(user)),
    };
};

class PaymentPage extends Component {
    static propTypes = {
        recipesNumber: PropTypes.number.isRequired,
        subscriptionChoice: PropTypes.number.isRequired,
        deliveryChoice: PropTypes.number.isRequired,
        itemsSelection: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            price: PropTypes.number.isRequired,
        })).isRequired,
        innerPrevTab: PropTypes.func.isRequired,
        closeModal: PropTypes.func.isRequired,
        orderSuccessModalRef: PropTypes.shape().isRequired,
    };

    state = {
        checked: false,
        error: false,
        status: 'default',
        coupon: null,
    };

    input = React.createRef();

    input2 = React.createRef();

    formSubmitRef = React.createRef()

    handleCheckboxChange = event =>
        this.setState({ checked: event.target.checked });

    handlePaymentStatusChange = status =>
        this.setState({ status });

    parseNumber = (value) => {
        if (Number.isNaN(Number.parseFloat(value))) {
            return 0;
        }
        return parseFloat(value);
    };

    getSum = () => {
        const { recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection } = this.props;
        const sum = recipesPrices[recipesNumber]
            + subscriptionPrice[subscriptionChoice]
            + this.parseNumber(deliveryData[deliveryChoice].price)
            + Math.floor(itemsSelection.reduce((acc, val) => acc + (val.unitPrice * val.itemNumber), 0)*100)/100;
        return sum;
    }

    getTotalSum = () => {
        const sum = this.getSum();
        const { coupon } = this.state;
        console.log(coupon);
        if (!coupon) {
            return sum;
        }
        if (coupon.type === 'percent') {
            return sum * coupon.value;
        }
        if (coupon.type === 'raw') {
            return sum - coupon.value;
        }
        throw new Error('coupon is invalid');
    }

    // new order from subscribers
    submitNewOrder = () => {
        const { closeModal, orderSuccessModalRef, createOrder, createSubscription, recipesSelection, partnerDeliveryAddress, privateDeliveryAddress, updateUserProfil, isLogged, forceConnexion, updateAuthUserData } = this.props;
        const { recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection, deliveryHoursChoice } = this.props;
        const { coupon } = this.state;
        const totalOrder = this.getTotalSum();

        // process to order
        const order = {
            date: new Date(),
            weekId: getNextOrderableWeekToken(),
            recipes: {
                recipesNumber,
                price: recipesPrices[recipesNumber],
                recipesSelection: recipesSelection.map(recipe => ({
                    id: recipe.id,
                    name: recipe.name,
                })),
            },
            grocery: {
                itemsSelection
            },
            delivery: {
                deliveryChoice,
                deliveryChoiceData: deliveryData[deliveryChoice],
                partnerDeliveryAddress,
                privateDeliveryAddress,
                deliveryHoursChoice,
                price: this.parseNumber(deliveryData[deliveryChoice].price),
                deliveryHoursChoiceData: deliveryHoursData[deliveryHoursChoice]
            },
            subscription : {
                isSubscription: (subscriptionChoice !== 1),
                subscriptionChoice,
                price: subscriptionPrice[subscriptionChoice],
            },
            price: {
                totalOrder,
                hasDiscount: !!coupon,
                discountCoupon: coupon
            },
            isFirstOrder: false,
            isUpfrontPayment: (subscriptionChoice === 1),
            paymentMethod: null,
            state: 'en attente de paiement',
            // fromUser: ''
        };

        console.log(order);

        // register order to db:
        createOrder(order)
            .then(() => {
                // success
                // if user take subscription, update it
                // TODO : create SUBSCRIPTION db entity ?
                if (subscriptionChoice !== 1) {
                    // return createSubscription();

                }
                return Promise.resolve(null);
            })
            .then(subscriptionRef => {
                /*
                updateUserProfil({
                    isSubscriber: !!subscriptionRef,
                    subscriptionRef,
                });
                */

                // if previously forced deconnected`
                if (!isLogged) {
                    forceConnexion();
                }

                orderSuccessModalRef.current.openModal();
                closeModal();
            })
            .catch(e => {
                console.log(e)
            })
    }

    updateCurrentOrder = (id) => {
        const { closeModal, orderSuccessModalRef, updateOrder, createSubscription, recipesSelection, partnerDeliveryAddress, privateDeliveryAddress, updateUserProfil, isLogged, forceConnexion, updateAuthUserData } = this.props;
        const { recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection, deliveryHoursChoice } = this.props;
        const { coupon } = this.state;
        const totalOrder = this.getTotalSum();

        // process to order
        const order = {
            id: id,
            dateUpdate: new Date(),
            weekId: getNextOrderableWeekToken(),
            recipes: {
                recipesNumber,
                price: recipesPrices[recipesNumber],
                recipesSelection: recipesSelection.map(recipe => ({
                    id: recipe.id,
                    name: recipe.name,
                })),
            },
            grocery: {
                itemsSelection
            },
            delivery: {
                deliveryChoice,
                deliveryChoiceData: deliveryData[deliveryChoice],
                partnerDeliveryAddress,
                privateDeliveryAddress,
                deliveryHoursChoice,
                price: this.parseNumber(deliveryData[deliveryChoice].price),
                deliveryHoursChoiceData: deliveryHoursData[deliveryHoursChoice]
            },
            subscription : {
                isSubscription: (subscriptionChoice !== 1),
                subscriptionChoice,
                price: subscriptionPrice[subscriptionChoice],
            },
            price: {
                totalOrder,
                hasDiscount: !!coupon,
                discountCoupon: coupon
            },
            // isFirstOrder: false,
            // paymentMethod: null,
            // state: 'en attente de paiement',
            // fromUser: ''
        };

        // console.log(order);

        // update order to db:
        updateOrder(order)
            .then(() => {
                // success
                // if user take subscription, update it
                // TODO : create SUBSCRIPTION db entity ?
                if (subscriptionChoice !== 1) {
                    // return createSubscription();

                }
                return Promise.resolve(null);
            })
            .then(subscriptionRef => {
                /*
                updateUserProfil({
                    isSubscriber: !!subscriptionRef,
                    subscriptionRef,
                });
                */

                // if previously forced deconnected`
                if (!isLogged) {
                    forceConnexion();
                }

                orderSuccessModalRef.current.openModal();
                closeModal();
            })
            .catch(e => {
                console.log(e)
            })
    };

    onSubmit = ({customerId, card}) => {
        const { closeModal, orderSuccessModalRef, createOrder, createSubscription, recipesSelection, partnerDeliveryAddress, privateDeliveryAddress, updateUserProfil, isLogged, forceConnexion, updateAuthUserData } = this.props;
        const { recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection, deliveryHoursChoice } = this.props;
        const { coupon } = this.state;
        const totalOrder = this.getTotalSum();

        this.setState({error: false});

        // process to order
        const order = {
            date: new Date(),
            weekId: getNextOrderableWeekToken(),
            recipes: {
                recipesNumber,
                price: recipesPrices[recipesNumber],
                recipesSelection: recipesSelection.map(recipe => ({
                    id: recipe.id,
                    name: recipe.name,
                })),
            },
            grocery: {
                itemsSelection
            },
            delivery: {
                deliveryChoice,
                deliveryChoiceData: deliveryData[deliveryChoice],
                partnerDeliveryAddress,
                privateDeliveryAddress,
                deliveryHoursChoice,
                price: this.parseNumber(deliveryData[deliveryChoice].price),
                deliveryHoursChoiceData: deliveryHoursData[deliveryHoursChoice]
            },
            subscription : {
                isSubscription: (subscriptionChoice !== 1),
                subscriptionChoice,
                price: subscriptionPrice[subscriptionChoice],
            },
            price: {
                totalOrder,
                hasDiscount: !!coupon,
                discountCoupon: coupon
            },
            isFirstOrder: true,
            isUpfrontPayment: true,
            paymentMethod: card,
            state: 'en attente de paiement',
            // fromUser: ''
        };

        // console.log(order);

        // register order to db:
        createOrder(order)
            .then((resp) => {
                // save customer_id to our db + card number ?

                // process first charge
                const charge = {
                    amount: Math.floor(totalOrder * 100),
                    customer_id: customerId,
                    description:  `Commande Cab - ${resp.id}`,
                    order_id: resp.id,
                    metadata: {
                        order_id: resp.id
                    }
                };

                // do payment
                // return addChargeToCustomerFromApi(charge).then(() => resp);
                return addChargeToCustomerFromApi(charge);
            })
            /* coté back
            .then((resp) => {
                // payment is valid, update order
                updateOrder({
                    id: resp.id,
                    state: 'commande payée'
                });
            })
            */
            .then(() => {
                // then add isCustomer to claimsProperties
                const user = getUser();
                // add to api
                return addCustomerToAuthClaimsFromApi({
                    email: user.email
                })
                    // add to reducer
                    .then(() => {
                        updateAuthUserData({...user, isCustomer: true})
                    })
            })
            .then(() => {
                // success
                // if user take subscription, update it
                // TODO : create SUBSCRIPTION db entity ?
                if (subscriptionChoice !== 1) {
                    return createSubscription();

                }
                return Promise.resolve(null);
            })
            .then(subscriptionRef => {
                updateUserProfil({
                    isSubscriber: !!subscriptionRef,
                    subscriptionRef,
                    paymentMethod: [{...card}],
                    defaultPaymentMethodId: card.id,
                    customerId,
                });

                // if previously forced deconnected`
                if (!isLogged) {
                    forceConnexion();
                }

                orderSuccessModalRef.current.openModal();
                closeModal();
            })
            .catch(e => {
                console.log(e)
            })
    };

    render() {
        const { innerPrevTab, recipesNumber, subscriptionChoice, deliveryChoice,
            itemsSelection, recipesSelection, fromUserAccount,
            partnerDeliveryAddress, privateDeliveryAddress, deliveryHoursChoice, user,
            authUserData,
        } = this.props;
        const { checked, error, status, coupon, couponMessage } = this.state;
        const sumOrder = this.getSum();
        const totalOrder = this.getTotalSum();
        console.log(recipesSelection);
        return (
            <>
                <FlexDiv
                    css={`
                        @media only screen and (max-width: 599px) {
                            flex-direction: column;
                        }
                    `}
                >
                    <FlexDiv
                        style={{
                            flexDirection: 'column',
                            margin: 10,
                            padding: 10,
                        }}
                        css={`
                            border: solid 2px ${props => props.theme.color.rougeFaible};
                        `}
                    >
                        <Title
                            style={{textTransform: 'none', fontSize: '1.5rem', textAlign: 'center'}}
                        >
                            Récapitulatif de votre commande
                        </Title>
                        {
                            subscriptionChoice === 0 ?
                                <AbonnementInfo recipesNumber={recipesNumber} recipesSelection={recipesSelection} /> :
                                <SansAbonnementInfo recipesNumber={recipesNumber} recipesSelection={recipesSelection} />
                        }
                        <ComplementsInfo itemsSelection={itemsSelection}/>
                        <LivraisonInfo deliveryChoice={deliveryChoice} partnerDeliveryAddress={partnerDeliveryAddress} privateDeliveryAddress={privateDeliveryAddress} deliveryHoursChoice={deliveryHoursChoice} />
                        <Sum orderTotalSum={sumOrder}/>
                        <ReductionCode
                            handleCoupon={coupon => {console.log(coupon);this.setState({coupon, couponMessage: null})}}
                            handleCouponError={couponMessage => {this.setState({couponMessage, coupon: null})}}
                        />
                        {
                            coupon && ['percent', 'raw'].includes(coupon.type) && (
                                <Text
                                    style={{ textAlign: 'center' }}
                                    css={`
                                        color: ${props => props.theme.color.vertFort};
                                    `}
                                >
                                    Votre coupon a bien été pris en compte
                                </Text>
                            )
                        }
                        {
                            couponMessage && (
                                <Text
                                    style={{ textAlign: 'center' }}
                                    css={`
                                        color: ${props => props.theme.color.rougeFort};
                                    `}
                                >
                                    {"Votre code n'est pas valide, contactez nous"}
                                </Text>
                            )
                        }
                        <Total orderTotalSum={totalOrder}/>
                    </FlexDiv>
                    {
                        (!fromUserAccount || !authUserData.isCustomer) && (
                            <FlexDiv
                                style={{
                                    flexDirection: 'column',
                                    // justifyContent: 'center',
                                    margin: 10,
                                    padding: 10,
                                }}
                            >
                                <Title
                                    style={{textTransform: 'none', fontSize: '1.5rem', textAlign: 'center'}}
                                >
                                    Vos informations de paiement
                                </Title>
                                <StripePaymentForm
                                    ref={this.formSubmitRef}
                                    handlePaymentStatusChange={this.handlePaymentStatusChange}
                                    paymentHandler={this.onSubmit}
                                    user={user}
                                />
                                <CgvValidator
                                    innerRef={this.input}
                                    innerRef2={this.input2}
                                    innerOnChange={this.handleCheckboxChange}
                                    innerChecked={checked}
                                />
                                <PaymentButton
                                    status={status}
                                    onClick={() => {
                                        if(!checked) {
                                            this.setState({error: true})
                                            // console.log(this.input2.current.focus);
                                            this.input.current.focus();
                                            return;
                                        }
                                        this.formSubmitRef.current.click();
                                    }}
                                />
                                <Text style={{color: 'red', fontSize: '0.875rem', padding: '5px 0px'}}>
                                    {'Le règlement de votre CABADICI sera débité chaque dimanche soir. Pensez à modifier vos préférences avant minuit.'}
                                </Text>
                                <Text style={{fontSize: '0.875rem', padding: '5px 0px'}}>
                                    {'PS : Lors de votre prochaine livraison, n\'hésitez pas à nous ramener vos sacs CABADICI, vos pochettes isotherme et/ou vos paquettes de froid.'}
                                    <br />
                                    {'Ce petit geste éco-citoyen sera récompensé par un cadeau surprise! (hors livraisons CHRONOFRESH)'}
                                </Text>
                                {
                                    error && (
                                        <Text style={{color: 'red', fontSize: '0.875rem', fontWeight: 600}}>
                                            Merci de bien vouloir accepter nos Conditions Générales de Vente
                                        </Text>
                                    )
                                }
                            </FlexDiv>
                        )
                    }
                </FlexDiv>
                {
                    !fromUserAccount && (
                        <>
                            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
                            <FlexDiv style={{
                                justifyContent: 'space-evenly',
                                alignItems: 'flex-start'
                            }}>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 1}}
                                >
                                    <BackButton
                                        size="1.115rem"
                                        color="jauneFort"
                                        onClick={innerPrevTab}

                                    />
                                </FlexDiv>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 3}}
                                >
                                    {
                                        /*
                                        <CabadiciButton
                                            color="rougeFort"
                                            onClick={this.onSubmit}
                                            css={`
                                                @media only screen and (max-width: 599px) {
                                                    font-size: 1.625rem !important;
                                                }
                                            `}
                                        >
                                            valider
                                        </CabadiciButton>
                                        */
                                    }
                                </FlexDiv>
                                <FlexDiv
                                    style={{justifyContent: 'center', flex: 1}}
                                />
                            </FlexDiv>
                        </>
                    )
                }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentPage);
