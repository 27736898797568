import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Text } from './Texts';

const CloseIcon = ({ onClick, color }) => (
    <FontAwesomeIcon icon={['far', 'window-close']} size='2x' onClick={onClick} color={color}/>
);

const CloseIconStyled = styled(CloseIcon)`
    :hover {
        opacity: 0.5;
    }
    cursor: pointer;
`;

const InfoMessage = ({ onClick, theme, message }) => (
    <div
        style={{
            position: 'sticky',
            zIndex: 50,
            top: 0,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: message.backgroundColor,
            justifyContent: 'center',
            // alignItems: 'center',
            minHeight: 75,
        }}
    >
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {/* add this for fucking mobile alignment */}
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                    visibility: 'hidden',
                }}
            >
                <CloseIconStyled
                    color={message.closeIconColor}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: 20,
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        flex: 4 !important;
                    }
                `}
            >
                <Text
                    style={{
                        color: message.messageColor || '#fff',
                        textAlign: 'center',
                        fontSize: 24,
                        padding: '0 15px',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 16px !important;
                        }
                    `}
                >
                    { message.messageTitle }
                </Text>
                <Text
                    style={{
                        color: message.messageColor || '#fff',
                        textAlign: 'center',
                        fontSize: 16,
                        padding: '0 15px',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 16px !important;
                        }
                    `}
                >
                    { message.messageBody }
                </Text>
            </div>
            <div
                style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'center',
                }}
            >
                <CloseIconStyled
                    color={message.messageColor || '#fff'}
                    onClick={onClick}
                />
            </div>

        </div>
    </div>
);

export default withTheme(InfoMessage);
