import React, { useState, useEffect, useMemo } from 'react';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Sticky from 'react-sticky-el';

import FlexDiv from '../components1/FlexDiv';
import { Text } from '../components1/Texts';
import { deliveryData, recipesPrices, subscriptionPrice } from '../Constants';

const mapStateToProps = ({
    recipesNumber, subscriptionChoice,
    deliveryChoice, itemsSelection
}) => ({
    recipesNumber, subscriptionChoice,
    deliveryChoice, itemsSelection
});

const parseNumber = (value) => {
    if (Number.isNaN(Number.parseFloat(value))) {
        return 0;
    }
    return parseFloat(value);
};

const scrollingElement = () => {
    return document.scrollingElement || document.documentElement;
}

const BasketContent = withTheme(({
    theme, recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection, closeModal
}) => {
    // 2 * 1.2rem
    const minOffset = 38;
    const [translationY, setTranslationY] = useState(0);

    const getSum = () => {
        const sum = recipesPrices[recipesNumber]
            + subscriptionPrice[subscriptionChoice]
            // set 0 if deliveryChoice not already selected
            + (deliveryChoice < 3 ? parseNumber(deliveryData[deliveryChoice].price) : 0)
            + itemsSelection.reduce((acc, val) => acc + (val.unitPrice * val.itemNumber), 0);
        return sum;
    };

    const currentSum = useMemo(() => getSum(), [recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection]);

    const handleScroll = () => {
        const currentScrollPos = document.getElementById('FirstOrderScroller').scrollTop;
        if (currentScrollPos === 0) {
            return setTranslationY(0);
        }
        if (currentScrollPos > minOffset) {
            setTranslationY(currentScrollPos - minOffset);
        }
    };

    useEffect(() => {
        const mainElement = document.getElementById('FirstOrderScroller');
        mainElement.addEventListener('scroll', handleScroll);
        return () => {
            mainElement.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <FlexDiv
            style={{
                justifyContent: 'flex-start',
                position: 'relative',
                zIndex: 20,
                left: 0,
                right: 0,
                top: 0,
                transform: `translateY(${translationY}px)`
            }}
        >
            <FlexDiv style={{
                zIndex: 20,
                backgroundColor: '#FFF',
                justifyContent: 'space-between',
            }}
            >
                <FlexDiv style={{
                    flex: 0,
                    padding: '5px 10px',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}>
                    <FontAwesomeIcon
                        icon="shopping-basket"
                        size="lg"
                        color={theme.color.rougeFaible}
                        onClick={() => {}}
                    />
                    <Text style={{
                        whiteSpace: 'nowrap',
                        margin: '0 0 -3px 5px',
                        // color: '#fff',
                        color: theme.color.rougeFaible,
                    }}>
                        {`total: ${currentSum} €`}
                    </Text>
                </FlexDiv>
                <FontAwesomeIcon
                    icon="times"
                    size="lg"
                    color="#000"
                    onClick={closeModal}
                    style={{padding: '5px 10px', cursor: 'pointer'}}
                    css={`:hover{opacity: 0.7;}`}
                />
            </FlexDiv>
        </FlexDiv>
    );
});

BasketContent.propTypes = {
    // totalPrice: ,
};

export default connect(mapStateToProps, null)(BasketContent);

export const ShortBasketContent = connect(mapStateToProps)(withTheme(({
    theme, recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection,
}) => {

    const getSum = () => {
        const sum = recipesPrices[recipesNumber]
            + subscriptionPrice[subscriptionChoice]
            // set 0 if deliveryChoice not already selected
            + (deliveryChoice < 3 ? parseNumber(deliveryData[deliveryChoice].price) : 0)
            + itemsSelection.reduce((acc, val) => acc + (val.unitPrice * val.itemNumber), 0);
        return sum;
    };

    const currentSum = useMemo(() => getSum(), [recipesNumber, subscriptionChoice, deliveryChoice, itemsSelection]);

    return (
        <Sticky
            topOffset={-50}
            stickyStyle={{transform: 'translateY(50px)'}}
            holderProps={{
                style:{
                    position: 'relative',
                    zIndex: 10
                }
            }}
            style={{
                zIndex: 10,
                display: 'flex',
                justifyContent: 'flex-start'
            }}
            css={`
                @media only screen and (max-width: 599px) {
                    transform: translateY(0px) !important;
                }
            `}
        >
            <FlexDiv style={{
                display: 'flex-inline',
                flex:0,
                padding: '5px 10px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                backgroundColor: '#FFF',
            }}>
                <FontAwesomeIcon
                    icon="shopping-basket"
                    size="lg"
                    color={theme.color.rougeFaible}
                    onClick={() => {}}
                />
                <Text style={{
                    whiteSpace: 'nowrap',
                    margin: '0 0 -3px 5px',
                    // color: '#fff',
                    color: theme.color.rougeFaible,
                }}>
                    {`total: ${currentSum} €`}
                </Text>
            </FlexDiv>
        </Sticky>
    );
}));
