import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Formik, Form, Field, ErrorMessage as ErrorMessageWithoutStyle } from '../../components1/forms/formik';
import FlexDiv from '../../components1/FlexDiv';
import { CabadiciButton } from '../../components1/buttons';

const ErrorMessage = styled(ErrorMessageWithoutStyle)`
    @media only screen and (max-width: 599px) {
        font-size: 0.75rem;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, minmax(15px, auto));
    grid-row-gap: 5px;
    grid-template-areas: 'a'
                         'b'
                         'c'
                         'c';
`;

const PasswordForgetSchema = Yup.object().shape({
    email: Yup.string().email('L\'addresse email n\'est pas valide')
        .required('champ requis'),
});

const PasswordForget = ({ onSubmit }) => (
    <Formik
        initialValues={{
            email: ''
        }}
        validationSchema={PasswordForgetSchema}
        onSubmit={onSubmit}
    >
        <Form>
            <Grid>
                <Field name="email" placeholder="adresse email" style={{flex: 1, margin: '0 10px', gridArea: 'a'}}/>
                <ErrorMessage name="email" component="div" style={{margin: '0 10px', fontSize: '0.75rem', gridArea: 'b'}}/>
                <CabadiciButton
                    type="submit"
                    color="rougeFort"
                    style={{
                        // margin: '15px 0',
                        justifySelf: 'center',
                        gridArea: 'c'
                    }}
                >
                    Envoyer un nouveau mot de passe
                </CabadiciButton>
            </Grid>
        </Form>
    </Formik>
);

PasswordForget.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default PasswordForget;
