import styled from 'styled-components';

const GroceryGrid = styled.div`
    display: grid;
    grid-gap: 5px;
    padding: 0 5px;
    padding-bottom: 20px;

    /* desktop */
    @media only screen and (min-width : 1024px) {
        grid-template-columns: ${props => props.mini ?
        'repeat(30, minmax(11px, 1fr))' :
        'repeat(5, minmax(135px, 1fr))'};
        justify-content: center;

        .col-mini-recipe:nth-child(1) {
            grid-column-start: 4 !important;
            grid-column-end: 10 !important;
        }
        .col-mini-recipe:nth-child(-1n + 4) {
            grid-column: span 6;
        }
        .col-mini-recipe:nth-last-child(3) {
            grid-row-start: 2;
            grid-column: 7 / span 6;
        }
        .col-mini-recipe:nth-last-child(2) {
            grid-row-start: 2;
            grid-column: 13 / span 6;
        }
        .col-mini-recipe:nth-last-child(1) {
            grid-row-start: 2;
            grid-column: 19 / span 6;
        }
    }

    /* tablet */
    @media only screen and (min-width : 700px) and (max-width : 1024px) {
        /*grid-template-columns: repeat(3, minmax(135px, 1fr));*/
        grid-template-columns: repeat(12, minmax(11px, 1fr));

        .col-mini-recipe:nth-child(-1n + 6) {
            grid-column: span 4;
        }
        .col-mini-recipe:nth-last-child(1) {
            grid-row-start: 3;
            grid-column: 5 / span 4;
        }
        .col-grocery {
            grid-column: span 4;
        }
        .col-grocery:nth-last-child(1) {
            grid-row-start: 4;
            grid-column: 5 / span 4;
        }
    }

    @media only screen and (max-width: 700px) {
        /* grid-template-columns: 0.5fr; */
        /* grid-template-columns: repeat(2, minmax(135px, 1fr));*/
        grid-template-columns: repeat(8, minmax(17px, 1fr));
        grid-row-gap: 10px;

        .col-mini-recipe:nth-child(-1n + 6) {
            grid-column: span 4;
        }
        .col-mini-recipe:nth-last-child(1) {
            grid-row-start: 4;
            grid-column: 3 / span 4;
        }
        .col-grocery {
            grid-column: span 4;
        }
    }
`;

export default GroceryGrid;
