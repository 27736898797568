import styled from 'styled-components';
import withTheme from '../theme';

export const Row = withTheme(styled.div`
    display: flex;
    /*direction: row;*/
    flex-direction: ${props => props.column ? 'column' : 'row'};
    /*width: 100vw;*/
    align-items: center;
    justify-content: space-around;
    font-family: ${props => props.special ? props.theme.font.special : props.theme.font.main};
`);

export const BackgroundCell = styled.div`
    background-image: url('${props => props.img}');
    background-repeat: no-repeat;
    background-size: contain;
    /*background-size: 100%;*/
    /*background-size: cover;*/
`;

export const Cell = withTheme(styled.div`
    display: flex;
    direction: ${props => props.row ? 'row' : 'column'};
    /*width: 100vw;*/
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 10px;
    font-family: ${props => props.special ? props.theme.font.special : props.theme.font.main};
`);

export const Section = styled.div`
    :beforez {
        background: '#fff';
        height: 50px;
        display: block;
        content: '';
    }
    margin: '0 auto',
    max-width: '70vw',
    //padding: '0px 1.0875rem 1.45rem',
    padding-top: 0,
`;

export const Title = withTheme(styled.div`
    text-transform: uppercase;
    font-size: 30px;
    padding: 10px;
    font-weight: 600;
    font-family: ${props => props.theme.font.title};
`);

export const Footer = withTheme(styled.footer`
    min-height: 25vh;
    background-color: ${props => props.theme.color.rougeFort};
    display: flex;
    align-items: center;
    justify-content: center;
`);

export const CellTitle = styled.div`
    text-transform: uppercase;
    font-weight: 600;
`;

export const CellLink = styled.div`

`;