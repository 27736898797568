import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Modalize from '../../components/Modalizer_';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';

const DeactivateSubscription = ({ closeModal, data }) => {
    const callbackAction = useCallback(
        () => {
            console.log('GO');
            closeModal();
            data.callback();
        },
        [closeModal, data]
    );

    return (
        <FlexDiv style={{flexDirection: 'column', paddingTop: 20}}>
            {
                data.title && (
                    <Title style={{textAlign: 'center'}}>
                        {data.title}
                    </Title>
                )
            }
            <Text style={{textAlign: 'center'}}>
                {
                    Array.isArray(data.message) ? (
                        data.message.map(m => <Text>{m}</Text>)
                    ) : data.message
                }
            </Text>
            <FlexDiv style={{ justifyContent: 'center', paddingTop: 10 }}>
                {
                    data.callback ? (
                        <>
                            <CabadiciButton
                                color="rougeFort"
                                onClick={closeModal}
                                style={{minWidth: 100}}
                            >
                                annuler
                            </CabadiciButton>
                            <CabadiciButton
                                color="vertFort"
                                onClick={callbackAction}
                                style={{maxWidth: 100}}
                            >
                                confirmer
                            </CabadiciButton>
                        </>
                    ) : (
                        <CabadiciButton
                            color="jauneFort"
                            onClick={closeModal}
                            style={{minWidth: 100}}
                        >
                            retour
                        </CabadiciButton>
                    )
                }
            </FlexDiv>
        </FlexDiv>
    );
}

DeactivateSubscription.propTypes = {
    closeModal: PropTypes.func.isRequired,
    data: PropTypes.shape({message: PropTypes.string}),
};

export default Modalize(DeactivateSubscription);
