import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    updateUSerData, subscriptionDeactivate, subscriptionReactivate,
    updateUserProfil, updateOrderFromApi, deleteOrderById,
    subscriptionDeactivateCurrentWeekOrder, subscriptionReactivateCurrentWeekOrder,
} from '../../actions';
import { getNextOrderableWeekToken } from '../../services/dates'
import { getUserRef, getCurrentOrder } from '../../services';

import DeactivateSubscriptionModal from '../modals/DeactivateSubscriptionSuccess';
import AlertModal from '../modals/AlertModal';

const mapStateToProps = ({ user, authUserData}) => {

    const isLastSubscriptionsDeactivate = (typeof user.subscription !== 'undefined') ?
        user.subscription.suspendedWeekId.includes(getNextOrderableWeekToken()) :
        false;
    const areAllSubscriptionsDeactivate = (typeof user.subscription !== 'undefined') ?
        !user.subscription.isActive :
        false;

    return { user, authUserData, isLastSubscriptionsDeactivate, areAllSubscriptionsDeactivate };
};

const mapDispatchToProps = dispatch => {
    return {
        updateUSerData: (value) => dispatch(updateUSerData(value)),
        updateSubscriptionDeactivate: () => dispatch(subscriptionDeactivate()),
        updateSubscriptionReactivate: () => dispatch(subscriptionReactivate()),
        updateUserProfil: (value) => dispatch(updateUserProfil(value)),
        updateOrderFromApi: (value) => dispatch(updateOrderFromApi(value)),
        deleteOrderById: (value) => dispatch(deleteOrderById(value)),
        subscriptionDeactivateCurrentWeekOrder: (value) => dispatch(subscriptionDeactivateCurrentWeekOrder(value)),
        subscriptionReactivateCurrentWeekOrder: (value) => dispatch(subscriptionReactivateCurrentWeekOrder(value)),
    };
};

export const ModalsRefsContext = React.createContext(null);
export const EditableContext = React.createContext({
    currentWeekOrderId: null,
    resetOrderId: () => {},
});

export default (UserComponent) => {
    class UserDataWrapper extends Component {
        static propTypes = {
            user: PropTypes.shape().isRequired,
            authUserData: PropTypes.shape().isRequired,
            updateUSerData: PropTypes.func.isRequired,
            updateSubscriptionDeactivate: PropTypes.func.isRequired,
            updateSubscriptionReactivate: PropTypes.func.isRequired,
            updateUserProfil: PropTypes.func.isRequired,
            updateOrderFromApi: PropTypes.func.isRequired,
            deleteOrderById: PropTypes.func.isRequired,
        };

        state = {
            currentWeekOrderId: null,
            isFirstOrder: false,
            isUpfrontPayment: false,
            resetOrderId: () => {
                this.setState({currentWeekOrderId: null})
            },
        };

        subscriptionModalRef = React.createRef();

        alertModalRef = React.createRef();

        unsubscribeListener;

        getRefs = () => {
            return {
                subscriptionModalRef: this.subscriptionModalRef,
                alertModalRef: this.alertModalRef,
            };
        };

        componentDidMount = () => {
            const { updateUSerData } = this.props;

            // attach user db handler
            getUserRef().then(userRef => {
                // The get() API allows you to specify a source: server which will wait for the server to give a response.
                // onSnapshot() with includeMetadataChanges: true, and then discard all snapshots with fromCache: true
                this.unsubscribeListener =  userRef.ref.onSnapshot({
                    includeMetadataChanges: true
                }, (doc) => {
                    // console.log("Current data: ", doc.data());
                    // console.log({source:doc.metadata.hasPendingWrites})
                    const userData = doc.data()
                    if (userData.subscriptionRef) {

                        userData.subscriptionRef.get().then(res => {
                            userData.subscription = res.data();
                            updateUSerData(userData);
                        });
                    } else {
                        updateUSerData(userData)
                    }
                });
            });

            // fetch if user already put an order for this week, and get his status
            getCurrentOrder(getNextOrderableWeekToken())
                .then((querySnapshot) => {

                    if (querySnapshot.empty) {
                        // no order, escape
                        return;
                    }
                    const order = querySnapshot.docs[0].data();

                    if (order.isFirstOrder || order.isUpfrontPayment) {
                        // already ordered, but update / delete is forbidden
                        return this.setState({
                            isFirstOrder: order.isFirstOrder,
                            isUpfrontPayment: order.isUpfrontPayment
                        });
                    }
                    // fill current order with backend-data
                    updateOrderFromApi(order);

                    this.setState({
                        currentWeekOrderId: order.orderId
                    });
                });
        };

        componentWillUnmount = () => {
            // remove user db handler
            this.unsubscribeListener();
        };

        render() {
            const { currentWeekOrderId, resetOrderId, ...restState } = this.state;
            return (
                <ModalsRefsContext.Provider
                    value={this.getRefs}
                >
                    <EditableContext.Provider
                        value={{ currentWeekOrderId, resetOrderId }}
                    >
                        <UserComponent {...this.props} {...restState} />
                        <DeactivateSubscriptionModal ref={this.subscriptionModalRef} />
                        <AlertModal ref={this.alertModalRef} />
                    </EditableContext.Provider>
                </ModalsRefsContext.Provider>
            );
        }
    }
    return connect(mapStateToProps, mapDispatchToProps)(UserDataWrapper);
}


/*
const UserDataWrapperHOC = (UserComponent) => () => {
    class UserDataWrapper extends Component {
        static propTypes = {
            user: PropTypes.shape().isRequired,
            authUserData: PropTypes.shape().isRequired,
            updateUSerData: PropTypes.func.isRequired,
            updateSubscriptionDeactivate: PropTypes.func.isRequired,
            updateSubscriptionReactivate: PropTypes.func.isRequired,
            updateUserProfil: PropTypes.func.isRequired,
            updateOrderFromApi: PropTypes.func.isRequired,
        };

        modalRef = React.createRef();

        componentDidMount = () => {
            const { updateUSerData } = this.props;

            // attach user db handler
            getUserRef().then(userRef => {
                // The get() API allows you to specify a source: server which will wait for the server to give a response.
                // onSnapshot() with includeMetadataChanges: true, and then discard all snapshots with fromCache: true
                userRef.ref.onSnapshot({
                    includeMetadataChanges: true
                }, (doc) => {
                    // console.log("Current data: ", doc.data());
                    // console.log({source:doc.metadata.hasPendingWrites})
                    const userData = doc.data()
                    if (userData.subscriptionRef) {

                        userData.subscriptionRef.get().then(res => {
                            userData.subscription = res.data();
                            updateUSerData(userData);
                        });
                    } else {
                        updateUSerData(userData)
                    }
                });
            })
        };

        componentWillUnmount = () => {
            // remove user db handler
        };

        render() {
            console.log(UserComponent);
            return (
                <>
                    <UserComponent {...this.props} subscriptionModalRef={this.modalRef} />
                    <DeactivateSubscriptionModal ref={this.modalRef} />
                </>
            );
        }
    }
    console.log('pute');
    console.log(connect(mapStateToProps, mapDispatchToProps)(UserDataWrapper));
    console.log({mapStateToProps, mapDispatchToProps});
    //return connect(mapStateToProps, mapDispatchToProps)(() => ('Hello'));
    return UserDataWrapper
};
// export default UserDataWrapperHOC

// marche
const DummyHOC = (Tutu) => {
    return connect(mapStateToProps, mapDispatchToProps)((props) => (`Hello ${props.user.email}`));
}



const tutu = (Bite) => () => {
    console.log(UserDataWrapperHOC(Bite));
    return UserDataWrapperHOC(Bite);
    return <Bite />
    try {
        //return compose(connect(mapStateToProps, mapDispatchToProps), bite);
        return connect(mapStateToProps, mapDispatchToProps)(React.cloneElement(bite));
    } catch (e) {console.log(e);}} // UserDataWrapperHOC;
// export default compose(connect(mapStateToProps, mapDispatchToProps), UserDataWrapperHOC);
*/
