import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'styled-components';

import BlackCheckbox from '../../components1/BlackCheckbox';
import { ColorIcon } from '../../components1/Icons';
import FlexDiv from '../../components1/FlexDiv';
import { Text } from '../../components1/Texts';
import { AddToCartButton } from '../../components1/buttons';

import { addItemToSelection, removeItemFromSelection, updateItemFromSelection } from '../../actions';

const halo = css`0 0 5px 1px ${props => props.theme.color.vertFort}`

const mapStateToProps = ({ itemsSelection, }, { item }) => {
    return { itemsSelection, alreadyInCart: itemsSelection.map(i => i.id).includes(item.id)};
};

const mapDispatchToProps = dispatch => {
    return {
        addItemToSelection: value => dispatch(addItemToSelection(value)),
        removeItemFromSelection: value => dispatch(removeItemFromSelection(value)),
        updateItemFromSelection: value => dispatch(updateItemFromSelection(value)),
    };
};

class GroceryDetailView extends PureComponent {
    static propTypes = {
        className: PropTypes.string.isRequired,
        checked: PropTypes.bool,
        item: PropTypes.shape({
            id: PropTypes.string.isRequired,
            imgUrl: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            time: PropTypes.string,
        }).isRequired,
        // col: PropTypes.number.isRequired,
        // add checkbox if selectable
        isSelectable: PropTypes.bool,
        // add function on click
        innerOnClick: PropTypes.func,
        itemsSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        addItemToSelection: PropTypes.func.isRequired,
        removeItemFromSelection: PropTypes.func.isRequired,
    };

    state= {
        // checked: false
    };

    innerHandleCheck = (e) => {
        const { item:{id, price, name}, removeItemFromSelection, addItemToSelection } = this.props;
        // this.setState({checked: e});
        if (e) {
            addItemToSelection({id, price, name});
        } else {
            removeItemFromSelection(id);
        }
    };

    updateCartContent = ({id, itemNumber}) => {
        const {itemsSelection, addItemToSelection, alreadyInCart, item: {name, price}, updateItemFromSelection, removeItemFromSelection } = this.props;
        if (alreadyInCart) {
            if (itemNumber === 0) {
                return removeItemFromSelection(id);
            }
            return updateItemFromSelection({id, unitPrice: parseFloat(price.replace(',', '.')), name, itemNumber});
        }
        addItemToSelection({id, unitPrice: parseFloat(price.replace(',', '.')), name, itemNumber});
    };

    renderIcon = () => {
        const { item: {
            type,
            price,
        }} = this.props;
        const color = (() => {
            switch (type) {
            case 'VG':
                return 'vertFort';
            case 'P':
                return 'jauneFort';
            case 'V':
                return 'rougeFort';
            case 'Mois':
            case 'Mini':
                return 'beigeFort';
            case 'Recette Longue':
                return 'rougeFaible';
            default:
                return 'vertFort';
            }
        })();
        return (<ColorIcon color={color}><Text css="font-size: 0.725rem;">{`${price}€`}</Text></ColorIcon>);
    };

    render() {
        const { checked, innerOnClick, isSelectable, className, item: {
            imgUrl,
            name,
            type,
            time,
        }, itemsSelection} = this.props;

        const itemInSelection = itemsSelection.find(item => item.id === this.props.item.id);
        const itemPreviousValue = typeof itemInSelection !== 'undefined' ? itemInSelection.itemNumber : 0;

        return (
            <FlexDiv
                className={`col-${className}`}
                style={{
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div
                    checked={checked}
                    isSelectable={isSelectable}
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flex: 1,
                        padding: 10,
                        cursor: 'pointer',
                    }}
                    css={`
                        :hover {
                            opacity: 0.7;
                        }
                        /* border: ${props => props.checked ? 'solid' : 'none'};*/
                        /* outline: ${props => props.checked ? '4px solid red': 'none'};*/
                        /* outline-offset: 5px; */
                        box-shadow: ${props => (props.isSelectable && props.checked) ? halo : 'none'};

                    `}
                    onClick={innerOnClick}
                    role="button"
                >
                    <img
                        src={imgUrl}
                        alt="identite"
                        width="100%"
                    />
                    <div
                        style={{
                            justifyContent: 'center',
                            display: 'flex',
                        }}
                    >
                        <div style={{
                            // fontWeight: 'bold',
                            fontSize: 14,
                            maxWidth: '70px',
                            // height: '100px',
                            padding: '1em',
                            display:'flex',
                            textAlign: 'center',
                            justifySelf: 'center',
                            textAlignLast: 'center',
                            alignItems: 'center'
                        }}>
                            {name}
                        </div>
                    </div>
                </div>
                <FlexDiv style={{flex: '0 0 auto', justifyContent: 'center', alignItems: 'center'}}>
                    <div>
                        {
                            this.renderIcon(type)
                        }
                    </div>
                    {
                        isSelectable && (
                            // <BlackCheckbox innerHandleCheck={this.innerHandleCheck}  checked={checked}/>
                            <AddToCartButton itemId={this.props.item.id} updateCart={this.updateCartContent} itemPreviousValue={itemPreviousValue} />
                        )
                    }
                </FlexDiv>
            </FlexDiv>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryDetailView);
