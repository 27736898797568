import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLinkWithoutStyle, StaticQuery, graphql } from 'gatsby';
import Sticky from 'react-sticky-el';
import {connect} from 'react-redux'
import Image from 'gatsby-image';

import connectWithStore from '../../stores';
import withTheme from '../../theme';

import { deconnexion,  } from '../../actions';
import { CabadiciButton, CabadiciConnectionButton } from '../buttons';
import Modalize from '../../components/Modalizer';
import ConnectionForm from '../../containers/ConnectionForm';
import Section from '../Section';
import { Text } from '../Texts';
import { parenthesesRoses } from '../../assets';

export const GatsbyLink = styled(GatsbyLinkWithoutStyle)`
    :hover {
        opacity: 0.7;
    }
    :active {
        outline: none;
        border: none;
    }
`;

export const TextResponsive = styled(Text)`
    @media only screen and (min-width: 599px) and (max-width: 799px) {
        font-size: 12px;
    }
`;

export const tabs = [
    {
        name: 'Le concept',
        anchorId: 'section3',
        page: '/concept',
    },
    {
        name: 'Les recettes',
        anchorId: 'section4',
        page: '/recettes'
    },
    {
        name: 'Les Livraisons',
        anchorId: 'section5',
        page: '/livraisons'
    },
    {
        name: 'D’ici, mais d’où ?',
        anchorId: 'section6',
        page: '/ici-ou'
    },
    {
        name: 'L\'épicerie',
        anchorId: 'section7',
        page: '/epicerie'
    },
];

export const tabsUser = [
    {
        name: 'Mon prochain cabadici',
        page: '/user/cabadici',
    },
    {
        name: 'Mes coordonnées',
        page: '/user/informations'
    },
    {
        name: 'Mes commandes',
        page: '/user/orders'
    },
];


export const ConnectTabs = styled(CabadiciConnectionButton).attrs({
    isSubHeaderTabs: true,
})`
    /*
    position: relative;
    top: -5px;
    left: -15px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: flex-end;
    */
`;

const ModalizedForm = Modalize(ConnectionForm, {
    onClose: () => {},
    onExited: () => {},
});

/* Use Class for ref */
class SubHeaderTabs extends PureComponent {
    static propTypes = {
        deconnexion: PropTypes.func.isRequired,
        isLogged: PropTypes.bool.isRequired,
        theme: PropTypes.shape().isRequired
    };

    render() {
        const { signOut, isLogged, theme, modalRef, isUserPage } = this.props;
        const dataTabs = isUserPage ? tabsUser : tabs;
        return (
            <StaticQuery
                query={graphql`
                    query {
                        logo: file(relativePath: { eq: "logos/logo-noir.png" }) {
                            childImageSharp {
                                fixed(height: 30) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    return (
                        <Sticky
                            holderProps={{
                                style:{
                                    position: 'relative',
                                    zIndex: 10
                                }
                            }}
                            style={{
                                zIndex: 10,
                                // marginBottom: -30
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    display: none;
                                }
                            `}
                        >
                            <div
                                style={{
                                    height: 50,
                                    backgroundColor: theme.color.beigeFort,
                                    display: 'flex',
                                    alignItems: 'stretch'
                                }}
                            >
                                <Section
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        marginLeft: 0,
                                        marginRight: 0,
                                    }}
                                >
                                    <GatsbyLink
                                        to={isUserPage ? '/user' : '/'}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Image
                                            fixed={data.logo.childImageSharp.fixed}
                                            alt="logo-menu"
                                            objectFit="contain"
                                            imgStyle={{
                                                width: 'auto',
                                                objectFit: 'contain',
                                            }}
                                            loading="eager"
                                            fadeIn={false}
                                        />
                                    </GatsbyLink>
                                    {
                                        dataTabs.map((t, index)=> (
                                            <GatsbyLink
                                                key={index.toString()}
                                                to={t.page}
                                                style={{
                                                    textDecoration: 'none',
                                                    color: '#000',
                                                    whiteSpace: 'nowrap',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: 35,
                                                }}
                                                activeStyle={{
                                                    color: theme.color.rougeFaible,
                                                    backgroundImage: `url(${parenthesesRoses})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: '100% 100%',
                                                    minWidth: isUserPage ? '15rem' : '9.5rem',
                                                }}
                                                partiallyActiveNo
                                                onClick={this.toggleActive}
                                            >
                                                <TextResponsive
                                                    style={{
                                                        textTransform: 'uppercase',
                                                        textAlign: 'center',
                                                    }}
                                                >{t.name}</TextResponsive>
                                            </GatsbyLink>
                                        )
                                        )
                                    }
                                </Section>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', height:0, zindex: 10 }}>
                                {isLogged ? (
                                    <ConnectTabs
                                        color="rougeFort"
                                        onClick={() => signOut()}
                                        css="right: auto;padding-left: 5px !important;"
                                    >
                                        se déconnecter
                                    </ConnectTabs>
                                ) : (
                                    <>
                                        <ConnectTabs
                                            color="rougeFort"
                                            onClick={() => {
                                                // this.ModalizedForm.onOpenModal()
                                                modalRef.current.openModal()
                                            }}>
                                            se connecter
                                        </ConnectTabs>
                                        <ModalizedForm
                                            ref={tutu => {
                                                this.ModalizedForm = tutu;
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </Sticky>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = ({ isLogged }) => {
    return {
        isLogged,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        deconnexion: () => dispatch(deconnexion()),
    };
};


export default withTheme(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubHeaderTabs));
