import React from 'react';
import styled, { withThemeOld } from 'styled-components';
import Slider, { Range } from 'rc-slider';
import NumberFormat from 'react-number-format';
import BackgroundImage from 'gatsby-background-image'
import { StaticQuery, graphql } from 'gatsby';

import { BackgroundCell, Cell, Row, Section, Title } from '../components/Grid';
import withTheme from '../theme';
import {Text} from '../components1/Texts';
import FlexDiv from '../components1/FlexDiv';
import connectWithStore from '../stores';

// We can just import Slider or Range to reduce bundle size
// import Slider from 'rc-slider/lib/Slider';
// import Range from 'rc-slider/lib/Range';

import 'rc-slider/assets/index.css';

const GreenCell = withTheme(styled(Cell)`
    color: ${props => props.theme.color.vertFort};
    font-size: 30px;
`);


const prices = {
    0: 70,
    1: 90,
    2: 110,
    3: 120,
};
const recipes = {
    0: 2,
    1: 3,
    2: 4,
    3: 5,
};
const recipesKeys = {
    2: 0,
    3: 1,
    4: 2,
    5: 3,
};
const marks2 = {
    0: '2 recettes',
    1: '3 recettes',
    2: '4 recettes',
    3: '5 recettes',
};

const StyledStrong = styled.span`
    color: #666;
    font-size: 1.5rem;
    font-weight: 600;
    @media only screen and (max-width: 599px) {
        font-size: 0.875rem !important;
        max-width: 50px;
        display: block;
    }
    font-family: ${props => props.theme.font.title};
`;

const marks = {
    0: {
        label: <StyledStrong>2 recettes</StyledStrong>,
    },
    1: {
        label: <StyledStrong>3 recettes</StyledStrong>,
    },
    2: {
        label: <StyledStrong>4 recettes</StyledStrong>,
    },
    3: {
        label: <StyledStrong>5 recettes</StyledStrong>,
    }
};

const RecipesSliderPage = ({theme}) => {
    return (
        <Section
            style={{
                width: '100%',
                minHeight: '40px',
                paddingTop: '15px',
                // paddingBottom:'20px',
            }}>
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    padding: 0
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.125rem !important;
                    }
                    font-family: ${props => props.theme.font.title};
                `}
            >Pour chaque recette, recevez les ingrédients pour cuisiner</Text>
            <Text
                style={{
                    textAlign: 'center',
                    paddingTop: 0,
                    paddingBottom: 30,
                    fontSize: '1.825rem',
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.5rem !important;
                    }
                    font-family: ${props => props.theme.font.title};
                `}
            >pour 4 personnes.</Text>
            <Text
                style={{
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    paddingBottom: 10
                }}
                css={`
                    @media only screen and (max-width: 599px) {
                        font-size: 1.125rem !important;
                    }
                    font-family: ${props => props.theme.font.title};
                `}
            >Cette semaine, si vous choisissez de recevoir :</Text>
            <ConnectedRecipesSlider theme={theme}/>
        </Section>
    );
}

class RecipesSlider extends React.PureComponent {
    static getDerivedStateFromProps(props, state) {
        if (props.recipesNumber !== recipes[state.value]) {
            return {
                value: recipesKeys[props.recipesNumber],
            };
        }
        return null;
    }

    state = {
        index: 1,
        value: 1,
    };

    onChange = value => {
        const {updateRecipesNumber} = this.props;
        this.setState({ value });
        if (updateRecipesNumber) {
            updateRecipesNumber(recipes[value])
        }
    };

    render() {
        const { theme, recipesNumber, className, isLogged } = this.props;
        const { index, value } = this.state;
        // console.log({recipesNumber, index: recipesKeys[recipesNumber]});
        return (
            <StaticQuery
                query={graphql`
                    query {
                        backgroundParenthesesVertes: file(relativePath: { eq: "parentheses/horizontal/vertFaible.png" }) {
                            childImageSharp {
                                fluid(maxHeight: 200, quality: 90) {
                                    ...GatsbyImageSharpFluid_noBase64
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    return (
                        <FlexDiv
                            className={className}
                            style={{
                                flexDirection: 'column',
                            }}
                        >
                            <div
                                style={{
                                    margin: '0 10px 40px 10px',
                                    position: 'relative'
                                }}
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        margin: 0 50px 40px 50px !important;
                                    }
                                `}
                            >
                                <Slider
                                    min={-0.5}
                                    max={3.5}
                                    marks={marks}
                                    step={null}
                                    onChange={this.onChange}
                                    defaultValueOld={recipesKeys[recipesNumber]}
                                    value={this.state.value}
                                    activeDotStyle={{ fontWeight: 'bold' }}
                                    railStyle={{backgroundColor: theme.color.rougeFaible, height: 3}}
                                    trackStyle={{backgroundColor: theme.color.rougeFaible, height: 3}}
                                    dotStyle={{
                                        backgroundColor: theme.color.rougeFaible,
                                        borderRadius: 0,
                                        marginBottom: -5,
                                        height: 20,
                                        width: 2,
                                        marginLeft: -1,
                                        border: `1px solid ${theme.color.rougeFaible}`,
                                    }}
                                    handleStyle={{
                                        backgroundColor: theme.color.rougeFort,
                                        border: theme.color.rougeFaible,
                                    }}
                                />
                            </div>
                            <Text
                                isLogged={isLogged}
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.5rem',
                                    fontWeight: 600,
                                    padding: '10px 0',
                                    flexGrow: 1,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1.125rem !important;
                                    }
                                    ${props => props.isLogged && `color: ${props.theme.color.vertFort};`}
                                    font-family: ${props => props.theme.font.title};
                                `}
                            >
                                {
                                    !isLogged ?
                                        'votre CABADICI sera au tarif de :' :
                                        'Mon CABADICI sera donc au tarif de'
                                }
                            </Text>
                            <BackgroundImage
                                fluid={data.backgroundParenthesesVertes.childImageSharp.fluid}
                                style={{
                                    // default values:
                                    // backgroundRepeat: 'no-repeat',
                                    // backgroundSize: 'cover',
                                    // backgroundPosition: 'center',

                                    backgroundSize: '100% 100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    maxWidth: '20em',

                                    flexDirection: 'column',
                                    marginTop: 10,
                                    marginBottom: 30,
                                    alignSelf: 'center',
                                }}
                                fadeIn={false}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        min-height: 100px !important;
                                        margin-top: 5px !important;
                                    }
                                `}
                            >
                                <GreenCell
                                    style={{
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        padding: '0 40px 0 40px',
                                        flexBasis: 100,
                                        justifyContent: 'center',
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            padding: 0 10px !important;
                                        }
                                    `}
                                >
                                    <Text
                                        style={{flexGrow: 1, alignItems: 'center', display: 'flex'}}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                font-size: 1.5rem !important;
                                            }
                                        `}
                                    >
                                        {`${prices[recipesKeys[recipesNumber]]},00€`}
                                    </Text>
                                    <Text
                                        style={{
                                            textAlign: 'center',
                                            fontSize: '1rem',
                                            whiteSpace: 'nowrap',
                                            fontWeight: 600,
                                        }}
                                        css={`
                                            @media only screen and (max-width: 599px) {
                                                font-size: 0.875rem !important;
                                            }
                                            font-family: ${props => props.theme.font.title};
                                        `}
                                    >
                                        {'soit '}
                                        <span
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '1.25rem',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 600,
                                            }}
                                            css={`
                                                @media only screen and (max-width: 599px) {
                                                    font-size: 1rem !important;
                                                }
                                                font-family: ${props => props.theme.font.title};
                                            `}
                                        >
                                            <NumberFormat
                                                value={prices[recipesKeys[recipesNumber]]/recipesNumber/4}
                                                displayType="text"
                                                renderText={value => `${value}`}
                                                suffix="€"
                                                decimalScale={2}
                                                fixedDecimalScale
                                                decimalSeparator=","
                                            />
                                        </span>
                                        <br />
                                        par pers. & par recette
                                    </Text>
                                </GreenCell>
                            </BackgroundImage>
                        </FlexDiv>
                    );
                }}
            />
        );
    }
}
const RecipesSliderThemed = withTheme(RecipesSlider)
import { connect } from 'react-redux';
/*
import {
    connectAction,
    connectByToken,
    createUserAction,
    updateRecipes,
} from '../actions';
*/
const mapStateToProps = (state) => {
    // console.log(state);

    return { recipesNumber: state.recipesNumber, };
};

const mapDispatchToProps = dispatch => {
    return {
        /*
        updateRecipes: (week, month) => dispatch(updateRecipes(week, month)),
        connect: (user, pwd) => dispatch(connectAction(user, pwd)),
        connectByToken: token => dispatch(connectByToken(token)),
        createUserAction: props => dispatch(createUserAction(props)),
        */
        updateRecipesNumber: (value) => dispatch({ type: 'UPDATE_RECIPES_NUMBER', value }),
    };
};

export const ConnectedRecipesSlider = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipesSliderThemed);

export default withTheme(RecipesSliderPage);
