import styled from 'styled-components';
import withTheme from '../theme';
import { Title } from './Texts';

const LinedTitle = withTheme(styled(Title)`
    display: grid;
    width: 100%;
    align-items: center;
    text-align: center;
    grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
    grid-gap: 20px;

    :before,
    :after {
        color: ${props => props.borderColor ? props.borderColor : props.theme.color.beigeFort};
        content: '';
        border-top: ${props => props.borderWidth ? props.borderWidth : '10px'} solid;
    }

    @media only screen and (max-width: 599px) {
        grid-gap: 0px;
    }
`);

export default LinedTitle;
