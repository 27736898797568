import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Formik, Form, Field, ErrorMessage } from '../../components1/forms/formik';
import { CabadiciButton } from '../../components1/buttons';
import { SignupSchema } from './schemas';
import { DrawHandStyleInput, } from '../../components1/forms';

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, minmax(15px, auto));
    grid-row-gap: 5px;
    grid-template-areas: 'a'
                         'b'
                         'c'
                         'd'
                         'e'
                         'e';
    justify-items: center;
`;

const SignUpForm = ({ onSubmit }) => (
    <Formik
        initialValues={{
            email: '',
            password: '',
        }}
        validationSchemaNo={SignupSchema}
        onSubmit={onSubmit}
    >
        <Form>
            <Grid
                style={{
                    flex: 1,
                    alignItems: 'stretch',
                }}
            >
                <Field
                    name="email"
                    type="email"
                    component={DrawHandStyleInput}
                    placeholder="adresse email"
                    autoComplete="email"
                    style={{
                        flex: 1,
                        margin: '0 10px',
                        gridArea: 'a'
                    }}
                />
                <ErrorMessage name="email" component="div" style={{margin: '0 10px', fontSize: '0.75rem', gridArea: 'b'}}/>
                <Field
                    name="password"
                    type="password"
                    component={DrawHandStyleInput}
                    placeholder="mot de passe"
                    style={{
                        flex: 1,
                        margin: '0 10px',
                        gridArea: 'c'
                    }}
                />
                <ErrorMessage name="password" component="div" style={{margin: '0 10px', fontSize: '0.75rem', gridArea: 'd'}}/>
                <CabadiciButton
                    type="submit"
                    color="jauneFort"
                    style={{
                        justifySelf: 'center',
                        gridArea: 'e'
                    }}
                >
                    Se connecter
                </CabadiciButton>
            </Grid>
        </Form>
    </Formik>
);

SignUpForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
