import React, { useCallback, useContext } from 'react';
import { withTheme } from 'styled-components';

import { EditableContext, ModalsRefsContext } from '../../UserDataWrapper';

import { SuspendSubscriptionButton as Button} from '../../../../components1/buttons';
import { Text } from '../../../../components1/Texts';
import FlexDiv from '../../../../components1/FlexDiv';
import { TextResponsive } from '../../../../components1/layoutFolder/SubHeaderTabs';

import { getNextOrderableWeekToken } from '../../../../services/dates';

const MenuButton = withTheme(({ data, theme, onClick }) => (
    <FlexDiv style={{justifyContent: 'center',}} onClick={onClick}>
        <FlexDiv
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.color[data.color],
                backgroundImage: `url(${data.parentheses})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                flexBasis: '15rem',
                flexGrow: 0,
                height: '5rem',
            }}
            css={`
                &:hover{
                    cursor: pointer;
                    opacity: 0.7;
                }
            `}
        >
            <TextResponsive
                style={{
                    padding: 10,
                    textAlign: 'center',
                    fontFamily: theme.font.title,
                    fontSize: '1.25rem',
                    fontWeight: 600,
                }}
            >{data.name.map(n => (<>{n}<br /></>))}</TextResponsive>
        </FlexDiv>
    </FlexDiv>
));

export const SuspendSubscriptionButton = ({
    updateSubscriptionDeactivate, page, data, deleteOrderById,
} ) => {
    const getRef = useContext(ModalsRefsContext);
    const { currentWeekOrderId, resetOrderId } = useContext(EditableContext);
    const memoizedCallback = useCallback(
        () => {
            const action = (doDelete = false) => {
                if (doDelete) {
                    return deleteOrderById(currentWeekOrderId)
                        .then(() => {
                            resetOrderId()
                            return updateSubscriptionDeactivate()
                        })
                        .then(() => {
                            const { subscriptionModalRef } = getRef();
                            subscriptionModalRef.current.setData({
                                title: 'Revenez vite !',
                                message: 'Votre commande a été supprimée, votre abonnement est temporairement désactivé'
                            });
                        });
                }
                updateSubscriptionDeactivate()
                    .then(() => {
                        const { subscriptionModalRef } = getRef();
                        subscriptionModalRef.current.setData({
                            title: 'Revenez vite !',
                            message: 'Votre abonnement est temporairement désactivé'
                        });
                    })
            };

            // check if user already orders for this week:
            if (currentWeekOrderId) {
                const { alertModalRef } = getRef();
                return alertModalRef.current.setData({
                    title: 'Attention !',
                    message: 'Vous avez déjà confirmé une commande pour cette semaine, êtes-vous sûr de vouloir supprimer cette commande ?',
                    callback: () => { action(true)}
                });
            }

            action();
        },
        [updateSubscriptionDeactivate, getRef, currentWeekOrderId],
    );

    return (
        page === 'myAccount' ? (
            <MenuButton
                data={data}
                onClick={memoizedCallback}
            />
        ) : (
            <Button
                onClick={memoizedCallback}
            >
                <Text
                    style={{
                        textDecoration: 'underline',
                        alignSelf: 'center',
                        whiteSpace: 'nowrap',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.875rem !important;
                        }
                    `}
                >
                    suspendre toutes mes prochaines livraisons
                </Text>
            </Button>
        )
    )
};

export const ActivateSubscriptionButton = ({ updateSubscriptionReactivate, page, data } ) => {
    const getRef = useContext(ModalsRefsContext);
    const currentWeekOrderId = useContext(EditableContext);
    const memoizedCallback = useCallback(
        () => {
            updateSubscriptionReactivate()
                .then(() => {
                    const { subscriptionModalRef } = getRef();
                    subscriptionModalRef.current.setData({
                        title: 'Heureux de vous retrouver !',
                        message: 'Votre abonnement est de nouveau activé'
                    });
                })
        },
        [updateSubscriptionReactivate, getRef],
    );

    return (
        page === 'myAccount' ? (
            <MenuButton
                data={data}
                onClick={memoizedCallback}
            />
        ) : (
            <FlexDiv
                onClick={memoizedCallback}
                style={{
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingBottom: 10,
                }}
                css={`
                    &:hover{
                        cursor: pointer;
                        opacity: 0.7;
                    }
                `}
            >
                <Button
                    css="padding: 10px 10px 0 10px !important;"
                >
                    {
                        <Text
                            style={{
                                textDecoration: 'underline',
                                alignSelf: 'center',
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.875rem !important;
                                }
                            `}
                        >
                            vous avez suspendu toutes vos livraisons
                        </Text>
                    }
                </Button>
                <Text
                    style={{
                        alignSelf: 'center',
                        textTransform: 'uppercase',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.875rem !important;
                        }
                    `}
                >
                    Les réactiver
                </Text>
            </FlexDiv>
        )
    )
};

export const SuspendCurrentWeekOrderButton = ({
    subscriptionDeactivateCurrentWeekOrder, page, data, deleteOrderById,
} ) => {
    const getRef = useContext(ModalsRefsContext);
    const { currentWeekOrderId, resetOrderId } = useContext(EditableContext);
    const memoizedCallback = useCallback(
        () => {
            const action = (doDelete = false) => {
                const weekId = getNextOrderableWeekToken();
                if (doDelete) {
                    return deleteOrderById(currentWeekOrderId)
                        .then(() => {
                            resetOrderId()
                            return subscriptionDeactivateCurrentWeekOrder(weekId)
                        })
                        .then(() => {
                            const { subscriptionModalRef } = getRef();
                            subscriptionModalRef.current.setData({
                                title: 'Bien recu !',
                                message: 'Votre commande a été supprimée, vous ne serez pas livré cette semaine !'
                            });
                        });
                }
                subscriptionDeactivateCurrentWeekOrder(weekId)
                    .then(() => {
                        const { subscriptionModalRef } = getRef();
                        subscriptionModalRef.current.setData({
                            title: 'Bien recu !',
                            message: 'Vous ne serez pas livré cette semaine !'
                        });
                    });
            };

            // check if user already orders for this week:
            if (currentWeekOrderId) {
                const { alertModalRef } = getRef();
                return alertModalRef.current.setData({
                    title: 'Attention !',
                    message: 'Vous avez déjà confirmé une commande pour cette semaine, êtes-vous sûr de vouloir supprimer cette commande ?',
                    callback: () => { action(true)}
                });
            }

            action();
        },
        [subscriptionDeactivateCurrentWeekOrder, getRef, currentWeekOrderId],
    );

    return (
        page === 'myAccount' ? (
            <MenuButton
                data={data}
                onClick={memoizedCallback}
            />
        ) : (
            <Button
                onClick={memoizedCallback}
            />
        )
    )
};

export const ActivateCurrentWeekOrderButton = ({ subscriptionReactivateCurrentWeekOrder, page, data } ) => {
    const getRef = useContext(ModalsRefsContext);
    const currentWeekOrderId = useContext(EditableContext);
    const memoizedCallback = useCallback(
        () => {
            const weekId = getNextOrderableWeekToken();
            subscriptionReactivateCurrentWeekOrder(weekId)
                .then(() => {
                    const { subscriptionModalRef } = getRef();
                    subscriptionModalRef.current.setData({
                        title: 'Félicitation !',
                        message: 'Votre livraison de la semaine est de nouveau active !'
                    });
                });
        },
        [subscriptionReactivateCurrentWeekOrder, getRef],
    );

    return (
        page === 'myAccount' ? (
            <MenuButton
                data={data}
                onClick={memoizedCallback}
            />
        ) : (
            <FlexDiv
                onClick={memoizedCallback}
                style={{
                    flexDirection: 'column'
                }}
                css={`
                    &:hover{
                        cursor: pointer;
                        opacity: 0.7;
                    }
                `}
            >
                <Button
                    css="padding: 10px 10px 0 10px !important;"
                >
                    {
                        <Text
                            style={{
                                textDecoration: 'underline',
                                alignSelf: 'center',
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 0.875rem !important;
                                }
                            `}
                        >
                            vous avez suspendu cette livraison
                        </Text>
                    }
                </Button>
                <Text
                    style={{
                        alignSelf: 'center',
                        textTransform: 'uppercase',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            font-size: 0.875rem !important;
                        }
                    `}
                >
                    La réactiver
                </Text>
            </FlexDiv>
        )
    )
};
