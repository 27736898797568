import React from 'react';
import styled from 'styled-components';

const VerticalSeparator = styled.div`
    border: none;
    border-left: ${props => (props.strokeWeight ? props.strokeWeight : '1px')}
        solid ${props => props.theme.color[props.strokeColor]};
    /*height: 100%;*/
    min-height: 80px;
    width: 1px;
`;

export default VerticalSeparator;
