import React from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';

import { connexion } from '../actions';
import { Button } from '../components/buttons';
import connectWithStore from '../stores';

const ConnectionForm = ({ connexion }) => {

    const onClick = () => {
        connexion();
        navigate('/user');
    };

    return (
        <>
            <h2>Formulaire de Connexion</h2>
            <Button onClick={onClick}>Connexion</Button>
        </>
    );
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = dispatch => {
    return {
        connexion: () => dispatch(connexion()),
    };
};

export default connectWithStore(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionForm);
