import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';

import FlexDiv from '../components1/FlexDiv';
import { CabadiciButton, BackButton } from '../components1/buttons';
import UserInformations from './forms/UserInformations';
import { createUser, createUserError, signOut } from '../actions';
import SignInSignUpErrorModal from './modals/SignInSignUpError';
import Separator from '../components1/Separator';

const mapStateToProps = ({error, user, authUserData}) => ({
    error,
    user,
    authUserData,
});

const mapDispatchToProps = dispatch => ({
    createUser: (values) => dispatch(createUser(values)),
    createUserError: (error) => dispatch(createUserError(error)),
    signOut: () => dispatch(signOut()),
});

const CoordinatesPage = ({ error, user, authUserData, innerNextTab, innerPrevTab, createUser, createUserError, signOut, connectionModalRef }) => {
    const formRef = React.createRef();

    return (
        <>
            <UserInformations
                ref={formRef}
                authUserData={authUserData}
                signOut={signOut}
                onSubmit={({email, password, ...userData}) => {
                    if (!isEmpty(authUserData)) {
                        return innerNextTab();
                    }
                    createUser({email, password, ...userData})
                        .then(info => {
                            innerNextTab();
                        })
                        .catch(error => {
                            createUserError(error);
                        });
                }}
            />
            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="jauneFort"
                        onClick={innerPrevTab}
                    />
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 3}}
                >
                    <CabadiciButton
                        color="rougeFort"
                        onClick={(...args) => {
                            formRef.current.handleSubmit(...args);
                        }}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.625rem !important;
                            }
                        `}
                    >
                        suivant
                    </CabadiciButton>
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>
            <SignInSignUpErrorModal isOpen={!!error} connectionModalRef={connectionModalRef} />
        </>
    );
};

CoordinatesPage.propTypes = {
    innerNextTab: PropTypes.func.isRequired,
    innerPrevTab: PropTypes.func.isRequired,
    createUser: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatesPage);
