import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'styled-components'

import FlexDiv from '../../components1/FlexDiv';
import Separator from '../../components1/Separator';
import { Text } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';

import {ConnectedRecipesSlider as RecipesSlider} from '../RecipesSlider';
import RecipeSelector from '../RecipeSelector';
import SubscriptionSelector from '../SubscriptionSelector';
import { getNextOrderableWeekToken } from '../../services/dates';

const mapStateToProps = ({ recipesSelection, recipesNumber, isLogged, user }) => ({
    recipesSelection,
    recipesNumber,
    isLogged,
    user,
});

class FirstOrderRecipesChoice1 extends Component {
    static propTypes = {
        recipesSelection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        recipesNumber: PropTypes.number.isRequired,
        goToNextSlide: PropTypes.func.isRequired,
        scrollTo: PropTypes.func.isRequired,
    };

    state = {
        error: null,
    };

    onClick = () => {
        const { recipesSelection, recipesNumber, goToNextSlide, scrollTo, isLogged, user } = this.props;
        const isVacancy = ['2019-31', '2019-32', '2019-33'].includes(getNextOrderableWeekToken());
        if (isVacancy) {
            return this.setState({
                error: 'Revenez à partir du 25 août pour découvrir les recettes de la rentrée !',
            });
        }
        if (isLogged && (typeof user.subscription !== 'undefined') && (
            !user.subscription.isActive || user.subscription.suspendedWeekId.includes(getNextOrderableWeekToken())
        )) {
            return this.setState({
                error: 'Votre abonnement est actuellement désactivé pour cette semaine, veuillez le réactiver pour continuer votre commande.',
            });
        }
        if (recipesSelection.length !== recipesNumber) {
            return this.setState({
                error: 'Vous avez choisi un mauvais nombre de recettes',
            });
        }
        goToNextSlide();
        scrollTo();
    };

    componentDidUpdate = () => {
        const { recipesSelection, recipesNumber } = this.props;
        const { error } = this.state;
        if (recipesSelection.length === recipesNumber && error) {
            // this.setState({error: null});
        }
    };

    render() {
        const {
            showSubscriptionOptions,
            className,
            isLogged,
            isSuscriber,
            isNotCustomer,
            showSubscriptionSuspendButton,
            showReactiveSubscriptionButton,
            innerModalRef,
        } = this.props;
        const { error } = this.state;

        return (
            <FlexDiv
                className={className}
                style={{
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                }}
            >
                <FlexDiv
                    isLogged={isLogged}
                    style={{
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        paddingTop: 20,

                    }}
                    css={`
                        ${props => props.isLogged && (css`
                                background-color: ${props => props.theme.color.beigeFaible};
                                width: 100vw;
                                margin-left: -50vw;
                                left: 50%;
                                position: relative;
                                /*z-index: -1;*/
                            `)}

                    `}
                >
                    <Text
                        style={{
                            padding: '20px 0',
                            fontSize: '2.5rem',
                            fontWeight: 600,
                        }}
                        css={`
                            font-family: ${props => props.theme.font.title};
                            @media only screen and (max-width: 599px) {
                                font-size: 1.5rem !important;
                                padding-top: 0 !important;
                            }
                        `}
                    >
                        {
                            isLogged ?
                                (isNotCustomer ?
                                    'Mon premier ' :
                                    'Mon prochain '
                                ) :
                            'Votre prochain '
                        }CABADICI
                    </Text>
                    {
                        isLogged ? (
                            <Text
                                style={{
                                    textAlign: 'center',
                                    fontSize: '1.5rem',
                                    fontWeight: 600,
                                    paddingTop: 20,
                                    paddingBottom: 20,
                                }}
                                css={`
                                    @media only screen and (max-width: 599px) {
                                        font-size: 1rem !important;
                                        padding-top: 10px !important;
                                    }
                                    font-family: ${props => props.theme.font.title};
                                `}
                            >{`1 – Je choisis le nombre de recettes qui composeront ma ${isNotCustomer ? 'première' : 'prochaine'} livraison :`}</Text>
                        ) : (
                            <>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        padding: 0
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 1.125rem !important;
                                        }
                                        font-family: ${props => props.theme.font.title};
                                    `}
                                >Pour chaque recette, recevez les ingrédients pour cuisiner</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        paddingTop: 0,
                                        paddingBottom: 30,
                                        fontSize: '1.825rem',
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 1.5rem !important;
                                        }
                                        font-family: ${props => props.theme.font.title};
                                    `}
                                >pour 4 personnes.</Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontSize: '1.5rem',
                                        paddingBottom: 10
                                    }}
                                    css={`
                                        @media only screen and (max-width: 599px) {
                                            font-size: 1.125rem !important;
                                            padding-top: 20px !important;
                                        }
                                        font-family: ${props => props.theme.font.title};
                                    `}
                                >Cette semaine, si vous choisissez de recevoir :</Text>
                            </>
                        )
                    }
                    <RecipesSlider isLogged={isLogged}/>
                </FlexDiv>
                {
                    showSubscriptionOptions && (
                        <>
                            <Text
                                style={{paddingTop: 20}}
                            >{
                                !isLogged ? 'Votre' : 'Mon'
                            } abonnement</Text>
                            <SubscriptionSelector />
                        </>
                    )
                }
                {
                    /*
                    <Text
                        style={{paddingTop: 20, fontSize: '1.5rem'}}
                        css={`font-family: ${props => props.theme.font.title};`}
                    >
                        {
                            !isLogged ? 'Vos' : 'Mes'
                        } recettes
                    </Text>
                    */
                }
                {
                    !isLogged ? (
                        <Text
                            style={{paddingTop: 20, fontSize: '1.5rem', textTransform: 'uppercase', fontWeight: 600,}}
                            css={`
                                font-family: ${props => props.theme.font.title};
                                color: ${props => props.theme.color.rougeFaible};
                            `}
                        >
                            Les recettes
                        </Text>
                    ) : (
                        <Text
                            style={{
                                textAlign: 'center',
                                fontSize: '1.5rem',
                                fontWeight: 600,
                                paddingTop: 30,
                                paddingBottom: 10,
                            }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    font-size: 1rem !important;
                                }
                                font-family: ${props => props.theme.font.title};
                                color: ${props => props.theme.color.rougeFaible};
                            `}
                        >2 - Je choisis mes recettes :</Text>
                    )
                }
                <RecipeSelector
                    isSelectable
                    isLogged={isLogged}
                    isSuscriber={isSuscriber}
                    showSubscriptionSuspendButton={showSubscriptionSuspendButton}
                    showReactiveSubscriptionButton={showReactiveSubscriptionButton}
                    innerModalRef={innerModalRef}
                />
                <CabadiciButton
                    color="rougeFort"
                    style={{
                        maxWidth: 200,
                        alignSelf: 'center',
                    }}
                    onClick={this.onClick}
                >
                    suivant
                </CabadiciButton>
                {
                    error && (
                        <Text
                            style={{
                                color: 'red',
                                fontSize: '0.875rem',
                                paddingTop: 10,
                            }}
                        >
                            {error}
                        </Text>
                    )
                }
            </FlexDiv>
        );
    }
}

export default connect(mapStateToProps)(FirstOrderRecipesChoice1);
