import React, { useState, useEffect } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import './CheckoutForm.css';

import { CabadiciButton } from '../../../components1/buttons';
import { makePaymentFromApi, addCustomerFromApi, addChargeToCustomerFromApi, getUser } from '../../../services';

const CheckoutForm = ({ amount, userData, stripe, innerRef, handlePaymentStatusChange, paymentHandler, style }) => {
    const [status, setStatus] = useState('default');
    useEffect(() => handlePaymentStatusChange(status), [status])

    const submit = async e => {
        e.preventDefault();
        setStatus('submitting');
        const user = getUser()

        stripe.createToken({ name: userData.userInformations.lastName })
            .then(resp => addCustomerFromApi({
                email: userData.email,
                token: resp.token.id,
                name: userData.userInformations.lastName,
                meta: {
                    cabadici_id: user.uid
                }
            }).then((respCustomer)=>
                ({
                    customer: respCustomer,
                    token: resp.token
                })
            ))
            .then(({customer, token}) => {
                
                // save customer_id to our db + card number ?
                return paymentHandler({
                    customerId: customer.data.id,
                    card: {
                        id: token.card.id,
                        brand: token.card.brand,
                        exp_month: token.card.exp_month,
                        exp_year: token.card.exp_year,
                        cardLast4digit: token.card.last4
                    }
                });
            })
            .then(() => {
                setStatus('complete');
                // go to
            })
            .catch(err => {
                console.error(err);
                setStatus('error');
            });
    };

    if (status === 'complete') {
        return <div className="CheckoutForm-complete">Paiement réussi !</div>;
    }

    return (
        <div style={style}>
            <form className="CheckoutForm" onSubmit={submit} >
                <CardElement hidePostalCode />
                <CabadiciButton
                    ref={innerRef}
                    css="display: none;"
                    color="vertFort"
                    classNameNo="CheckoutForm-button"
                    type="submit"
                    disabled={status === 'submitting'}>
                    {status === 'submitting' ? 'En cours' : 'Payer'}
                </CabadiciButton>
                {status === 'error' && (
                    <div className="CheckoutForm-error">Une erreur est apparue.</div>
                )}
            </form>
        </div>
    );
};

export default injectStripe(CheckoutForm);
