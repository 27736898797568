import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// import withTheme from '../theme';

import FlexDiv from './FlexDiv';
import { Text } from './Texts';
// import Separator from './Separator';

const Separator = styled.hr`
    border-top: ${props => props.strokeWidth ? props.strokeWidth : '10px'} solid ${props => props.theme.color[props.strokeColor]};
`;

const Subtitle = ({ className, children, color, alignement, position, width, top }) => {
    return (
        <div
            className={className}
            style={{
                // marginTop: -20,
                // marginBottom: 20,
            }}
        >
            <FlexDiv
                style={{
                    justifyContent: alignement,
                    height: 0,
                }}
            >
                <Text
                    style={{
                        fontSize: '1rem',
                        fontWeight: 600,
                        backgroundColor: 'transparent',
                        position: 'relative',
                        // top: 22,
                        // top: 32,
                        top
                    }}
                >
                    {children}
                </Text>

            </FlexDiv>


            <Separator
                // ref.current.offsetWidth ?
                strokeColor={color}
                strokeWidth="0.6em"
                style={(() => {
                    const tutu = {
                        textAlign: position,
                        maxWidth: '80%',
                        width
                    }
                    switch (position) {
                    case 'left':
                        return {
                            ...tutu,
                            ...{
                                marginLeft: 0,
                            }
                        };
                    case 'center':
                        return tutu;
                    case 'right':
                        return {
                            ...tutu,
                            ...{
                                marginRight: 0,
                            }
                        };
                    default:

                    }
                })()}
            />

        </div>
    );
};

Subtitle.propTypes = {
    alignement: PropTypes.string,
    children: PropTypes.element,
    color: PropTypes.string,
    width: PropTypes.string,
    top: PropTypes.number,
    position: PropTypes.oneOf(['left', 'center', 'right']),
};

Subtitle.defaultProps = {
    alignement: 'flex-start',
    color: 'vertFaible',
    position: 'left',
    width: '300',
    top: -10
};

export default Subtitle;
