import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../../components1/buttons';
import { SuspendSubscriptionButton, ActivateSubscriptionButton, SuspendCurrentWeekOrderButton, ActivateCurrentWeekOrderButton } from './subscriptionButtons';

export const AddCardButton = withTheme(({theme, ...props}) => {
    return (
        <Button {...props} transparent>
            <FontAwesomeIcon icon="plus" color={theme.color.vertFort} size='2x'/>
        </Button>
    );
});

export const CancelAddCardButton = withTheme(({theme, ...props}) => {
    return (
        <Button {...props} transparent>
            <FontAwesomeIcon icon="minus" color={theme.color.rougeFort} size='2x'/>
        </Button>
    );
});

export {
    SuspendSubscriptionButton,
    ActivateSubscriptionButton,
    SuspendCurrentWeekOrderButton,
    ActivateCurrentWeekOrderButton,
};
