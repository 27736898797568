import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modalize from '../../components/Modalizer_';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton } from '../../components1/buttons';
import { cleanError } from '../../actions';

const mapStateToProps = ({ error }) => ({
    error,
});

const mapDispatchToProps = dispatch => {
    return {
        cleanError: () => dispatch(cleanError()),
    };
};

const SignInSignUpError = ({ data, closeModal, error, openModal, cleanError,
    connectionModalRef, passwordForgetModalRef
}) => {
    console.log(error);

    useEffect(() => {

        // returned function will be called on component unmount
        return () => {
            console.log('UNMOUNT !!');
            // window.removeEventListener('mousemove', () => {})
        }
    }, [])

    return (
        <FlexDiv
            style={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '20px 20px 0 20px',
            }}
        >
            <Title
                style={{
                    textTransform: 'none',
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    paddingBottom: 20,
                }}
            >
                Oups !
            </Title>
            <Text
                style={{ padding: '20px 0'}}
            >
                { error && error.message}
            </Text>
            {
                error && (
                    error.code === 'auth/wrong-password' || error.code === 'auth/wrong-password') && (
                    <Text
                        style={{padding: '20px 0', fontSize: '0.875rem', alignSelf: 'center'}}
                        css={`
                            :hover {
                                opacity: 0.8;
                                cursor: pointer;
                            }
                        `}
                        onClick={() => {
                            passwordForgetModalRef.current.openModal();
                        }}
                    >
                        Mot de passe oublié ?
                    </Text>
                )
            }
            <CabadiciButton
                color="jauneFort"
                onClick={() => {
                    cleanError();
                    // if from FirstOrder, open connectionModal, else close current modal
                    if (typeof connectionModalRef !== 'undefined') {
                        connectionModalRef.current.openModal();
                    }
                }}
            >
                se connecter
            </CabadiciButton>
            <CabadiciButton
                color="rougeFort"
                onClick={() => {
                    cleanError();
                    // closeModal();
                }}
            >
                retour
            </CabadiciButton>
        </FlexDiv>
    );
};

SignInSignUpError.propTypes = {
    data: PropTypes.shape().isRequired,
    error: PropTypes.shape().isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
};

export default Modalize(connect(mapStateToProps, mapDispatchToProps)(SignInSignUpError));
