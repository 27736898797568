import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modalize from '../../components/Modalizer_';
import DeliveryInformationsForm from '../forms/DeliveryInformations';
import { updatePrivateDeliveryAddress } from '../../actions';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';
import { CabadiciButton, BackButton } from '../../components1/buttons';
import Separator from '../../components1/Separator';

const mapDispatchToProps = dispatch => {
    return {
        updatePrivateDeliveryAddress:  value => dispatch(updatePrivateDeliveryAddress(value)),
    };
};

const DeliveryAddress = ({ closeModal, updatePrivateDeliveryAddress, updateDeliveryChoice }) => {
    const formRef = useRef(null);
    const onClick = (...args) => {
        formRef.current.handleSubmit(...args);
    };

    return (
        <>
            <DeliveryInformationsForm
                ref={formRef}
                onSubmit={(data) => {
                    updatePrivateDeliveryAddress(data);
                    // set delivery choice as private only after submitting choice
                    updateDeliveryChoice(2);
                    closeModal();
                }}
            />

            <Separator strokeColor="vertFaible" style={{marginTop: 20}}/>
            <FlexDiv style={{
                justifyContent: 'space-evenly',
                alignItems: 'flex-start'
            }}>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                >
                    <BackButton
                        size="1.115rem"
                        color="jauneFort"
                        onClick={closeModal}

                    />
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 3}}
                >
                    <CabadiciButton
                        color="rougeFort"
                        onClick={onClick}
                        css={`
                            @media only screen and (max-width: 599px) {
                                font-size: 1.625rem !important;
                            }
                        `}
                    >
                        valider
                    </CabadiciButton>
                </FlexDiv>
                <FlexDiv
                    style={{justifyContent: 'center', flex: 1}}
                />
            </FlexDiv>
        </>
    );
};

DeliveryAddress.propTypes = {
    closeModal: PropTypes.func.isRequired,
    updatePrivateDeliveryAddress: PropTypes.func.isRequired,
};

export default Modalize(connect(null, mapDispatchToProps)(DeliveryAddress), {showCloseIcon: false});
