import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image'

const ParentheseBackgroundVerte = ({ children, className }) => {
    const data = useStaticQuery(graphql`
        query {
            backgroundParenthesesVertesFluid: file(relativePath: { eq: "parentheses/vertical/vertFaible.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800, quality: 90) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `);
    return (
        <BackgroundImage
            className={className}
            fluid={data.backgroundParenthesesVertesFluid.childImageSharp.fluid}
            style={{
                backgroundSize: '100% 100%',
                display: 'flex',
                justifyContent: 'center',
                alignSelf: 'center',
                marginTop: 20,
            }}
            loading="eager"
            fadeIn={false}
            css={`
                @media only screen and (max-width: 599px) {
                    padding-bottom: 20px !important;
                }
            `}
        >
            {children}
        </BackgroundImage>
    );
};

ParentheseBackgroundVerte.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.bool]).isRequired
};

export default ParentheseBackgroundVerte;

/*

fluid={data.backgroundParenthesesNoires.childImageSharp.fluid}
style={{
    // default values:
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'cover',
    // backgroundPosition: 'center',

    backgroundSize: '100% 100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    minWidth: '10rem',
    minHeight: '10rem',
}}
fadeIn={false}
css={`
    @media only screen and (max-width: 599px) {
        min-width: 7rem !important;
        min-height: 7rem !important;
    }
`}

*/
