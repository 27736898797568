import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modalize from '../../components/Modalizer_';
import PasswordForgetForm from '../forms/PasswordForget';
import { resetUserPassword } from '../../actions';
import FlexDiv from '../../components1/FlexDiv';
import { Text, Title } from '../../components1/Texts';

const mapDispatchToProps = dispatch => {
    return {
        resetUserPassword:  value => dispatch(resetUserPassword(value)),
    };
};

const PasswordForget = ({ closeModal, resetUserPassword }) => (
    <FlexDiv style={{flexDirection: 'column'}}>
        <Title
            style={{
                textTransform: 'none',
                padding: 20,
            }}
        >
            Mot de passe oublié
        </Title>
        <PasswordForgetForm
            onSubmit={({email}) => {
                resetUserPassword(email);
                closeModal();
            }}
        />
    </FlexDiv>
);

PasswordForget.propTypes = {
    closeModal: PropTypes.func.isRequired,
    resetUserPassword: PropTypes.func.isRequired,
};

export default Modalize(connect(null, mapDispatchToProps)(PasswordForget));
