import React, { PureComponent } from 'react';
import { slide as Menu } from 'react-burger-menu';
import styled, { createGlobalStyle, withTheme } from 'styled-components';
import Headroom from 'react-headroom';
import Image from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    GatsbyLink,
    tabs,
    tabsUser,
    ConnectTabs as ConnectTabsBeforeStyle,
} from './SubHeaderTabs';
import { Text } from '../Texts';

const ConnectTabs = styled(ConnectTabsBeforeStyle)`
    top: 0;
    left: 0;
    right: 0;
`;

const handleStateChange = newState => {
    window.setTimeout(() => {
        // This is necessary to force the blur to happen after in the event loop
        if (newState.isOpen) {
            document.activeElement.blur();
        }
    });
};

const GlobalStyle = createGlobalStyle`
    /* Individual item */
    .bm-item {
        display: inline-block;

        /* Our sidebar item styling */
        text-decoration: none;
        margin-bottom: 10px;
        color: #d1d1d1;
        transition: color 0.2s;
    }

    /* Change color on hover */
    .bm-item:hover {
        color: white;
    }

    /* The rest copied directly from react-burger-menu docs */

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: fixed;
        width: 20px;
        height: 20px;
        left: 20px;
        top: ${props => props.isUserPage ? '30px' : '50px'};
        @media only screen and (min-width: 599px) {
            display: none;
        }
        transition: top 0.6s;
    }

    /* hide on scroll */
    /* https://dev.to/guimg/hide-menu-when-scrolling-in-reactjs-47bj */
    .navbar--hidden {
        top: -50px;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: #373a47;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #000/*#bdc3c7*/;
    }

    /* General sidebar styles */
    .bm-menu {
        background: ${props=> props.theme.color.beigeFaible};
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }
`;

class MenuBurger extends React.Component {
    static defaultProps = {
        isUserPage: false,
    };

    state = {
        isOpen: false,
        prevScrollpos: null,
        visible: true,
    };

    isMenuOpen = state => {
        // console.log('state', state);
        if (state.isOpen === this.state.isOpen) return;
        this.setState({ isOpen: state.isOpen });
    };

    handleScroll = () => {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    };

    componentDidMount = () => {
        this.setState({
            prevScrollpos: window.pageYOffset,
        });
    };

    componentDidMount = () => {
        window.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    };

    render() {
        const { theme, modalRef, isUserPage, signOut, isLogged } = this.props;
        const { isOpen } = this.state;
        const dataTabs = isUserPage ? tabsUser : tabs;

        return (
            <StaticQuery
                query={graphql`
                    query {
                        logo: file(relativePath: { eq: "logos/logo-noir.png" }) {
                            childImageSharp {
                                fixed(width: 100) {
                                    ...GatsbyImageSharpFixed
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    return (
                        <>
                            <GlobalStyle isUserPage={isUserPage} />
                            <Menu
                                disableAutoFocus
                                isOpen={ isOpen }
                                onStateChange={this.isMenuOpen}
                                burgerButtonClassName={`my-class ${(this.state.visible ? '' : 'navbar--hidden')}`}
                                css={`
                                    @media only screen and (min-width: 599px) {
                                        display: none;
                                    }
                                `}
                                onStateChange={handleStateChange}>
                                <GatsbyLink
                                    to="/"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '10px 10px 10px 0',
                                        marginBottom: 0,
                                    }}>
                                    <Image
                                        fixed={data.logo.childImageSharp.fixed}
                                        alt="logo-menu"
                                        objectFit="contain"
                                        imgStyle={{
                                            // maxWidth: '33%',
                                            height: 'auto',
                                            // maxHeight: '80%',
                                            objectFit: 'contain',
                                        }}
                                    />
                                </GatsbyLink>
                                {dataTabs.map((t, index) => {
                                    return (
                                        <GatsbyLink
                                            key={index.toString()}
                                            to={t.page}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#000',
                                            }}
                                            activeStyle={{
                                                color: theme.color.rougeFaible,
                                            }}>
                                            <Text
                                                style={{
                                                    textTransform: 'uppercase',
                                                }}>
                                                {t.name}
                                            </Text>
                                        </GatsbyLink>
                                    );
                                })}
                                {
                                    isLogged ? (
                                        <ConnectTabs
                                            color="rougeFort"
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            onClick={() => signOut()}>
                                            se déconnecter
                                        </ConnectTabs>
                                    ) : (
                                        <ConnectTabs
                                            color="rougeFort"
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                            onClick={() => {
                                                this.setState((state)=>({isOpen: false}));
                                                modalRef.current.openModal()
                                            }}>
                                            se connecter
                                        </ConnectTabs>
                                    )
                                }
                            </Menu>
                        </>
                    );
                }}
            />
        );
    }
}

export default withTheme(MenuBurger);
