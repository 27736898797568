import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { CarouselSlideWrapper } from './CarouselElements';
import CarouselStyle from './CarouselStyle';

class Carousel extends Component {
    static propTypes = {
        children: PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
        isModal: PropTypes.bool,
    };

    static defaultProps = {
        isModal: true,
    };

    state = {
        activeIndex: 0,
    };

    myRef = React.createRef();

    goToSlide = (index) => {
        this.setState({
            activeIndex: index,
        });
        // this.myRef.current.scrollTo(0, 0);
    };

    goToPrevSlide = (e) => {
        // e.preventDefault();

        let index = this.state.activeIndex;

        /*
        let { slides } = this.props;
        let slidesLength = slides.length;
        */
        const { children } = this.props;
        const slidesLength = React.Children.count(children);

        if (index < 1) {
            index = slidesLength;
        }

        --index;

        this.setState({
            activeIndex: index,
        });
        // this.myRef.current.scrollTo(0, 0);
    }

    goToNextSlide = (e) => {
        // e.preventDefault();

        let index = this.state.activeIndex;
        /*
        let { slides } = this.props;
        let slidesLength = slides.length - 1;
        */
        const { children } = this.props;
        const slidesLength = React.Children.count(children) -1 ;

        if (index === slidesLength) {
            index = -1;
        }

        ++index;

        this.setState({
            activeIndex: index,
        });
        // this.myRef.current.scrollTo(0, 0);
    }

    render() {
        const { children, isModal } = this.props;
        const { activeIndex } = this.state;
        return (
            <>
                <CarouselStyle isModal={isModal} />
                <div className="carousel">
                    <ul className="carousel__slides">
                        {
                            React.Children.map(children, (child, index) => (
                                <CarouselSlideWrapper
                                    key={index.toString()}
                                    index={index}
                                    activeIndex={activeIndex}
                                >
                                    {
                                        // add props directly to child
                                        React.cloneElement(child, {
                                            goToPrevSlide: this.goToPrevSlide,
                                            goToNextSlide: this.goToNextSlide
                                        })
                                    }
                                </CarouselSlideWrapper>
                            ))
                        }
                    </ul>
                </div>
            </>
        );
    }
}

export default Carousel;
