import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { connect } from 'react-redux';

import FlexDiv from '../../components1/FlexDiv';
import { Text } from '../../components1/Texts';
import GreenCheckbox from '../../components1/GreenCheckbox';
import DeliveryAddressModal from '../modals/DeliveryAddress';
import PartnerDeliveryAddressModal from '../modals/PartnerDeliveryAddressModal';
import { updatePrivateDeliveryAddress, updatePartnerDeliveryAddress } from '../../actions';
import { parenthesesNoiresCarrees } from '../../assets';

const livraisonData = [
    {
        text: 'Point de retrait',
        price: 0,
        text2: 'Chez un de nos partenaires'
    },
    {
        text: 'Livraison groupée',
        price: 0,
        text2: 'À partir de deux CABADICI'
    },
    {
        text: 'Livraison privée',
        price: 10,
        text2: 'Où vous voulez en France'
    },
];

// const halo = css`4px solid ${props => props.theme.color.beigeFort}`;
const halo = css`0 0 5px 1px ${props => props.theme.color.vertFort}`

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.theme.color.vertFaible};
  color: white;
  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
`;

const TextChangeColor = styled(Text)``;

const DeliveryChoice = ({ data, checked, innerOnClick, value, privateDeliveryAddress, partnerDeliveryAddress }) => (
    <FlexDiv
        style={{
            flexDirection: 'column',
            alignItems: 'center',
            cursor: 'pointer',
        }}
        onClick={()=>innerOnClick(value)}
    >
        <FlexDiv
            checked={checked}
            style={{
                flexDirection: 'column',
                flex: 0,
                backgroundImage:
                    `url(${parenthesesNoiresCarrees})`,
                backgroundRepeat: 'no-repeat',
                // backgroundSize: 'contain',
                backgroundSize: '100% 100%',
                objectFit: 'contain',
                minWidth: '10rem',
                maxWidth: '10rem',
                minHeight: '10rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: 10,
                padding: 20,
            }}
            css={`
                /* background-color: ${props => props.theme.color.beigeFaible};*/
                /* border: ${props => props.checked ? 'solid' : 'none'};*/
                /* outline: ${props => props.checked ? halo : 'none'}; */
                /* outline-offset: 2px; */
                box-shadow: ${props => props.checked ? halo : 'none'};

                &:hover {
                    opacity: 0.7;
                }

                &:hover + ${TextChangeColor} {
                    color: ${props => props.theme.color.rougeFort};
                    font-weight: 600;
                }

            `}
        >
            <Text style={{fontSize: '1.5rem'}}>{data.text}</Text>
            <Text style={{fontSize: '0.875rem'}}>{data.text2}</Text>
            <Text style={{fontSize: '1.5rem'}}>
                {`+${data.price}.00€`}
            </Text>
        </FlexDiv>
        {
            data.text === 'Livraison groupée' &&
                // <GreenCheckbox innerHandleCheck={() => innerOnClick(value)} checked={checked}/> :
                // <Button style={{fontSize: '0.875rem'}} onClick={() => innerOnClick(value)}>Choisir ce mode de livraison</Button> :
                <TextChangeColor style={{fontSize: '0.875rem'}}>bientôt disponible</TextChangeColor>
        }
        {
            data.text === 'Point de retrait' && partnerDeliveryAddress && (
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        padding: 10,
                    }}
                    css={`border: 2px solid ${props => props.theme.color.rougeFaible};`}
                >
                    <Text style={{fontSize: '0.75rem', textAlign: 'center', textTransform: 'uppercase'}}>{partnerDeliveryAddress.name}</Text>
                    <Text style={{fontSize: '0.75rem'}}>{partnerDeliveryAddress.location}</Text>
                </FlexDiv>
            )
        }
        {
            data.text === 'Livraison privée' && privateDeliveryAddress && (
                <FlexDiv
                    style={{
                        flexDirection: 'column',
                        padding: 10,
                        // alignSelf: 'stretch',
                        // margin: 20,
                    }}
                    css={`border: 2px solid ${props => props.theme.color.rougeFaible};`}
                >
                    <Text style={{fontSize: '0.75rem', textAlign: 'center',}}>{privateDeliveryAddress.deliveryAddress}</Text>
                    <Text style={{fontSize: '0.75rem'}}>{`${privateDeliveryAddress.deliveryPostalCode} ${privateDeliveryAddress.deliveryCity}`}</Text>
                </FlexDiv>
            )
        }
    </FlexDiv>
);

DeliveryChoice.propTypes = {
    data: PropTypes.shape().isRequired,
    checked: PropTypes.bool.isRequired,
    innerOnClick: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    privateDeliveryAddress: PropTypes.shape(),
    partnerDeliveryAddress: PropTypes.shape(),
};

const mapStateToProps = ({ deliveryChoice, privateDeliveryAddress, partnerDeliveryAddress }) => ({
    deliveryChoice,
    privateDeliveryAddress,
    partnerDeliveryAddress
});

const mapDispatchToProps = dispatch => {
    return {
        updateDeliveryChoice: (value) => dispatch({ type: 'UPDATE_DELIVERY_CHOICE', value }),
        updatePrivateDeliveryAddress:  value => dispatch(updatePrivateDeliveryAddress(value)),
        updatePartnerDeliveryAddress:  value => dispatch(updatePartnerDeliveryAddress(value)),
    };
};

class DeliverySelector extends PureComponent {
    static propTypes = {
        deliveryChoice: PropTypes.number,
        privateDeliveryAddress: PropTypes.shape(),
        partnerDeliveryAddress: PropTypes.shape(),
        updatePrivateDeliveryAddress: PropTypes.func.isRequired,
        updatePartnerDeliveryAddress: PropTypes.func.isRequired,
        updateDeliveryChoice: PropTypes.func.isRequired,
    };

    deliveryFormModal = React.createRef();

    PartnerDeliveryFormModal = React.createRef();

    resetDeliveryAddressChoice = (value) => {
        const { updatePartnerDeliveryAddress, updatePrivateDeliveryAddress } = this.props;

        switch (value) {
        case 0:
            return updatePartnerDeliveryAddress(null);
        case 2:
            return updatePrivateDeliveryAddress(null);
        default:
        }
    };

    innerOnClick = (value) => {
        const { updateDeliveryChoice } = this.props;
        if (value === 1) {
            return;
        }
        // updateDeliveryChoice(value);
        // this.resetDeliveryAddressChoice(value);

        if (value === 0) {
            this.PartnerDeliveryFormModal.current.openModal()
        }
        if (value === 2) {
            this.deliveryFormModal.current.openModal()
        }
    };

    closeModal = () => {
        const { deliveryChoice, partnerDeliveryAddress, privateDeliveryAddress, updateDeliveryChoice } = this.props;
        if (deliveryChoice === 0 && !partnerDeliveryAddress) {
            updateDeliveryChoice(0);
        }
        if (deliveryChoice === 2 && !privateDeliveryAddress) {
            updateDeliveryChoice(0);
        }
    };

    render() {
        const { deliveryChoice, updateDeliveryChoice, privateDeliveryAddress, updatePrivateDeliveryAddress, updatePartnerDeliveryAddress, partnerDeliveryAddress, isLogged } = this.props;

        return (
            <>
                {
                    !isLogged && (
                        <Text style={{textAlign: 'center', padding: '20px 0'}}>
                            Choisissez votre mode de livraison :
                        </Text>
                    )
                }
                <FlexDiv
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        flexWrap: 'wrap',
                    }}
                >
                    {
                        livraisonData.map((hours, index) => (
                            <DeliveryChoice
                                key={index.toString()}
                                data={hours}
                                checked={(deliveryChoice === index)}
                                innerOnClick={this.innerOnClick}
                                value={index}
                                privateDeliveryAddress={privateDeliveryAddress}
                                partnerDeliveryAddress={partnerDeliveryAddress}
                            />
                        ))
                    }
                </FlexDiv>
                <PartnerDeliveryAddressModal ref={this.PartnerDeliveryFormModal} updatePartnerDeliveryAddress={updatePartnerDeliveryAddress} onCloseModalHook={this.closeModal} updateDeliveryChoice={updateDeliveryChoice} />
                <DeliveryAddressModal ref={this.deliveryFormModal} updatePrivateDeliveryAddress={updatePrivateDeliveryAddress} onCloseModalHook={this.closeModal} updateDeliveryChoice={updateDeliveryChoice} />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(DeliverySelector));
