import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Persist } from 'formik-persist';
import { isEmpty } from 'lodash';

import { Formik, Form, Field, ErrorMessage } from '../../components1/forms/formik';
import { CustomInputComponent2, DrawHandStyleInput, WrappingSelect, DrawHandStyleSelect,  } from '../../components1/forms';
import FlexDiv from '../../components1/FlexDiv';
import { Text } from '../../components1/Texts';
import { UserInformationsSchema, UserInformationsNoEmailSchema } from './schemas';


const FormGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /*grid-template-rows: repeat(10, minmax(2.25rem, auto));*/
    grid-template-rows: minmax(2.25rem, auto);
    /* grid-auto-rows: minmax(300px, auto) ;*/
    grid-column-gap: 30px;
    grid-row-gap: 5px;
    grid-template-areas:
        '. gender gender .'
        'a b c d'
        'birthDayLabel birthDay birthMonth birthYear'
        'e f f f'
        'g h i j'
        'k l m n'
        'q q r r'
        's s t t'
        'v v w w'
        'x x y y'
        '. z z .';
    @media only screen and (max-width: 599px) {
        grid-template-columns: 1fr 1fr !important;
        grid-column-gap: 5px;
        grid-template-areas:
            'gender gender'
            'a b'
            'c d'
            'birthDayLabel birthDay'
            'birthMonthLabel birthMonth'
            'birthYearLabel birthYear'
            'e e'
            'f f'
            'g h'
            'i j'
            'k l'
            'm n'
            'q q'
            's s'
            'r r'
            't t'
            'v w'
            'x y'
            'z z' !important;
    }
`;

const InnerForm = withTheme(({authUserData, theme, signOut}) => (
    <Form>
        <FormGrid>
            <FlexDiv
                style={{
                    gridArea: 'gender',
                    flexDirection: 'column',
                }}>
                {/*
                    <Field name="gender" type="radio" value="mme"/>
                    <label htmlFor="gender">Mme</label>
                    <Field name="gender" type="radio" value="mr"/>
                    <label htmlFor="gender">Monsieur</label>
                    <Field name="gender" type="radio" value="mr-mme" label="mr-mme"/>
                */}
                <Field
                    name="gender"
                    component={CustomInputComponent2}
                />
                <ErrorMessage
                    name="gender"
                    component="div"
                    style={{ alignSelf: 'center' }}
                />
            </FlexDiv>

            <FlexDiv
                style={{ gridArea: 'a', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>
                    Nom
                </Text>
                <ErrorMessage
                    name="userInformations.lastName"
                    component="div"
                />
            </FlexDiv>
            <FlexDiv
                style={{ gridArea: 'b', flexDirection: 'column', justifyContent: 'center' }}>
                <Field
                    name="userInformations.lastName"
                    component={DrawHandStyleInput}
                />
                <ErrorMessage
                    name="userInformations.lastName"
                    component="div"
                />
            </FlexDiv>

            <FlexDiv
                style={{ gridArea: 'c', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>
                    Prénom
                </Text>
                <ErrorMessage
                    name="userInformations.firstName"
                    component="div"
                />
            </FlexDiv>
            <Field
                name="userInformations.firstName"
                component={DrawHandStyleInput}
                style={{ gridArea: 'd', alignSelf: 'flex-start' }}
            />

            <FlexDiv
                style={{
                    gridArea: 'birthDayLabel',
                    flexDirection: 'column',
                }}>
                <Text>
                    Date de naissance
                </Text>
                <ErrorMessage
                    name="userInformations.birthDate.day"
                    component="div"
                />
                <ErrorMessage
                    name="userInformations.birthDate.month"
                    component="div"
                />
                <ErrorMessage
                    name="userInformations.birthDate.year"
                    component="div"
                />
            </FlexDiv>
            <FlexDiv
                style={{ gridArea: 'birthDay', justifyContent: 'flex-start', alignItems: 'center' }}
                css={`
                    ${DrawHandStyleInput},${DrawHandStyleSelect} {
                        margin: 0 2px;
                    }
                `}
            >
                <Field
                    name="userInformations.birthDate.day"
                    placeholder="JJ"
                    component={DrawHandStyleInput}
                    style={{
                        gridArea: 'birthDay',
                        alignSelf: 'center',
                        width: '2rem',
                    }}
                    noPadding
                />
                <WrappingSelect>
                    <Field
                        name="userInformations.birthDate.month"
                        component={DrawHandStyleSelect}
                        placeholder="MM"
                        style={{
                            gridArea: 'birthMonth',
                            alignSelf: 'center',
                            width: '3rem',
                        }}
                        noPadding
                    >
                        <option value="" disabled selected hidden>MM</option>
                        <option value="01">01</option>
                        <option value="02">02</option>
                        <option value="03">03</option>
                        <option value="04">04</option>
                        <option value="05">05</option>
                        <option value="06">05</option>
                        <option value="07">07</option>
                        <option value="08">08</option>
                        <option value="09">09</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </Field>
                </WrappingSelect>
                <Field
                    name="userInformations.birthDate.year"
                    placeholder="AAAA"
                    component={DrawHandStyleInput}
                    style={{
                        gridArea: 'birthYear',
                        alignSelf: 'center',
                        width: '5rem',
                    }}
                    css={`
                        @media only screen and (max-width: 599px) {
                            width: 3rem !important;
                        }
                    `}
                    noPadding
                />
            </FlexDiv>

            <FlexDiv
                style={{ gridArea: 'e', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>
                    Adresse de facturation
                </Text>
                <ErrorMessage
                    name="billingAddress.address"
                    component="div"
                />
            </FlexDiv>
            <Field
                name="billingAddress.address"
                component={DrawHandStyleInput}
                style={{ gridArea: 'f', alignSelf: 'flex-start' }}
                css={`
                    @media only screen and (max-width: 599px) {
                        max-width: 100%;
                    }
                `}
            />

            <FlexDiv
                style={{ gridArea: 'g', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>
                    Code postal
                </Text>
                <ErrorMessage
                    name="billingAddress.postalCode"
                    component="div"
                />
            </FlexDiv>
            <Field
                name="billingAddress.postalCode"
                component={DrawHandStyleInput}
                style={{ gridArea: 'h', alignSelf: 'flex-start' }}
            />

            <FlexDiv
                style={{ gridArea: 'i', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>Ville</Text>
                <ErrorMessage
                    name="billingAddress.city"
                    component="div"
                />
            </FlexDiv>
            <Field
                name="billingAddress.city"
                component={DrawHandStyleInput}
                style={{ gridArea: 'j', alignSelf: 'flex-start', flex: 1 }}
            />

            {
                isEmpty(authUserData) ? (
                    <>
                        <FlexDiv
                            style={{ gridArea: 'k', flexDirection: 'column', justifyContent: 'center' }}>
                            <Text>Email</Text>
                            <ErrorMessage name="email" component="div" />
                        </FlexDiv>
                        <Field
                            autocomplete="email"
                            name="email"
                            type="email"
                            component={DrawHandStyleInput}
                            style={{ gridArea: 'l', alignSelf: 'flex-start' }}
                        />
                    </>
                ) : (
                    <>
                        <Text style={{ gridArea: 'k', alignSelf: 'flex-start' }}>Adresse email:</Text>
                        <Text style={{ gridArea: 'l', alignSelf: 'flex-start' }}>{authUserData.email}</Text>
                        {
                            !authUserData.isCustomer && (
                                <Text
                                    role="button"
                                    style={{
                                        gridArea: 'q',
                                        textDecoration: 'underline',
                                        textAlign: 'center'
                                    }}
                                    css={`
                                        :hover{
                                            cursor: pointer;
                                        }
                                    `}
                                    onClick={() => {signOut()}}
                                >
                                    {'changer l\'adresse email'}
                                </Text>
                            )
                        }
                    </>
                )
            }

            {
                isEmpty(authUserData) && (
                    <>
                        <FlexDiv
                            style={{ gridArea: 'q', flexDirection: 'column', justifyContent: 'center' }}>
                            <Text>Mot de passe</Text>
                            <ErrorMessage name="password" component="div" />
                        </FlexDiv>
                        <Field
                            name="password"
                            component={DrawHandStyleInput}
                            type="password"
                            style={{ gridArea: 's', alignSelf: 'flex-start' }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    max-width: 100%;
                                }
                            `}
                        />

                        <FlexDiv
                            style={{ gridArea: 'r', flexDirection: 'column', justifyContent: 'center' }}>
                            <Text>
                                Confirmation mot de passe
                            </Text>
                            <ErrorMessage
                                name="passwordConfirm"
                                component="div"
                            />
                        </FlexDiv>
                        <Field
                            name="passwordConfirm"
                            component={DrawHandStyleInput}
                            type="password"
                            style={{ gridArea: 't', alignSelf: 'flex-start' }}
                            css={`
                                @media only screen and (max-width: 599px) {
                                    max-width: 100%;
                                }
                            `}
                        />
                    </>
                )
            }

            <FlexDiv
                style={{ gridArea: 'm', flexDirection: 'column', justifyContent: 'center' }}>
                <Text>
                    Téléphone
                </Text>
                <ErrorMessage
                    name="userInformations.phone"
                    component="div"
                />
            </FlexDiv>
            <Field
                name="userInformations.phone"
                component={DrawHandStyleInput}
                style={{ gridArea: 'n', alignSelf: 'flex-start' }}
            />

            <Text
                style={{ gridArea: 'v', alignSelf: 'center' }}>
                Nombre de personnes dans votre foyer
            </Text>

            <WrappingSelect
                style={{ gridArea: 'w', width: '100%', fontFamily: theme.font.main, alignSelf: 'center' }}
            >
                <Field
                    name="informations.familyNumber"
                    component={DrawHandStyleSelect}
                >
                    <option value="" disabled selected hidden>-</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </Field>
            </WrappingSelect>

            <Text
                style={{ gridArea: 'x', alignSelf: 'center'  }}
            >
                Préférences alimentaires
            </Text>
            <WrappingSelect
                style={{ gridArea: 'y', width: '100%', fontFamily: theme.font.main, alignSelf: 'center' }}
            >
                <Field
                    component={DrawHandStyleSelect}
                    name="informations.foodPreference"
                >
                    <option value="" disabled selected hidden>-</option>
                    <option value="tout">de tout</option>
                    <option value="poisson">plutôt du poisson</option>
                    <option value="vegetarien">plutôt VG</option>
                </Field>
            </WrappingSelect>
        </FormGrid>
        {/*<Persist name="signup-form" />*/}
    </Form>
));

const UserInformations = React.forwardRef(({onSubmit, ...props}, ref) => (
    <Formik
        ref={ref}
        initialValues={props.initialValues ? props.initialValues : {
            email: '',
            password: '',
            passwordConfirm: '',
            gender: '',
            userInformations: {
                birthDate: {},
                lastName: '',
                firstName: '',
                phone: '',
            },
            billingAddress: {
                address: '',
                postalCode: '',
                city: '',
            },
            informations: {
                familyNumber: '',
                foodPreference: '',
            },
        }}
        validationSchema={
            isEmpty(props.authUserData) ? UserInformationsSchema : UserInformationsNoEmailSchema
        }
        onSubmit={onSubmit}
        component={(p) => <InnerForm {...p} authUserData={props.authUserData} signOut={props.signOut} />}
        {...props}
    />
));

export default UserInformations;
